
export class Conversion {
  public static hexToInt(hex: string) : number {
    return parseInt(hex);
  }

  public static intToHex(value: number) : string {
    var hex =  value.toString(16);
    if (hex.length % 2) {
      hex = "0" + hex;
    }
    return "0x" + hex;
  }

 public static bytesToHex(data: Uint8Array) : string {
    return "0x" + data.reduce(function(str, byte) {
      return str + ("0" + byte.toString(16)).slice(-2);
    }, "");
  }

public static bufferToHex(data: Buffer) : string{
  return "0x" + data.reduce(function(str, byte) {
    return str + ("0" + byte.toString(16)).slice(-2);
  }, "");
}
}
