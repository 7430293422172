import { Base64 } from "../../base64";

export class TransferData {
  from: string;
  to: string;
  assetID: number;
  quantity: string; //hex encoded string
  gasPrice: string; // gas price - hex value
  gasLimit: number; // gas limit

  constructor(from: string, to: string, assetID: number, quantity: string, gasPrice: string, gasLimit: number) {
    this.from = from;
    this.to = to;
    this.assetID = assetID;
    this.quantity = quantity;
    this.gasPrice = gasPrice;
    this.gasLimit = gasLimit;
  }

  public static fromJson(json: any): TransferData {
    return new TransferData(
      json["from"],
      json["to"],
      json["asset_id"],
      json["quantity"],
      json["gas_price"],
      json["gas_limit"]
    );
  }

  public toJson(): string {
    return JSON.stringify({
      "from": this.from,
      "to": this.to,
      "asset_id": this.assetID,
      "quantity": this.quantity,
      "gas_price": this.gasPrice,
      "gas_limit": this.gasLimit
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
