import { combineReducers } from 'redux';
import { totalXchangeAmount, totalVaultAmount } from './totalAmount'
import XAccount from "./xAccount";
import { currentNonce, getNonce } from "./currentNonce";
import { sessionPubKey, wsAddress } from "./session";
import { currentView} from "./currentView";
import { topLoaderState} from "./topLoaderState";
import { assetList, currentAssetId } from "./assetList";
import { currentVaultNameReducer } from "./currentVault";
import { transactionStates } from "./transactionStates";
import { currentUser, getCurrentUser } from "./currentUser";
import { currentOrg } from "./currentOrg";
import { currentTokenPool } from "./currentTokenPool";
import { currentVaultList, getCurrentVaultList } from "./currentVaultsList";


export default combineReducers({
    totalXchangeAmount,
    totalVaultAmount,
    XAccount,
    currentNonce,
    getNonce,
    sessionPubKey,
    wsAddress,
    currentView,
    topLoaderState,
    assetList,
    currentAssetId,
    currentVaultNameReducer,
    transactionStates,
    currentUser,
    getCurrentUser,
    currentOrg,
    currentTokenPool,
    currentVaultList,
    getCurrentVaultList
})
