export function clip_address(str) {
    return str.substr(0, 6) + '....' + str.substr(str.length-4, str.length);
}

export function clip_names(str) {
    return str.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
}

export function get_bg_color(stringInput) {
    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

export function clearStorage () {
    localStorage.clear();
}

export function getSimpleDate (d) {
    return d.getDate() + "-" + ( d.getMonth() + 1 ) + "-" + d.getFullYear();
}

export function calculateDaysBetween(date1_ms, date2_ms) {
    // Calculate the difference in milliseconds
    var difference_ms = Math.abs(date1_ms - date2_ms);

    // Convert back to days
    var days = difference_ms / (1000 * 60 * 60 * 24);

    // If less than 1 day, return in hours and minutes
    if (days < 1) {
        var hours = Math.floor(difference_ms / (1000 * 60 * 60));
        var minutes = Math.floor((difference_ms % (1000 * 60 * 60)) / (1000 * 60));
        return hours + " hours and " + minutes + " minutes";
    }

    // Else return in days
    return Math.round(days) + " days";
}

export function formatDate(date) {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Try to extract timezone abbreviation from the date string
    const match = date.toString().match(/\(([A-Za-z\s]+)\)$/);
    const timezoneAbbr = match ? match[1].match(/[A-Z]/g).join('') : 'Local';

    return `${month} ${day} ${year} ${hours}:${minutes}:${seconds} ${timezoneAbbr}`;
}