import { Base64 } from "../../base64";

export class CreatePBMProgramPoolConditionData {
  pbmProgramHash: string;
  loyaltyPointMintPercent: number;
  minRedeemSpendAmount: number;
  MaxRedeemAllowed: number;
  assetPriceOracle: string;
  priceUnit: string;
  
  constructor(pbmProgramHash: string, loyaltyPointMintPercent: number, minRedeemSpendAmount: number, MaxRedeemAllowed: number, assetPriceOracle: string, priceUnit: string) {
    this.pbmProgramHash = pbmProgramHash;
    this.loyaltyPointMintPercent = loyaltyPointMintPercent;
    this.minRedeemSpendAmount = minRedeemSpendAmount;
    this.MaxRedeemAllowed = MaxRedeemAllowed;
    this.assetPriceOracle = assetPriceOracle;
    this.priceUnit = priceUnit;

  }

  public static fromJson(json: any): CreatePBMProgramPoolConditionData {
    return new CreatePBMProgramPoolConditionData(
      json["pbm_program_hash"],
      json["loyalty_point_mint_percent"],
      json["min_redeem_spend_amount"],
      json["max_redeem_allowed"],
      json["asset_price_oracle"],
      json["price_unit"]
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "pbm_program_hash": this.pbmProgramHash,
      "loyalty_point_mint_percent": this.loyaltyPointMintPercent,
      "min_redeem_spend_amount": this.minRedeemSpendAmount,
      "max_redeem_allowed": this.MaxRedeemAllowed,
      "asset_price_oracle": this.assetPriceOracle,
      "price_unit": this.priceUnit

    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
