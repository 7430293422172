/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from "react";
import { Base64 } from "kapow-sdk";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table,
    Modal,
    ModalBody,
    Alert,
    UncontrolledTooltip,
} from "reactstrap";

import ReactPaginate from "react-paginate";

import RulesModal from "./Modals/RulesModal";
import {Link, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import {config} from "../config";
import "./governance.scss";
import {clip_address, formatDate} from "../variables/utils";
import ActionTakers from "./Modals/CurrentTransactionModal/ActionTakers";
import Identicon from "react-identicons";
import AvatarList from "./components/AvatarList/AvatarList";
import LongHash from "../components/LongHash/LongHash";
import {DotPulse} from "@uiball/loaders";

const Governance = () => {

    const ruleListHeader = ["Vault", "Rule Name", "Created On",
        // "Created By",
        "Approvers", "Approval Quorum", "Rejectors", "Rejection Quorum", "Tx Limits", "Actions"];
    const [ruleList, setRuleList] = useState([]);
    const [modalClassic, setModalClassic] = useState(false);
    const [alert, setAlert] = useState(null);
    const [currentView, setCurrentView] = useState("init");

    // Pagination Items
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;
    let pendingTimeout;

    useEffect(() => {
        (async () => {
            await getAccountRules();
        })();

        return () => {
            clearInterval(pendingTimeout);
        };
    }, []);


    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    async function getAccountRules (page_number = 1) {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.organisation}/${localStorage.getItem("org_hash")}/rules?limit=${itemsPerPage}&page=${page_number}&order=desc`
        );

        let accountRules = await response.json();
        console.log("groups response ", accountRules.data);
        setRuleList(accountRules.data);

        if (accountRules.paginate && accountRules.paginate.total_page) {
            setTotalPages(accountRules.paginate.total_page);
        }
        console.log("accountRules.data", accountRules.data);
        if (accountRules.data && accountRules.data.length > 1 && accountRules.data[0] && accountRules.data[0].create_block === 0) {
            console.log("Inside Account Rules Data");
            pendingTimeout = setTimeout(() => {
                getAccountRules();
            }, 1000);
        } else {
            window.clearInterval(pendingTimeout);
        }

    }

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        getAccountRules(selectedPage.selected + 1).then((res) => {
            console.log("Get Current Txs - Response", res);
        });
    };

    let { path } = useRouteMatch();
    let { id } = useParams();

    return (
        <>
            <div className="content governance">
                <Switch>
                    <Route exact path={path}>
                        <Row>
                            <Link to={`governance/new`}>
                                <Link className="create-new-rule" color="info" to={`governance/new`}>
                                    <Button
                                        className="btn-simple" color="info"
                                    >
                                        + Create New Rule
                                    </Button>
                                </Link>
                            </Link>

                            <Col lg="12">
                                <Card>
                                    <CardHeader>
                                        <div className="tools float-right">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    caret
                                                    className="btn-icon"
                                                    color="link"
                                                    data-toggle="dropdown"
                                                    type="button"
                                                >
                                                    <i className="tim-icons icon-settings-gear-63" />
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        Action
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        Another action
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        Something else
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        className="text-danger"
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        Remove Data
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <CardTitle tag="h4">Governance Policies</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        {
                                            ruleList && ruleList.length === 0 &&
                                            <div className="first-view">
                                                <div className="step-1">
                                                    <div className="team-image">
                                                        <div>
                                                            <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1672745122/web_application/s2_agzbke.png" alt="" style={{width: "200px"}} />
                                                        </div>
                                                    </div>
                                                    <div className="step-1__text">
                                                        Create your First Transaction Policy

                                                    </div>
                                                    <Link color="info" to={`governance/new`}>
                                                        <div className="step-1__action">
                                                            <Button className="btn-primary" toggleModalClassic={toggleModalClassic}>+ Create New Rule</Button>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        }

                                        <Table responsive>
                                            <colgroup>
                                                <col span="1" width="10%" />
                                                <col span="1" width="10%" />
                                                <col span="1" width="15%" />
                                                {/*<col span="1" width="15%" />*/}
                                                <col span="1" width="16%" />
                                                <col span="1" width="9%" />
                                                <col span="1" width="16%" />
                                                <col span="1" width="9%" />
                                                <col span="1" width="10%" />
                                                <col span="1" width="4%" />
                                            </colgroup>
                                            <thead className="text-primary" style={{"height": "0px"}}>
                                                <tr style={{"height": "0px"}}>
                                                    {
                                                        ruleList && ruleList.length > 0 &&
                                                        ruleListHeader.map((rule_header, index) => <th key={index} className="text-center">{rule_header}</th>)
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                ruleList && ruleList.length > 0 &&
                                                ruleList.map((rule, index) => (
                                                    <tr key={index}>
                                                        <td className="small-size">
                                                            <b className="text-center">{ rule.vault.vault_name }</b>
                                                            <br />
                                                            <LongHash
                                                                hash={ rule.vault.vault_address }
                                                                mode="dark"
                                                            />
                                                        </td>
                                                        <td className="text-center small-size">{rule.name}</td>
                                                        {/*<td>{rule.creator}</td>*/}
                                                        <td>
                                                            <span className="small-size">
                                                                {
                                                                    formatDate(new Date(rule.create_time_stamp*1000))
                                                                }
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {
                                                                rule.create_block === 0 &&
                                                                <div className="pending_parent">
                                                                    <DotPulse
                                                                        color="#2a6ef6"
                                                                        className="pending_rows"
                                                                        size={23}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                rule.create_block !== 0 &&
                                                                rule.approvers && rule.approvers.length > 0 &&
                                                                <AvatarList
                                                                    members={ rule.approvers }
                                                                    add_member={ false }
                                                                />
                                                            }
                                                        </td>
                                                        <td className="text-center small-size">
                                                            {
                                                                rule.create_block === 0 &&
                                                                <div className="pending_parent">
                                                                    <DotPulse
                                                                        color="#2a6ef6"
                                                                        className="pending_rows"
                                                                        size={23}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                rule.create_block !== 0 &&
                                                                <div>
                                                                    <span>{rule.threshold_approvals}</span>
                                                                    /
                                                                    <span>{rule.approvers.length}</span>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                rule.create_block === 0 &&
                                                                <div className="pending_parent">
                                                                    <DotPulse
                                                                        color="#2a6ef6"
                                                                        className="pending_rows"
                                                                        size={23}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                rule.create_block !== 0 &&
                                                                rule.rejectors && rule.rejectors.length > 0 &&
                                                                <AvatarList members={ rule.rejectors } add_member={ false } />
                                                            }
                                                        </td>
                                                        <td className="text-center small-size">
                                                            {
                                                                rule.create_block === 0 &&
                                                                <div className="pending_parent">
                                                                    <DotPulse
                                                                        color="#2a6ef6"
                                                                        className="pending_rows"
                                                                        size={23}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                rule.create_block !== 0 &&
                                                                <div>
                                                                    <span>{rule.threshold_rejections}</span>
                                                                    /
                                                                    <span>{rule.approvers.length}</span>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td className="text-center small-size">{rule.min}-{rule.max}</td>
                                                        <td>
                                                            <Link className="new-vault" to={`governance/${rule.rule_hash}`}>
                                                                Edit
                                                            </Link>
                                                            {/*<Button*/}
                                                            {/*    className="btn-link"*/}
                                                            {/*    color="danger"*/}
                                                            {/*    id="tooltip974171201345"*/}
                                                            {/*    size="sm"*/}
                                                            {/*    onClick={ () => {*/}
                                                            {/*        */}
                                                            {/*    } }*/}
                                                            {/*>*/}
                                                            {/*    <i className="tim-icons icon-simple-remove" />*/}
                                                            {/*</Button>*/}
                                                            {/*<UncontrolledTooltip*/}
                                                            {/*    delay={0}*/}
                                                            {/*    target="tooltip974171201345"*/}
                                                            {/*>*/}
                                                            {/*    Archive Rule*/}
                                                            {/*</UncontrolledTooltip>*/}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                        <ReactPaginate
                                            pageCount={ totalPages }
                                            onPageChange={ handlePageChange }
                                            forcePage={ currentPage }
                                            renderOnZeroPageCount={ null }
                                            className="pagination"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            activeClassName="active"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Route>

                    <Route path={`${path}/:id`}>
                        <RulesModal
                            modalClassic={ modalClassic }
                            toggleModalClassic={ toggleModalClassic }
                            getAccountRules={ getAccountRules }
                        />
                    </Route>
                </Switch>
            </div>
        </>
    );
};

export default Governance;