import { Base64 } from "../base64";
import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { CreatePBMProgramData } from "./data/data_pbm_program_create";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class CreatePBMProgramTransaction extends Transaction {
  
  // type: number = TransactionTypeEnum.TX_TYPE_ORGANISATION_CREATE;
  type: number = 1537;


  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, name: string, coinName: string, coinSymbol: string, coinLogo: string, description: string) {
    super();
    
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    
    var data = new CreatePBMProgramData(name, coinName, coinSymbol, coinLogo,description).toBase64();
    
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();    
  }
}
