import React from "react";
import classNames from "classnames";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";

import ReactTable from "components/ReactTable/ReactTable.js";

const dataTable = [
    ["Airi Satou", "e-Rupee, e-HKD, e-REIT", "Tokyo", "$33000"],
    ["Angelica Ramos", "e-Rupee, e-HKD, e-REIT", "London", "47"],
    ["Ashton Cox", "e-Rupee, e-HKD, e-REIT", "San Francisco", "66"],
    ["Bradley Greer", "e-Rupee, e-HKD, e-REIT", "London", "41"],
    ["Brenden Wagner", "e-Rupee, e-HKD, e-REIT", "San Francisco", "28"],
    ["Brielle Williamson", "e-Rupee, e-HKD, e-REIT", "New York", "61"],
    ["Caesar Vance", "e-Rupee, e-HKD, e-REIT", "New York", "21"],
    ["Cedric Kelly", "e-Rupee, e-HKD, e-REIT", "Edinburgh", "22"],
    ["Charde Marshall", "e-Rupee, e-HKD, e-REIT", "San Francisco", "36"],
    ["Colleen Hurst", "e-Rupee, e-HKD, e-REIT", "San Francisco", "39"],
    ["Dai Rios", "e-Rupee, e-HKD, e-REIT", "Edinburgh", "35"],
    ["Doris Wilder", "e-Rupee, e-HKD, e-REIT", "Sidney", "23"],
    ["Fiona Green", "e-Rupee, e-HKD, e-REIT", "San Francisco", "48"],
    ["Garrett Winters", "e-Rupee, e-HKD, e-REIT", "Tokyo", "63"],
    ["Gavin Cortez", "e-Rupee, e-HKD, e-REIT", "San Francisco", "22"],
    ["Gavin Joyce", "e-Rupee, e-HKD, e-REIT", "Edinburgh", "42"],
    ["Gloria Little", "e-Rupee, e-HKD, e-REITr", "New York", "59"],
    ["Haley Kennedy", "e-Rupee, e-HKD, e-REIT", "London", "43"],
    ["Herrod Chandler", "e-Rupee, e-HKD, e-REIT", "San Francisco", "59"],
    ["Hope Fuentes", "e-Rupee, e-HKD, e-REIT", "San Francisco", "41"],
    ["Howard Hatfield", "e-Rupee, e-HKD, e-REIT", "San Francisco", "51"],
    ["Jackson Bradshaw", "e-Rupee, e-HKD, e-REIT", "New York", "65"],
    ["Jena Gaines", "e-Rupee, e-HKD, e-REIT", "London", "30"],
    ["Jenette Caldwell", "e-Rupee, e-HKD, e-REIT", "New York", "30"],
    ["Jennifer Chang", "e-Rupee, e-HKD, e-REIT", "Singapore", "28"],
    ["Martena Mccray", "e-Rupee, e-HKD, e-REIT", "Edinburgh", "46"],
    ["Michael Silva", "e-Rupee, e-HKD, e-REIT", "London", "66"],
    ["Michelle House", "e-Rupee, e-HKD, e-REIT", "Sidney", "37"],
    ["Olivia Liang", "e-Rupee, e-HKD, e-REIT", "Singapore", "64"],
    ["Paul Byrd", "e-Rupee, e-HKD, e-REIT", "New York", "64"],
    ["Prescott Bartlett", "e-Rupee, e-HKD, e-REIT", "London", "27"],
    ["Quinn Flynn", "e-Rupee, e-HKD, e-REIT", "Edinburgh", "22"],
    ["Rhona Davidson", "e-Rupee, e-HKD, e-REIT", "Tokyo", "55"],
    ["Shou Itou", "e-Rupee, e-HKD, e-REIT", "Tokyo", "20"],
    ["Sonya Frost", "e-Rupee, e-HKD, e-REIT", "Edinburgh", "23"],
    ["Suki Burks", "e-Rupee, e-HKD, e-REIT", "London", "53"],
    ["Tatyana Fitzpatrick", "e-Rupee, e-HKD, e-REIT", "London", "19"],
    ["Timothy Mooney", "e-Rupee, e-HKD, e-REIT", "London", "37"],
    ["Unity Butler", "e-Rupee, e-HKD, e-REIT", "San Francisco", "47"],
    ["Vivian Harrell", "e-Rupee, e-HKD, e-REIT", "San Francisco", "62"],
    ["Yuri Berry", "e-Rupee, e-HKD, e-REIT", "New York", "40"],
    ["Tiger Nixon", "e-Rupee, e-HKD, e-REIT", "Edinburgh", "61"],
];

const UserManagement = () => {
    const [data, setData] = React.useState(
        dataTable.map((prop, key) => {
            return {
                id: key,
                name: prop[0],
                position: prop[1],
                office: prop[2],
                age: prop[3],
                actions: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        {/* use this button to add a like kind of action */}
                        <Button
                            onClick={() => {
                                let obj = data.find((o) => o.id === key);
                                alert(
                                    "You've clicked LIKE button on \n{ \nName: " +
                                    obj.name +
                                    ", \nposition: " +
                                    obj.position +
                                    ", \noffice: " +
                                    obj.office +
                                    ", \nage: " +
                                    obj.age +
                                    "\n}."
                                );
                            }}
                            color="info"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5,
                            })}
                        >
                            <i className="tim-icons icon-heart-2" />
                        </Button>{" "}
                        {/* use this button to add a edit kind of action */}
                        <Button
                            onClick={() => {
                                let obj = data.find((o) => o.id === key);
                                alert(
                                    "You've clicked EDIT button on \n{ \nName: " +
                                    obj.name +
                                    ", \nposition: " +
                                    obj.position +
                                    ", \noffice: " +
                                    obj.office +
                                    ", \nage: " +
                                    obj.age +
                                    "\n}."
                                );
                            }}
                            color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5,
                            })}
                        >
                            <i className="tim-icons icon-pencil" />
                        </Button>{" "}
                        {/* use this button to remove the data row */}
                        <Button
                            onClick={() => {
                                var newdata = data;
                                newdata.find((o, i) => {
                                    if (o.id === key) {
                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        data.splice(i, 1);
                                        console.log(data);
                                        return true;
                                    }
                                    return false;
                                });
                                setData(newdata);
                            }}
                            color="danger"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5,
                            })}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </Button>{" "}
                    </div>
                ),
            };
        })
    );
    return (
        <>
            <div className="content">
                <Col md={8} className="ml-auto mr-auto">
                    <h2 className="text-center">User Management</h2>
                </Col>
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                {/*<CardTitle tag="h4">React Table</CardTitle>*/}
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={data}
                                    filterable
                                    resizable={false}
                                    columns={[
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                        },
                                        {
                                            Header: "Assets",
                                            accessor: "position",
                                        },
                                        {
                                            Header: "Office",
                                            accessor: "office",
                                        },
                                        {
                                            Header: "Age",
                                            accessor: "age",
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                        },
                                    ]}
                                    defaultPageSize={10}
                                    showPaginationTop
                                    showPaginationBottom={false}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default UserManagement;
