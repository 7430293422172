import React, { useEffect, useState, useRef } from "react";
import {Route, Switch, Link, useParams, useRouteMatch, Redirect} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// import {KeyPair, createAddress } from "kapow-sdk";
// New Kapow Code - Shubham Sharma
import {
    CreatePBMProgramTransaction,
    CreateVaultTransaction,
    Quantity,
    UpdateVaultTransaction
} from "kapow-sdk";
import { config } from "../config";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    FormGroup,
    Input,
    Progress,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    ModalBody,
    Breadcrumb,
    BreadcrumbItem,
    Alert, Form
} from "reactstrap";

// Functional View
import AssetList from "./Assets/AssetList";

import { setTotalVaultAmount } from "./../actionCreators/setTotalAmount";

import { getNonce } from "../reducers/currentNonce";
import { getCurrentVaultList } from "../reducers/currentVaultsList";

import  { clearStorage } from "../variables/utils";

import NotificationAlert from "react-notification-alert";
import Swal from 'sweetalert2';
import {formatAmount} from "../variables/cutils";
import AvatarList from "./components/AvatarList/AvatarList";
import {DotPulse} from "@uiball/loaders";

const MealVoucherVaults = () => {

    // const key_pair = KeyPair.generateKeyPairFromMnemonic(config.hack.mnemonic);
    // const x = createAddress(3, key_pair.publicKey);
    // console.log("bitcoin address", x);

    console.log("session pub key", sessionStorage.getItem("session_pub_key"));
    const totalVaultAmount = useSelector(state => state.totalVaultAmount);
    // const totalXchangeAmount = useSelector(state => state.totalXchangeAmount);
    const currentVaultNameReducer = useSelector(state => state.currentVaultNameReducer);
    const nonce = useSelector(state => state.currentNonce);
    console.log("Nonce Inside Accounts", nonce);

    const [modalClassic, setModalClassic] = useState(false);
    const [modalClassic1, setModalClassic1] = useState(false);
    const [modalClassicVault, setModalClassicVault] = useState(false);

    const [currentView, setCurrentView] = useState("init");
    const [portfolioData, setPortfolioData] = useState([]);

    const [vault_list, setCurrentVaults] = useState(null);
    const [all_vault_list, setAllVaults] = useState(null);
    const [organisation, setOrganisation] = useState([]);
    const [vaultName, setVaultName] = useState("");
    const [coinPrice, setCoinPrice] = useState([])
    const [assetList, setAssetList] = useState(null);
    // const [currentVault, setCurrentVault] = useState("");
    const [currentVaultAddress, setCurrentVaultAddress] = useState("");
    const [vaultModalState, setVaultModalState] = useState("create");
    const [loginRedirect, setLoginRedirectState] = useState(false);

    const dispatch = useDispatch();
    const notificationAlertRef = useRef(null);

    // Pagination Items
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;
    let pendingInterval;

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const toggleModalClassicVault = () => {
        setModalClassicVault(!modalClassicVault);
    };

    let { path } = useRouteMatch();
    let { id } = useParams();

    useEffect(() => {
        (async () => {
            await getPortfolioData();
            dispatch(getNonce());
            await getOrganisationsByMember();

        })();

        return () => {
            // this now gets called when the component unmounts
            window.clearInterval(pendingInterval);
        };
    }, []);

    const openPop = (msg) => {
        Swal.fire(
            'Success!',
            msg,
            'success'
        )
    }

    const notify = (place, msg) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    async function getOrganisationsByMember () {
        const response = await fetch(
            `${config.api_url}/${config.v}/organisation-members/${localStorage.getItem("ws_address")}/organisations`
        );

        let organization_list = await response.json();
        console.log("portfolio Data ", organization_list);
        if (!response.ok) {
            console.log("RESPONSE ACC YO YO MA NOT OKAY!");
            notify("tr", "Authentication Failed. Login Again.");
            clearStorage();
            setLoginRedirectState(true);
        } else {
            console.log("RESPONSE ACC VERY VERY OKAY!");
            dispatch(getNonce());
            setOrganisation(organization_list.data);

            if(organization_list.data.length > 0) {
                // localStorage.setItem("org_hash", organization_list.data[0].organisation_hash);
                await getCurrentVaults();
            }

        }
    }

    const handleVaultName = (event) => {
        setVaultName(event.target.value);
    }

    async function getPortfolioData () {
        const response = await fetch(
            `${config.api_url}/${config.v}/portfolio/${localStorage.getItem("ws_address")}`
        );

        let portfolioData = await response.json();
        // console.log("portfolio Data ", portfolioData.data);
        setPortfolioData(portfolioData.data)
    }

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx, msg) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );
        const json = await response.json();

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!", json);
            notify("tr", json.message);
        } else {
            toggleModalClassicVault();

            pendingInterval = setInterval(() => {
                getCurrentVaults().then((res) => {
                    console.log("TEST Get Current Vaults - Response", res);
                });
            }, 1000);
            openPop(msg);
        }

        dispatch(getNonce());
        console.log("successful transaction", json);
        return;
        // setTxResponse(json);
    }

    // #TODO-HP. Add Fail Check for Transaction
    async function getCurrentVaults (page_number=1) {
        console.log("Inside GET CurrentVaults FETCH");
        const [vaultsData, coinPriceData, assetData] = await Promise.all([
            fetch(`${config.api_url}/${config.v}/${config.endpoints.accounts}/${config.endpoints.vaults}?address=${localStorage.getItem("ws_address")}&organisationHash=${localStorage.getItem("org_hash")}&limit=${itemsPerPage}&page=${page_number}`),
            fetch(`${config.price_api_url}/${config.v}/${config.price_endpoints.coins}`),
            fetch(`${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`),
        ]);

        const vault_list = await vaultsData.json();
        const coinPrice = await coinPriceData.json();
        const coreAssetList = await assetData.json();

        console.log("vault Data - ", vault_list.data);
        console.log("Price Data - ", coinPrice);
        console.log("Asset List - ", coreAssetList);

        setCurrentVaults(vault_list.data);
        setAllVaults(vault_list.data);
        setCoinPrice(coinPrice);
        setAssetList(coreAssetList);

        setTotalPages(vault_list.paginate.total_page);

        if(vault_list.data && vault_list.data.length > 0) {
            setCurrentView("vault");
            calculateTotalAssetValue(vault_list.data.filter((elem) => elem.create_block !== 0), coreAssetList, coinPrice, "total");
            console.log("test this inside");
            if (vault_list.data[0].create_block === 0) {
            } else {
                window.clearInterval(pendingInterval);
            }

        }

    }

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        getCurrentVaults(selectedPage.selected + 1).then((res) => {
            console.log("Get Current Vaults - Response", res);
        })
    };

    const calculateTotalAssetValue = (vault, coreAssetList, coinPrice, type) => {
        console.log("Vault List Portfolio", vault);
        let totalValue = 0;
        vault.map((singleVault, index) => singleVault.portfolio.map((asset, innerIndex) => totalValue = totalValue + Number(
                Quantity.hexToDecimal(
                    coreAssetList.find(item => item.id === asset.id), asset.confirmed_quantity)) * Number(coinPrice.find(item => item.id === asset.id).price)
            )
        );
        console.log("Total Value", totalValue);
        if (type !== "localised_vault") {
            dispatch(setTotalVaultAmount(totalValue));
        }
        return config.price_symbol + totalValue.toFixed(4);
    }

    const createNewVault = () => {
        console.log("Inside Create New Vault", nonce);
        const new_vault_create_tx = new CreateVaultTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            vaultName,
            config.oracle_address,
            config.price_unit
        );

        new_vault_create_tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = new_vault_create_tx.toBase64();
        sendTx(signedTx, "New Vault Created!").then((res) => {
            console.log("response", res);
        });
    };

    const createNewPBMProgram = () => {
        console.log("Inside Create New PDM Program", nonce);
        const createPBMTx = new CreatePBMProgramTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            "Tata Meal Coin",
            "Ram Coin",
            "ram",
            "https://s2.coinmarketcap.com/static/img/coins/64x64/1321.png",
            "ram lalla ayodhya wapas",
        );

        createPBMTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = createPBMTx.toBase64();
        sendTx(signedTx, "New Vault Created!").then((res) => {
            console.log("response", res);
        });
    };

    const updateNewVault = () => {
        console.log("Inside Create New Vault", nonce);
        const new_vault_update_tx = new UpdateVaultTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            currentVaultAddress,
            "",
            vaultName
        );

        new_vault_update_tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = new_vault_update_tx.toBase64();
        sendTx(signedTx, "Vault Updated!").then((res) => {
            console.log("response", res);
        });
    };

    // Front End Filter for Vault Names.
    const filterList = (searchValue) => {
        if (searchValue === '') {
            setCurrentVaults(all_vault_list);
        } else {
            const filteredData = vault_list.filter((vault) => {
                return vault.vault_name.toLowerCase().includes(searchValue.toLowerCase())
            });
            setCurrentVaults(filteredData);
        }
    }

    if(loginRedirect) {
        return <Redirect to='/auth/login' />
    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <div className="content accounts-view">
                {/* Classic Modal */}
                <Modal isOpen={modalClassic} toggle={toggleModalClassic} className="dark-theme">
                    <div className="modal-header justify-content-left">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassic}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up" style={{color: "white"}}>Bitcoin Addresses</h6>
                    </div>
                    <ModalBody className="justify-content-left">
                        <div className="permanent-address">
                            <h6 style={{fontWeight: "bold"}}>PERMANENT ADDRESS</h6>
                            <div>
                                <span style={{fontWeight: "bold", color: "#fff"}}>Segwit: </span>
                                <span style={{fontSize: "13px", marginLeft: "7px"}}>bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</span>
                                <i style={{marginLeft: "10px", color: "#fff", cursor: "pointer"}} className="tim-icons icon-single-copy-04" />
                            </div>
                            <div>
                                <span style={{fontWeight: "bold", color: "#fff"}}>Legacy: </span>
                                <span style={{fontSize: "13px", marginLeft: "7px"}}>bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</span>
                                <i style={{marginLeft: "10px", color: "#fff", cursor: "pointer"}} className="tim-icons icon-single-copy-04" />
                            </div>
                            <div>
                                <span style={{fontWeight: "bold", color: "#fff"}}>Kraken Description: </span>
                                <span style={{fontSize: "13px", marginLeft: "7px"}}>bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</span>
                                <i style={{marginLeft: "10px", color: "#fff", cursor: "pointer"}} className="tim-icons icon-single-copy-04" />
                            </div>
                        </div>
                        <Alert
                            color="info"
                            className="alert-with-icon"
                            style={{marginTop: "15px", backgroundColor: "#fff3cd", color: "grey"}}
                        >
                            <span data-notify="icon" className="tim-icons icon-alert-circle-exc" />
                            <span data-notify="message" style={{fontSize: "13px"}}>
                The Permanent Address is used for whitelisting addresses on Exchanges.
                Exchanges will send assets to the wallet's legacy permanent address when connected via the Exchange API.
              </span>
                            <button type="button" className="close" aria-label="Close" style={{marginTop: "-10px", backgroundColor: "#fff3cd", color: "grey"}}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </Alert>

                        <div className="other-addresses">
                            <h6 style={{fontWeight: "bold"}}>OTHER ADDRESS</h6>
                            <div style={{height: "60px"}}>
                                <div style={{float: "left"}}>
                                    <input type="text" placeholder="Description or Addresses" style={{width: "250px"}} />
                                    <i style={{paddingLeft: "10px"}} className="tim-icons icon-zoom-split" />
                                </div>
                                <div className="new-vault" style={{float: "right", border: "1px solid white", color: "#fff"}}>
                                    + Address
                                </div>
                                <div style={{clear: "both"}}>&nbsp;</div>
                            </div>

                            <div className="address-detail-card">
                                <div>
                                    <div style={{color: "#fff", fontWeight: "bold"}}>Shmulik's Wallet</div>
                                    <div>
                                        <span style={{color: "#fff", fontWeight: "bold", marginRight: "10px"}}>Legacy:</span>
                                        <span style={{fontSize: "13px", marginLeft: "7px"}}>bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</span>
                                        <i style={{marginLeft: "10px", color: "#fff", cursor: "pointer"}} className="tim-icons icon-single-copy-04" />
                                    </div>
                                </div>
                                <div>
                                    <i className="tim-icons icon-components" style={{color: "white"}}/>
                                </div>
                            </div>

                            <div className="address-detail-card">
                                <div>
                                    <div style={{color: "#fff", fontWeight: "bold"}}>Tom's Wallet</div>
                                    <div>
                                        <span style={{color: "#fff", fontWeight: "bold", marginRight: "10px"}}>Legacy:</span>
                                        <span style={{fontSize: "13px", marginLeft: "7px"}}>bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq</span>
                                        <i style={{marginLeft: "10px", color: "#fff", cursor: "pointer"}} className="tim-icons icon-single-copy-04" />
                                    </div>
                                </div>
                                <div>
                                    <i className="tim-icons icon-components" style={{color: "white"}}/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <div className="modal-footer">
                        <Button style={{visibility: "hidden"}}
                                color="default"
                                type="button"
                                onClick={toggleModalClassic}
                        >
                            Nice Button
                        </Button>
                        <Button
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassic}
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
                {/* End Classic Modal */}

                {/* Classic Modal - 2 Exchange View*/}
                <Modal isOpen={modalClassic1} toggle={toggleModalClassic1} className="dark-theme">
                    <div className="modal-header justify-content-left">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassic1}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up" style={{color: "white"}}>Connect Exchange Account</h6>
                    </div>
                    <ModalBody className="justify-content-left connect-exchange-modal">
                        <div>
                            <Col className="mb-4">
                                {/*<CardTitle tag="h4">Customisable Select</CardTitle>*/}
                                <Row>
                                    <Col lg="12" md="6" sm="3" style={{padding: "0", maxWidth: "100%"}}>
                                        <Card style={{marginTop: "20px", border: "0.5px solid grey", background: "transparent", boxShadow: "none"}}>
                                            <CardHeader>
                                                <CardTitle tag="h4" style={{textAlign: "center"}}>
                                                    <img src={require("assets/img/binance-logo.svg").default} alt="" style={{width: "60px", height: "60px"}}/>
                                                    <div>Binance</div>
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <Form action="#">
                                                    <label>Name on Xaults</label>
                                                    <FormGroup>
                                                        <Input type="email" />
                                                    </FormGroup>
                                                    <label>API Key</label>
                                                    <FormGroup>
                                                        <Input type="password" autoComplete="off" />
                                                    </FormGroup>
                                                    <label>Private Key</label>
                                                    <FormGroup>
                                                        <Input type="password" autoComplete="off" />
                                                    </FormGroup>
                                                    {/*<FormGroup check className="mt-3">*/}
                                                    {/*  <Label check>*/}
                                                    {/*    <Input type="checkbox" />*/}
                                                    {/*    <span className="form-check-sign" />*/}
                                                    {/*    Subscribe to newsletter*/}
                                                    {/*  </Label>*/}
                                                    {/*</FormGroup>*/}
                                                </Form>
                                            </CardBody>
                                            <CardFooter style={{textAlign: "center"}}>
                                                <Button className="btn-fill" color="primary" type="submit">
                                                    Submit
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            {/*<input type="text" placeholder="Search Exchange Name" style={{width: "calc(100% - 35px)", marginBottom: "10px"}} />*/}

                        </div>
                    </ModalBody>

                </Modal>
                {/* End Classic Modal - 2 for Exchanges View*/}

                {/* Vault Modal - 3 for Create Vault Modal */}
                <Modal isOpen={modalClassicVault} toggle={toggleModalClassicVault} className="">
                    <div className="modal-header justify-content-left">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassicVault}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        {/*<h6 className="title title-up" style={{color: "white"}}>Create Vault</h6>*/}
                    </div>
                    <ModalBody className="justify-content-left connect-exchange-modal">
                        <div>
                            <Col className="mb-4">
                                {/*<CardTitle tag="h4">Customisable Select</CardTitle>*/}
                                <Row>
                                    <Col lg="12" md="6" sm="3" style={{padding: "0", maxWidth: "100%"}}>
                                        <Card style={{marginTop: "20px", border: "0.5px solid grey", background: "transparent", boxShadow: "none"}}>
                                            <CardHeader>
                                                <CardTitle tag="h4">
                                                    <div className="vault-logo">
                                                        <div>
                                                            <img src={require("assets/img/vault-transparent.png").default} alt="" />
                                                        </div>
                                                    </div>
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <Form>
                                                    <label>Vault Name</label>
                                                    <FormGroup>
                                                        <Input type="text" placeholder="Enter Your new Vault Name" onChange={handleVaultName}/>
                                                    </FormGroup>
                                                    {/*<label>Choose Assets</label>*/}
                                                </Form>
                                            </CardBody>
                                            <CardFooter style={{textAlign: "center"}}>
                                                {
                                                    vaultModalState === "create" &&
                                                    <Button className="btn-fill" color="primary"  onClick={createNewVault}>
                                                        Create
                                                    </Button>
                                                }
                                                {
                                                    vaultModalState === "update" &&
                                                    <Button className="btn-fill" color="primary"  onClick={updateNewVault}>
                                                        Update
                                                    </Button>
                                                }
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            {/*<input type="text" placeholder="Search Exchange Name" style={{width: "calc(100% - 35px)", marginBottom: "10px"}} />*/}

                        </div>
                    </ModalBody>

                </Modal>
                {/* End Vault Modal - 3 for Create Vault Modal */}
                <Row>

                </Row>

                <Row>
                    <Col lg="4" md="6">
                        <Card className="card-stats" style={{height: "86px"}}>
                            {/*<Link to={`accounts`} onClick={e => setCurrentView("vault")}>*/}
                            <CardBody>
                                <Row>
                                    <Col xs="5">
                                        <div className="info-icon text-center icon-warning">
                                            <i className="tim-icons icon-chat-33" />
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Total CBDC Assets</p>
                                            {
                                                (vault_list && vault_list.length > 0) &&
                                                <CardTitle tag="h3">{formatAmount(totalVaultAmount, 2)}</CardTitle>
                                            }
                                            {
                                                totalVaultAmount === 0 &&
                                                (!vault_list || vault_list.length === 0) &&
                                                <Button className="btn-simple" color="info"  size="sm" onClick={ toggleModalClassicVault }>+ Create Vault</Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            {/*</Link>*/}
                            {/*<CardFooter>*/}
                            {/*  <hr />*/}
                            {/*  <div className="stats">*/}
                            {/*    <i className="tim-icons icon-refresh-01" /> Update Now*/}
                            {/*  </div>*/}
                            {/*</CardFooter>*/}
                        </Card >
                    </Col>
                    <Col lg="4" md="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col xs="5">
                                        <div className="info-icon text-center icon-success">
                                            <i className="tim-icons icon-single-02" />
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Corporate Meal Tokens</p>
                                            {/*<CardTitle tag="h3">$ 54,652,847.28</CardTitle>*/}
                                            <Link to={`meal-voucher-program`}>
                                                <Button className="btn-simple" color="info"  size="sm" style={{padding: "4px 12px"}}>+ New Meal Program</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            {/*<CardFooter>*/}
                            {/*  <hr />*/}
                            {/*  <div className="stats">*/}
                            {/*    <i className="tim-icons icon-trophy" /> Customers feedback*/}
                            {/*  </div>*/}
                            {/*</CardFooter>*/}
                        </Card>
                    </Col>
                    <Col lg="4" md="6">

                        <Card className="card-stats">
                            {/*<Link to={`accounts`} onClick={e => setCurrentView("exchange")}>*/}
                            <CardBody>
                                <Row>
                                    <Col xs="5">
                                        <div className="info-icon text-center icon-primary">
                                            <i className="tim-icons icon-shape-star" />
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Locked - Purpose Bound CBDC Vaults</p>

                                            {/*{*/}
                                            {/*    totalXchangeAmount &&*/}
                                            {/*    <CardTitle tag="h3">{totalXchangeAmount}</CardTitle>*/}
                                            {/*}*/}
                                            {/*<CardTitle tag="h3">$</CardTitle>*/}
                                            <Link to={`apps`}>
                                                {/*<Button className="btn-simple" color="info"  size="sm">+ Connect</Button>*/}
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            {/*</Link>*/}

                            {/*<CardFooter>*/}
                            {/*  <hr />*/}
                            {/*  <div className="stats">*/}
                            {/*    <i className="tim-icons icon-sound-wave" /> Last Research*/}
                            {/*  </div>*/}
                            {/*</CardFooter>*/}
                        </Card>
                    </Col>



                    <Col xs="12">

                    </Col>

                    <Col xs="12">
                        <Card>
                            {
                                currentView !== "init" &&
                                <CardHeader>
                                    <div style={{height: "60px"}}>
                                        <div style={{float: "left"}}>
                                            <input
                                                type="text"
                                                placeholder="Accounts Name or Addresses"
                                                style={{width: "250px"}}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    return filterList(e.target.value)
                                                }}
                                            />
                                            <i style={{paddingLeft: "10px"}} className="tim-icons icon-zoom-split" />
                                        </div>
                                        <Button
                                            color="primary"
                                            className="new-vault btn-simple"
                                            onClick={() => {
                                                setVaultModalState("create");
                                                toggleModalClassicVault();
                                            }}
                                        >
                                            + Create New Vault
                                        </Button>
                                        {/*<div className="new-vault"*/}
                                        {/*     onClick={toggleModalClassicVault} >*/}
                                        {/*  + Exchange Account*/}
                                        {/*</div>*/}
                                        <div>&nbsp;</div>
                                    </div>

                                    <CardTitle tag="h4">
                                        <Breadcrumb>
                                            {
                                                id ? (
                                                    <BreadcrumbItem active>Michael Vault</BreadcrumbItem>
                                                ) : (
                                                    <BreadcrumbItem>
                                                        <Link to={`/admin/vaults`}
                                                        >
                                                            {
                                                                currentView === "vault" &&
                                                                <span>Core Vaults</span>
                                                            }
                                                        </Link>

                                                        {
                                                            currentView === "exchange" &&
                                                            <span>Exchange Accounts</span>
                                                        }
                                                        {
                                                            currentVaultNameReducer !== "" &&
                                                            <span>&nbsp;> {currentVaultNameReducer}</span>
                                                        }
                                                        {
                                                            currentView === "exchange" &&
                                                            <span>Connected Exchanges</span>
                                                        }
                                                    </BreadcrumbItem>
                                                )
                                            }
                                        </Breadcrumb>
                                    </CardTitle>
                                </CardHeader>
                            }

                            {/*    Sub Vault Account View    */}

                            {
                                currentView === "init" &&
                                <div className="first-view">
                                    <div className="step-1">
                                        <div className="vault-logo">
                                            <div>
                                                <img src={require("assets/img/vault-transparent.png").default} alt="" />
                                            </div>
                                        </div>
                                        <div className="step-1__text">
                                            Create Your First Asset Vault
                                            <div className="step-1__text__sub">All of your vaults will be listed on this page.</div>
                                        </div>
                                        <div className="step-1__action">
                                            <Button className="btn-primary" onClick={toggleModalClassicVault}>+ Create Vault</Button>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                currentView === "vault" &&
                                <Switch>
                                    <Route exact path={path}>
                                        <CardBody className="vault-list">

                                            <Table responsive>
                                                <thead className="text-primary" style={{"height": "0"}}>
                                                <tr style={{"height": "0"}}>
                                                    <th className="text-center">&nbsp;</th>
                                                    <th>Vaults</th>
                                                    <th>
                                                        <div>
                                      <span>
                                          { config.price_unit } Amount
                                      </span>
                                                            <i className="tim-icons icon-minimal-down" />
                                                        </div>
                                                    </th>
                                                    <th className="text-center">Assets</th>
                                                    <th className="text-center">Vault Members</th>
                                                    <th style={{textAlign: "right", paddingRight: "10px"}}>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    (vault_list && vault_list.length > 0) &&
                                                    vault_list.map((vault, index) => (
                                                            <tr key={vault.vault_address}>
                                                                <td className="text-center" >
                                                                    <div className="vault-logo">
                                                                        <div>
                                                                            <img src={require("assets/img/vault-transparent.png").default} alt="" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Link
                                                                        to={`vaults/${vault.vault_address}`}
                                                                    >{vault.vault_name}</Link>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        vault.create_block === 0 &&
                                                                        <DotPulse
                                                                            color="#2a6ef6"
                                                                            className="pending_rows"
                                                                            size={23}
                                                                        />
                                                                    }
                                                                    {
                                                                        vault.create_block !== 0 &&
                                                                        assetList && assetList.length > 0 &&
                                                                        coinPrice && coinPrice.length > 0 &&
                                                                        calculateTotalAssetValue([vault], assetList, coinPrice, "localised_vault")
                                                                    }
                                                                    {/*$399,000,000*/}
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="asset-icons-list">
                                                                        {
                                                                            assetList && assetList.length > 0 &&
                                                                            assetList.map((asset, index) => (<div key={index}>
                                                                                <img src={asset.logo} alt={asset.name} />
                                                                            </div>))
                                                                        }
                                                                    </div>
                                                                    {/*<div className="progress-container progress-sm">*/}
                                                                    {/*  <Progress multi>*/}
                                                                    {/*    <span className="progress-value">25%</span>*/}
                                                                    {/*    <Progress bar max="100" value="25" />*/}
                                                                    {/*  </Progress>*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                                <td className="text-center">
                                                                    {
                                                                        vault.create_block === 0 &&
                                                                        <div className="pending_parent">
                                                                            <DotPulse
                                                                                color="#2a6ef6"
                                                                                className="pending_rows"
                                                                                size={23}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        vault.create_block !== 0 &&
                                                                        vault.vault_member &&
                                                                        vault.vault_member.length > 0 &&
                                                                        <AvatarList members={ vault.vault_member } add_member={ true }/>
                                                                    }
                                                                </td>
                                                                <td className="text-right">
                                                                    <Button
                                                                        className="btn-link btn-icon btn-neutral"
                                                                        color="success"
                                                                        id="tooltip618296632"
                                                                        size="sm"
                                                                        title="Refresh"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setVaultModalState("update");
                                                                            setCurrentVaultAddress(vault.vault_address);
                                                                            toggleModalClassicVault();
                                                                        } }
                                                                    >
                                                                        <i className="tim-icons icon-pencil" />
                                                                    </Button>
                                                                    <UncontrolledTooltip
                                                                        delay={0}
                                                                        target="tooltip618296632"
                                                                    >
                                                                        Edit Vault
                                                                    </UncontrolledTooltip>
                                                                    {/*<Button*/}
                                                                    {/*    className="btn-link btn-icon btn-neutral"*/}
                                                                    {/*    color="danger"*/}
                                                                    {/*    id="tooltip707467505"*/}
                                                                    {/*    size="sm"*/}
                                                                    {/*    title="Delete"*/}
                                                                    {/*    type="button"*/}
                                                                    {/*>*/}
                                                                    {/*  <i className="tim-icons icon-simple-remove" />*/}
                                                                    {/*</Button>*/}
                                                                    {/*<UncontrolledTooltip*/}
                                                                    {/*    delay={0}*/}
                                                                    {/*    target="tooltip707467505"*/}
                                                                    {/*>*/}
                                                                    {/*  Archive Vault*/}
                                                                    {/*</UncontrolledTooltip>*/}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                }
                                                </tbody>
                                            </Table>

                                        </CardBody>
                                    </Route>

                                    <Route path={`${path}/:id`}>
                                        <AssetList currentView="vault" getCurrentVaults={getCurrentVaults} />
                                    </Route>
                                </Switch>
                            }

                            {
                                currentView === "exchange" &&
                                <Switch>
                                    <Route exact path={path}>
                                        <CardBody>
                                            <Table responsive>
                                                <thead className="text-primary" style={{"height": "0px"}}>
                                                <tr style={{"height": "0px"}}>
                                                    <th className="text-center">&nbsp;</th>
                                                    <th>&nbsp;</th>
                                                    <th><div>
                    <span>
                      {config.price_unit} Amount
                    </span>
                                                        <i className="tim-icons icon-minimal-down" />
                                                    </div></th>
                                                    <th style={{'color': 'transparent'}}>ABCDEFGHIJP</th>
                                                    <th style={{textAlign: "right", paddingRight: "10px"}}>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="text-center">
                                                        <div className="photo">
                                                            <img
                                                                alt="..."
                                                                src={require("assets/img/binance_logo.jpeg").default}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link to={{
                                                            pathname: `vaults/${config.wallet}`,
                                                            state: { titleExchange: "binance" },
                                                        }}>Binance Wallet</Link>
                                                    </td>
                                                    <td>$399,000,000</td>
                                                    <td className="text-center">
                                                        <div className="progress-container progress-sm">
                                                            <Progress multi>
                                                                <span className="progress-value">25%</span>
                                                                <Progress bar max="100" value="25" />
                                                            </Progress>
                                                        </div>
                                                    </td>
                                                    <td className="text-right">
                                                        <Button
                                                            className="btn-link btn-icon btn-neutral"
                                                            color="success"
                                                            id="tooltip618296632"
                                                            size="sm"
                                                            title="Refresh"
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-refresh-01" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target="tooltip618296632"
                                                        >
                                                            Tooltip on top
                                                        </UncontrolledTooltip>
                                                        <Button
                                                            className="btn-link btn-icon btn-neutral"
                                                            color="danger"
                                                            id="tooltip707467505"
                                                            size="sm"
                                                            title="Delete"
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-simple-remove" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target="tooltip707467505"
                                                        >
                                                            Tooltip on top
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center">
                                                        <div className="photo">
                                                            <img
                                                                alt="..."
                                                                src={require("assets/img/ftx_exchange.png").default}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link to={{
                                                            pathname: `vaults/${config.wallet}`,
                                                            state: { titleExchange: "ftx" },
                                                        }}>FTX</Link>
                                                    </td>
                                                    <td>$ 420,000</td>
                                                    <td className="text-center">
                                                        <div className="progress-container progress-sm">
                                                            <Progress multi>
                                                                <span className="progress-value">15%</span>
                                                                <Progress bar max="100" value="15" />
                                                            </Progress>
                                                        </div>
                                                    </td>
                                                    <td className="text-right">
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="success"
                                                            id="tooltip30547133"
                                                            size="sm"
                                                            title="Refresh"
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-refresh-01" />
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="tooltip30547133">
                                                            Tooltip on top
                                                        </UncontrolledTooltip>
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="danger"
                                                            id="tooltip156899243"
                                                            size="sm"
                                                            title="Delete"
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-simple-remove" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target="tooltip156899243"
                                                        >
                                                            Tooltip on top
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center">
                                                        <div className="photo">
                                                            <img
                                                                alt="..."
                                                                src={require("assets/img/coinbase_exchange.png").default}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>Coinbase</td>
                                                    <td>$ 420,000</td>
                                                    <td className="text-center">
                                                        <div className="progress-container progress-sm">
                                                            <Progress multi>
                                                                <span className="progress-value">15%</span>
                                                                <Progress bar max="100" value="15" />
                                                            </Progress>
                                                        </div>
                                                    </td>
                                                    <td className="text-right">
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="success"
                                                            id="tooltip30547133"
                                                            size="sm"
                                                            title="Refresh"
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-refresh-01" />
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="tooltip30547133">
                                                            Tooltip on top
                                                        </UncontrolledTooltip>
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="danger"
                                                            id="tooltip156899243"
                                                            size="sm"
                                                            title="Delete"
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-simple-remove" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target="tooltip156899243"
                                                        >
                                                            Tooltip on top
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="text-center">
                                                        <div className="photo">
                                                            <img
                                                                alt="..."
                                                                src={require("assets/img/kraken_exchange.jpeg").default}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>Kraken</td>
                                                    <td>$ 420,000</td>
                                                    <td className="text-center">
                                                        <div className="progress-container progress-sm">
                                                            <Progress multi>
                                                                <span className="progress-value">15%</span>
                                                                <Progress bar max="100" value="15" />
                                                            </Progress>
                                                        </div>
                                                    </td>
                                                    <td className="text-right">
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="success"
                                                            id="tooltip30547133"
                                                            size="sm"
                                                            title="Refresh"
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-refresh-01" />
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="tooltip30547133">
                                                            Tooltip on top
                                                        </UncontrolledTooltip>
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="danger"
                                                            id="tooltip156899243"
                                                            size="sm"
                                                            title="Delete"
                                                            type="button"
                                                        >
                                                            <i className="tim-icons icon-simple-remove" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target="tooltip156899243"
                                                        >
                                                            Tooltip on top
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Route>

                                    <Route path={`${path}/:id`}>
                                        <AssetList currentView="exchange"/>
                                    </Route>
                                </Switch>
                            }

                            {/*    Sub Vault Account View    */}
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default MealVoucherVaults;