import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { Asset } from '../models/asset.model';
import { Quantity } from '../utils/quantity';
import { LendingPoolWithdrawData } from "./data/data_lending_pool_withdraw";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class LendingPoolDispenseTransaction extends Transaction {

  // type: number = TransactionTypeEnum.TX_TYPE_LENDING_POOL_DISPENSE;
  type: number = 1529

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, pool: string, asset: Asset, quantity: string, gasPrice: string, gasLimit: number) {
    super();
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new LendingPoolWithdrawData(pool, Quantity.decimalToHex(asset, quantity), gasPrice, gasLimit).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    console.log("payload", payload, payload.sender);
    this.payload = payload.toBase64();
  }
}
