
import React, { useEffect, useRef} from "react";

// used for making the prop types of this component
import PropTypes from "prop-types";
import axios from "axios";

import { Button } from "reactstrap";
import { config } from "config";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import {getNonce} from "../../reducers/currentNonce";
import Swal from "sweetalert2";
import NotificationAlert from "react-notification-alert";

const ImageUpload = ({
  avatar,
  addBtnColor,
  addBtnClasses,
  changeBtnColor,
  changeBtnClasses,
  removeBtnColor,
  removeBtnClasses,
  org_hash,
  upload_type,
  member_address,
  image_src,
  refreshOrgAndProfileData,
  toggleModalClassicImage,
  className,
  user_name,
  designation,
  branch,
}) => {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    avatar ? defaultAvatar : (image_src ? image_src : defaultImage)
  );

  const notificationAlertRef = useRef(null);
  const fileInput = useRef(null);


  const openPop = (message) => {
    Swal.fire(
        'Success!',
        message,
        'success'
    )
  }

  const notify = (place, msg) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
          <div>
            <div>
              <b>{msg}</b> - Error
            </div>
          </div>
      ),
      type: type,
      icon: "tim-icons icon-alert-circle-exc",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    console.log("test", e.target.files[0]);

    const tail_url = upload_type === "profile_pic" ?  "organisation-members/profile" : "organisation/profile";

    // Preparing Payload
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("org_hash", org_hash);

    if (upload_type === "profile_pic") {
      formData.append("address", member_address);
      formData.append("name", user_name);
      formData.append("designation", designation);
      formData.append("branch", branch);
    } else if (upload_type === "org_logo") {
      formData.append("name", JSON.parse(localStorage.getItem("org_list"))[0].organisation_name);
    }

    axios.post(`${config.api_url}/${config.v}/${tail_url}`, formData, {
      // Add parameters here
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
        .then((response) => {
          console.log("upload Success", response.data);
          openPop("Profile Image Updated!");
          // Handle data
          refreshOrgAndProfileData();
        })
        .catch((error) => {
          console.log(error);
          if(error.response && error.response.data) {
            console.log("error", error.response.data.error);
            notify("tr", error.response.data.error);
          }
        })
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself

  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  return (
      <>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <div className="fileinput text-center">
          <input type="file" onChange={handleImageChange} ref={fileInput} accept="image/png, image/jpeg"/>
          <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
            <img className={ className } src={imagePreviewUrl} style={{maxWidth: "200px"}} alt="..." />
          </div>
          <div>
            {file === null ? (
                <Button
                    className="image_btn select btn-simple"
                    color="info"
                    onClick={() => handleClick()}
                >
                  {avatar ? "Add Photo" : "Select image"}
                </Button>
            ) : (
                <span>
            <Button
                color="info"
                className="image_btn  btn-simple change"
                onClick={() => handleClick()}
            >
              Change
            </Button>
            <Button
                color="info"
                className="image_btn  btn-simple done"
                onClick={toggleModalClassicImage}
            >
              Done
            </Button>
                  {avatar ? <br /> : null}
                  <Button
                      color="info"
                      className="image_btn remove btn-simple"
                      onClick={() => handleRemove()}
                  >
              <i className="fa fa-times" /> Remove
            </Button>
          </span>
            )}
          </div>
        </div>
      </>
  );
};

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary",
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
};

export default ImageUpload;
