import * as nacl from 'tweetnacl';
import { Mnemonic } from './mnemonic';
import {Hash} from "./hash";
import { Utils } from './utils';
import { Base58 } from './base58';

export interface KeyPair {
  publicKey: Uint8Array;
  secretKey: Uint8Array;
}

export class KeyPair {
  // generate random key pair
  public static generateKeyPair(): KeyPair {
    const kp = nacl.sign.keyPair();
    return { publicKey: kp.publicKey, secretKey: kp.secretKey };

  }

  public static generateKeyPairFromMnemonic(mnemonic: string) {
    const buff: Buffer = Mnemonic.generateSeedFromMnemonic(mnemonic);
    const buff32: Buffer = buff.slice(0, 32);
    return KeyPair.generateKeyPairFromSeed(buff32);
  }

  // generate key pair from 32 bytes seed
  // @param seed 32 bytes seed
  public static generateKeyPairFromSeed(seed: Uint8Array): KeyPair {
    const kp = nacl.sign.keyPair.fromSeed(seed);
    return { publicKey: kp.publicKey, secretKey: kp.secretKey };
  }

  // generate key pair from 64 bytes secret key
  // @param secretKey 64 bytes secret key
  public static generateKeyPairFromSecretKey(
      secretKey: Uint8Array,
  ): KeyPair {
    const kp =  nacl.sign.keyPair.fromSecretKey(secretKey);
    return { publicKey: kp.publicKey, secretKey: kp.secretKey };
  }

  public static sign(message: Uint8Array, secretKey: Uint8Array): Uint8Array {
    return nacl.sign.detached(message, secretKey);
  }

  // genrate address from keypait
  public static generateAddressFromPublicKey(publicKey: Uint8Array) {
    const version = Utils.toArray(Utils.fromUtf8("0x01"))
    // calculate pubkey hash
    const hash = Hash.keccak256(Utils.toBuffer(publicKey))
    // calculate hash of hash
    const checksum = Hash.keccak256(hash).slice(0,3)
    const address1 =  new Uint8Array([...version, ...Utils.toArray(checksum)])
    const address = new Uint8Array([...address1,  ...Utils.toArray(hash)])
    // encode address
    return Base58.encode(address)
  }
}
