import { Base64 } from "../base64";
import { KeyPair } from "../keys";
import { Utils } from "../utils";

export abstract class Transaction {
  // public nonce: number;
  // public address: string;
  // public multiUserAddress: string;
  // public publicKey: Uint8Array;
  public payload: string; // base64 encoded
  public signature: string; // base64 encoded

  public signTx(secretKey: Uint8Array ) : void {
    var payloadBytes  = Base64.decode(this.payload);
    var signatureBase = KeyPair.sign(payloadBytes, secretKey);
    this.signature = Base64.encode(Utils.toBuffer(signatureBase));
  }

  public toJson(): string {
    return JSON.stringify({
      "payload": this.payload,
      "signature": this.signature
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
