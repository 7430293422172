import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {config} from "../../config";
import "./transactionmodal.scss";

import {
    Button,
    Col, FormGroup, Input, Modal, ModalBody, Row,
} from "reactstrap";
import Transactions  from "../Transactions";
import {KeyPair, TransferTransaction} from "kapow-sdk";
import {ImCopy} from "react-icons/im";
import {CopyToClipboard} from "react-copy-to-clipboard";

import TransactionTable from "../TransactionTable";

const TransactionModal = (props) => {

    const { modalClassic2, setModalClassic2, tx_type, assetId } = props;
    const currentAssetId = useSelector(state => state.currentAssetId);
    const [assetList, setAssetList] = useState(null);

    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic2);
    };

    useEffect(() => {
        (async () => {
            await getAssetList();
        })();
    }, []);

    async function getAssetList () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`
        );

        let assetList = await response.json();
        console.log("asset list shubham ew added data", assetList);
        setAssetList(assetList);
    }

    return (
        <>
            <Modal isOpen={modalClassic2} toggle={toggleModalClassic2} className="transaction-model">
                <div className="modal-header justify-content-left">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalClassic2}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up" style={{color: "white"}}>Transactions</h6>
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    <TransactionTable
                        tx_type={ tx_type }
                        assetId={ assetId }
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default TransactionModal;
