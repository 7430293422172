import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    CreateRuleTransferTransaction,
    UpdateRuleTransferTransaction,
    DeleteRuleTransferTransaction,
    CreateInvoiceTransaction,
    CreateInvoiceTransactionProposal,
    AcceptInvoiceTransaction,
    InvoiceDiscountingTransferTransaction
} from 'kapow-sdk';
import {config} from "../../../../config";

import {
    Button,
    Card, CardBody, CardFooter,
    Col, Form, FormGroup, Input, Label, ModalBody, Row,
} from "reactstrap";
import Select from "react-select";
import Slider from "nouislider";
import Swal from "sweetalert2";
import "./proposalcreation.scss";
import ReactDatetime from "react-datetime";
import NotificationAlert from "react-notification-alert";

const ProposalCreation = (props) => {

    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [IRN, setIRN] = useState("");
    const [sellerGST, setSellerGST] = useState("");
    const [buyerGST, setBuyerGST] = useState("");
    const [invoiceAmount, setInvoiceAmount] = useState(0);
    const [currency, setCurrency] = useState("");
    const [invoiceDueDate, setInvoiceDueDate] = useState("");

    const [nonce, setNonce] = useState(0);

    const [vault, setVault] = useState("");
    const [vault_list, setCurrentVaults] = useState(null);

    const [singleAsset, setSingleAsset] = useState({id:1});
    const [gasETHData, setETHGasData] = useState(null);
    const [gasBTCData, setBTCGasData] = useState(null);
    const [gasKPWData, setKPWGasData] = useState(null);

    const [discountingPercentage, setDiscountingPercentage] = useState(0);
    const [percentFee, setPercentFee] = useState(0);
    const notificationAlertRef = useRef(null);


    let history = useHistory();
    let { id } = useParams();

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Proposal Created',
            'success'
        )
    }

    const notify = (place) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        const notifyMessage = "Proposal Created Successfully";
        let options = {};
        options = {
            place: "tr",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        (async () => {
            await getNonce();

            if(id !== "new") {
                // await getCurrentRule();
            }
            await getCurrentVaults();
        })();

        return () => {
            // Timeout cleanup on Unmount,
            // window.clearTimeout(timeout);
        }

    }, []);



    async function getNonce () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${localStorage.getItem("ws_address")}/sequence`
        );

        const nonce = await response.json();
        setNonce(nonce + 1);
    }

    async function getCurrentVaults (vault_address) {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${config.endpoints.vaults}?address=${localStorage.getItem("ws_address")}&organisationHash=${localStorage.getItem("org_hash")}`
        );

        let vault_list = await response.json();
        console.log("vault Data ", vault_list.data);

        let useAbleList = [];
        useAbleList.unshift({
            value: "",
            label: "Choose a Source Vault",
            name: "Choose a Source Vault",
            isDisabled: true,
        });

        vault_list.data.map((vault, innerIndex) => {
            useAbleList.push({
                value: "",
                label: vault.vault_name,
                name: vault.vault_name,
                address: vault.vault_address
            })
        });
        console.log("Vault List", useAbleList);
        setCurrentVaults(useAbleList);
        if(vault_address) {
            console.log("current Vault =>", useAbleList.find((item) => {
                console.log("Item, item", item);
                return (item.address === vault_address);
            }), vault_address);
            setVault(useAbleList.find(item => item.address === vault_address));
        }
    }

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );
        const newJson = await response.json();

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            notify(newJson.message);
            return;
        } else {
            openPop();
            // notify("successful transaction");
            // dispatch(setCurrentMemberView("member-list"));
            history.push("/admin/proposals");
        }
        const json = await response.json();
        console.log("Proposal Created Successfully", json);
    }

    const createProposal = () => {
        const tx = new CreateInvoiceTransactionProposal(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            vault.address,
            "",
            parseInt(discountingPercentage),
            parseInt(percentFee),
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    }

    const acceptProposal = () => {
        const tx = new AcceptInvoiceTransaction(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "pMtAkwC5enY7Fi8gbF12Gtbv5t6Fz5tBqrkt6ehoitk2mBi3",
            "",
            "0x1adbe0f4eb1727bf5ac16cb2e6e9129c3b4ec466641e72acc07162d6ef3f8c3e",
            "0x94c7b9543cfac534a0c704887293e333651ea3c8a3d43403016b62b95c01e903",
            true
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    }

    async function getETHGasData () {
        const response = await fetch(
            `${config.eth_gas_api}`
        );

        const gas_data = await response.json();
        console.log("ETH gas_data", gas_data.data);
        setETHGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getBTCGasData () {
        const response = await fetch(
            `${config.btc_gas_api}`
        );

        const gas_data = await response.json();
        console.log("BTC gas_data", gas_data.data);
        setBTCGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getKPWGasData () {
        const response = await fetch(
            `${config.kapow_gas_api}`
        );

        const gas_data = await response.json();
        console.log("KPW gas_data", gas_data.data);
        setKPWGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    const initiateTransaction = () => {

        const tx = new InvoiceDiscountingTransferTransaction(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "pMtAkwC5enY7Fi8gbF12Gtbv5t6Fz5tBqrkt6ehoitk2mBi3",
            "",
            "0x1adbe0f4eb1727bf5ac16cb2e6e9129c3b4ec466641e72acc07162d6ef3f8c3e",
            "0x94c7b9543cfac534a0c704887293e333651ea3c8a3d43403016b62b95c01e903",
            "100",
            {
                "id": 1,
                "blockchain_id": 1,
                "name": "eRupee-R",
                "symbol": "e₹-R",
                "description": "",
                "logo": "https://res.cloudinary.com/kapow-tech/image/upload/v1676914208/erupee_vii3is.png",
                "decimals": 2,
                "supply": "0x1000000000000000000000",
                "type": 7001,
                "sub_type": 0,
                "contract": "",
                "creator": "cnCEFpjcUwQDNx7NAadcGRjKxh6bnjdAJBmgLr1HKc6iML44",
                "website": "",
                "white_paper": "",
                "source_code": "",
                "status_code": 801
            },
            "XWt5skC1Ygsow4hrBC2ueB74RHJZNnoXmF9CxDBFm5V1Di1K", // Invoice Vault COKE VAULT
            "pMtAkwC5enY7Fi8gbF12Gtbv5t6Fz5tBqrkt6ehoitk2mBi3", // Proposal Vault INVOICE DISCOUTING VAULT
            "0",
            0

        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    };

    return (
        <>
            <div className="rules-modal">
                <div className="rna-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <div className="modal-header justify-content-left">
                    <h6 className="title title-up">Proposal Details</h6>
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    <div>

                        <Row>
                            <Col md="12">
                                <Form id="RegisterValidation">
                                    <Card className="rule-set">
                                        <CardBody>
                                            <FormGroup>
                                                <label>Maximum Discounting Percentage</label>
                                                <Input
                                                    name="rule"
                                                    id="discounting_percentage"
                                                    type="text"
                                                    value={discountingPercentage}
                                                    placeholder="Enter the Discounting Percentage"
                                                    onChange={(e) => setDiscountingPercentage(e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup className="calc-width">
                                                <label>Vault Address</label>
                                                {
                                                    vault_list && vault_list.length > 0 &&
                                                    <Col lg="12" md="12" sm="3">
                                                        <Select
                                                            style={{color: "#fff"}}
                                                            className="react-select info"
                                                            classNamePrefix="react-select"
                                                            name="vault"
                                                            value={vault}
                                                            onChange={(value) => {
                                                                console.log("value", value);
                                                                setVault(value);
                                                            }}
                                                            getOptionLabel={value => (
                                                                <div>
                                                                    <img
                                                                        className="asset-logo-icon"
                                                                        src={require("assets/img/vault-transparent.png").default}
                                                                        alt=""
                                                                    />
                                                                    <span>{value.name}</span>
                                                                </div>
                                                            )}
                                                            options={vault_list}
                                                            placeholder="Choose Vault to Receive Funds"
                                                        />
                                                    </Col>
                                                }
                                            </FormGroup>

                                            <FormGroup>
                                                <label>Percentage Fee</label>
                                                <Input
                                                    name="percentFee"
                                                    id="percent_fee"
                                                    type="text"
                                                    value={percentFee}
                                                    placeholder="Enter the Fee Percentage"
                                                    onChange={(e) => setPercentFee(e.target.value)}
                                                />
                                            </FormGroup>
                                        </CardBody>

                                        <CardFooter className="text-right">
                                            <FormGroup check className="pull-left">
                                                <Label check>
                                                    &nbsp;
                                                </Label>
                                            </FormGroup>
                                            {
                                                id && id === "new" &&
                                                <Button color="primary" onClick={ createProposal }>
                                                    Create Proposal
                                                </Button>
                                            }
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </div>
        </>
    );
};

export default ProposalCreation;
