import VectorMap from "views/maps/VectorMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import ReactTables from "views/tables/ReactTables.js";
import RegularTables from "views/tables/RegularTables.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import Wizard from "views/forms/Wizard.js";
import ValidationForms from "views/forms/ValidationForms.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import RegularForms from "views/forms/RegularForms.js";
import Calendar from "views/Calendar.js";
import Widgets from "views/Widgets.js";
import Charts from "views/Charts.js";
import Dashboard from "views/Dashboard.js";

import Buttons from "views/components/Buttons.js";
import SweetAlert from "views/components/SweetAlert.js";
import Notifications from "views/components/Notifications.js";
import Grid from "views/components/Grid.js";
import Typography from "views/components/Typography.js";
import Panels from "views/components/Panels.js";
import Icons from "views/components/Icons.js";

// import Pricing from "views/pages/Pricing.js";
// import Register from "views/pages/Register.js";
import RegisterOrganization from "views/pages/RegisterOrganization";
import Onboarding from "views/Onboarding/Onboarding";
import Profile from "views/Profile/Profile";
// import Timeline from "views/pages/Timeline.js";
import User from "views/pages/User.js";
import Login from "views/pages/Login.js";
// import Rtl from "views/pages/Rtl.js";
import Lock from "views/pages/Lock.js";

/*    Core Pages    */
import Accounts from "views/Accounts.js";
import Governance from "views/Governance.js";
import Transactions from "views/Transactions";
import Insights from "./views/Insights";
import Apps from "./views/third_party_apps/Apps";
import AdminConsole from "./views/ManageTeam/AdminConsole";
import UserManagement from "./views/UserManagement";
import Pools from "./views/Pools/Pools";
import Portfolio from "./views/Portfolio";
import InvoiceDiscounting from "./views/TradeFinance/InvoiceDiscouting/InvoiceDiscounting";
import ProposalCreation from "./views/TradeFinance/InvoiceDiscouting/ProposalCreation/ProposalCreation";
import Invoices from "./views/TradeFinance/InvoiceDiscouting/Invoices";
import Proposals from "./views/TradeFinance/InvoiceDiscouting/Proposals";
// import Login from "views/Login/Login";

// Meal Voucher Program
import MealVoucherVaults from "./views/MealVoucherVaults";
import MealVoucherProgram from "./views/MealVoucherProgram";
import LoyaltyProgram from "./views/Loyalty/LoyaltyProgram";


const routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    collapse: true,
    name: "My Funds",
    rtlName: "صفحات",
    icon: "tim-icons icon-chart-pie-36",
    // icon: "tim-icons icon-image-02",
    state: "pagesCollapse",
    showOnSidebar: true,
    views: [
      {
        path: "/vaults",
        name: "Vaults",
        rtlName: "عالتسعير",
        mini: "P",
        icon: "tim-icons icon-wallet-43",
        rtlMini: "ع",
        //   component: Accounts,
        component: MealVoucherVaults,
        showOnSidebar: true,
        layout: "/admin",
      },
      {
        path: "/portfolio",
        name: "Portfolio",
        rtlName: "عالتسعير",
        mini: "P",
        icon: "tim-icons icon-coins",
        rtlMini: "ع",
        component: Portfolio,
        showOnSidebar: true,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/governance",
    name: "Governance",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: Governance,
    showOnSidebar: true,
    layout: "/admin",
  },
  {
    path: "/pools",
    name: "Lending Pools",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-money-coins",
    component: Pools,
    showOnSidebar: true,
    layout: "/admin",
  },
  {
    path: "/loyalty",
    name: "Corporate Loyalty",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-credit-card",
    component: LoyaltyProgram,
    showOnSidebar: true,
    layout: "/admin",
  },
  {
    path: "/meal-voucher-program",
    name: "Meal Program",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-credit-card",
    component: MealVoucherProgram,
    showOnSidebar: true,
    layout: "/admin",
  },
  // {
  //   path: "/invoices",
  //   name: "Invoices",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-money-coins",
  //   component: Invoices,
  //   showOnSidebar: true,
  //   layout: "/admin",
  // },
  // {
  //   path: "/proposals",
  //   name: "Proposals",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-money-coins",
  //   component: Proposals,
  //   showOnSidebar: true,
  //   layout: "/admin",
  // },
  {
    path: "/transactions",
    name: "Transactions",
    rtlName: "عالتسعير",
    mini: "P",
    icon: "tim-icons icon-coins",
    rtlMini: "ع",
    component: Transactions,
    showOnSidebar: true,
    layout: "/admin",
  },
  {
    path: "/console",
    name: "Manage Team",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: AdminConsole,
    showOnSidebar: true,
    layout: "/admin",
  },
  // {
  //   collapse: true,
  //   name: "Trade Finance",
  //   rtlName: "صفحات",
  //   icon: "tim-icons icon-chart-pie-36",
  //   // icon: "tim-icons icon-image-02",
  //   state: "tradeCollapse",
  //   showOnSidebar: true,
  //   views: [
  //     {
  //       path: "/discounting",
  //       name: "Invoice Discounting",
  //       rtlName: "عالتسعير",
  //       mini: "P",
  //       icon: "tim-icons icon-wallet-43",
  //       rtlMini: "ع",
  //       component: InvoiceDiscounting,
  //       showOnSidebar: true,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/proposal",
  //       name: "Proposal",
  //       rtlName: "عالتسعير",
  //       mini: "P",
  //       icon: "tim-icons icon-wallet-43",
  //       rtlMini: "ع",
  //       component: ProposalCreation,
  //       showOnSidebar: true,
  //       layout: "/admin",
  //     }
  //   ],
  // },
  {
    path: "/users",
    name: "User Management",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: UserManagement,
    showOnSidebar: false,
    layout: "/admin",
  },
  {
    path: "/profile/:id",
    name: "My Profile",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: Profile,
    showOnSidebar: false,
    layout: "/admin",
  },
  {
    path: "/apps",
    name: "Connect Apps",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-app",
    component: Apps,
    showOnSidebar: true,
    layout: "/admin",
  },
  {
    path: "/insights",
    name: "Insights",
    rtlName: "عالتسعير",
    mini: "P",
    icon: "tim-icons icon-chart-bar-32",
    rtlMini: "ع",
    component: Insights,
    showOnSidebar: false,
    layout: "/admin",
  },
  {
    path: "/whitelisted",
    name: "Whitelisted Addresses",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: Dashboard,
    showOnSidebar: false,
    layout: "/admin",
  },
  {
    path: "/network",
    name: "Network",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-molecule-40",
    component: Dashboard,
    showOnSidebar: false,
    layout: "/admin",
  },
  // {
  //   path: "/transaction",
  //   name: "Transaction History",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-paper",
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    path: "/status",
    name: "Status Page",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    showOnSidebar: false,
    layout: "/admin",
  },
  {
    path: "/help",
    name: "Help Center",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    showOnSidebar: false,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: "tim-icons icon-molecule-40",
    state: "componentsCollapse",
    showOnSidebar: false,
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MLT",
        rtlMini: "ر",
        state: "multiCollapse",
        showOnSidebar: false,
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            showOnSidebar: false,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: Grid,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        showOnSidebar: false,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "tim-icons icon-notes",
    state: "formsCollapse",
    showOnSidebar: false,
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        showOnSidebar: true,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        showOnSidebar: true,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        showOnSidebar: true,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        showOnSidebar: true,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: "tim-icons icon-puzzle-10",
    state: "tablesCollapse",
    showOnSidebar: false,
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        showOnSidebar: false,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    state: "mapsCollapse",
    showOnSidebar: false,
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        showOnSidebar: false,
        layout: "/admin",
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        showOnSidebar: false,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: "tim-icons icon-settings",
    component: Widgets,
    showOnSidebar: false,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: "tim-icons icon-chart-bar-32",
    showOnSidebar: false,
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: "tim-icons icon-time-alarm",
    showOnSidebar: false,
    component: Calendar,
    layout: "/admin",
  },
  // {
  //   path: "/pricing",
  //   name: "Pricing",
  //   rtlName: "عالتسعير",
  //   mini: "P",
  //   rtlMini: "ع",
  //   component: Pricing,
  //   layout: "/auth",
  // },
  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "صودعم رتل",
  //   mini: "RS",
  //   rtlMini: "صو",
  //   component: Rtl,
  //   layout: "/rtl",
  // },
  // {
  //   path: "/timeline",
  //   name: "Timeline",
  //   rtlName: "تيالجدول الزمني",
  //   mini: "T",
  //   rtlMini: "تي",
  //   component: Timeline,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: Login,
    showOnSidebar: false,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register Organization",
    rtlName: "تسجيل",
    mini: "R",
    rtlMini: "صع",
    component: RegisterOrganization,
    showOnSidebar: false,
    layout: "/auth",
  },
  {
    path: "/lock-screen",
    name: "Lock Screen",
    rtlName: "اقفل الشاشة",
    mini: "LS",
    rtlMini: "هذاع",
    component: Lock,
    showOnSidebar: false,
    layout: "/auth",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    mini: "UP",
    rtlMini: "شع",
    component: User,
    showOnSidebar: false,
    layout: "/admin",
  },
];

export default routes;
