import { Base64 } from "../../base64";

export class CreateOrganisationData {
  name: string;
  type: number
  
  constructor(name: string, type: number) {
    this.name = name;
    this.type = type; 
  }

  public static fromJson(json: any): CreateOrganisationData {
    return new CreateOrganisationData(
      json["name"],
      json["orgType"]
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "name": this.name,
      "type": this.type
    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
