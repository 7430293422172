/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from "react";
import {Link, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
// import { Base64 } from "../kapow-sdk";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Modal, ModalBody, Alert,
} from "reactstrap";

import CreatePool from "./CreatePool/CreatePool";
import SinglePool from "./SinglePool/SinglePool";
import {config} from "../../config";

import {clip_address} from "../../variables/utils";
import "./pools.scss";

const Pools = () => {


    const [poolList, setPoolList] = useState([]);
    const [modalClassic, setModalClassic] = useState(false);
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        (async () => {
            await getPoolList();
        })();
    }, []);


    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    async function getPoolList () {
        const response = await fetch(
            `${config.api_url}/${config.v}/organisation/${localStorage.getItem("org_hash")}/${config.endpoints.lending_pools}`
        );

        let poolList = await response.json();
        console.log("Pools List response ", poolList.data);
        setPoolList(poolList.data)
    }

    let { path } = useRouteMatch();
    let { id } = useParams();

    return (
        <>
            <div className="content pools">
                <Switch>
                    <Route exact path={path}>
                        <Row>
                            <Link to={`pools/new`}>
                                <Button
                                    className="btn-simple" color="info" onClick={toggleModalClassic}
                                    style={{marginLeft: "15px", cursor: "pointer", marginBottom: "20px"}}
                                >
                                    + Create New Lending Pool
                                </Button>
                            </Link>

                            {
                                poolList && poolList.length === 0 &&
                                <Card>
                                    <div className="first-view empty-pool-list">
                                        <div className="step-1">
                                            <div className="team-image">
                                                <div>
                                                    <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1672742628/web_application/lending_init.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="step-1__text">Create Your First Lending Pool</div>
                                            <Link to={`pools/new`}>
                                                <div className="step-1__action">
                                                    <Button className="btn-primary" toggleModalClassic={toggleModalClassic}>+ Start Lending</Button>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Card>
                            }

                            <Col lg="12" className="pool-group">
                                {
                                    poolList && poolList.length > 0 &&
                                    poolList.map((singlePool, index) => <SinglePool key={singlePool.address} pool={singlePool} index={index} />)
                                }
                            </Col>
                        </Row>
                    </Route>

                    <Route path={`${path}/:id`}>
                        <CreatePool modalClassic={modalClassic} toggleModalClassic={toggleModalClassic} />
                    </Route>
                </Switch>
            </div>
        </>
    );
};

export default Pools;