import { Base64 } from "../../base64";

export class RemoveOrganisationMemberData {
  address: string;
  
  constructor(address: string) {
    this.address = address;
  }

  public static fromJson(json: any): RemoveOrganisationMemberData {
    return new RemoveOrganisationMemberData(
      json["address"],
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "address": this.address,
    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
