import { Base64 } from "../../base64";

export class CreateLendingPoolData {
  name: string;
  collateralAssetID: number;
  borrowAssetID: number;
  lendingRate: number;
  collateralRatio: number;
  liquidationThresold: number;
  allowExternal: boolean;
  maxLoanPeriod: number;
  assetPriceOracle: string;
  priceUnit: string;
  
  constructor( name: string,
    collateralAssetID: number,
    borrowAssetID: number,
    lendingRate: number,
    collateralRatio: number,
    liquidationThresold: number,
    allowExternal: boolean,
    maxLoanPeriod: number,
    assetPriceOracle: string,
    priceUnit: string) {
    
      this.name = name;
      this.collateralAssetID = collateralAssetID;
      this.borrowAssetID = borrowAssetID;
      this.lendingRate = lendingRate;
      this.collateralRatio = collateralRatio;
      this.liquidationThresold = liquidationThresold;
      this.allowExternal = allowExternal;
      this.maxLoanPeriod = maxLoanPeriod;
      this.assetPriceOracle = assetPriceOracle;
      this.priceUnit = priceUnit;      
  }

  public static fromJson(json: any): CreateLendingPoolData {
    return new CreateLendingPoolData(
      json["name"],
      json["collateral_asset_id"],
      json["borrow_asset_id"],
      json["lending_rate"],
      json["collateral_ratio"],
      json["liquidation_threshold"],
      json["allow_external"],
      json["max_loan_period"],
      json["asset_price_oracle"],
      json["price_unit"]
    );
  }

  public toJson(): string {
    return JSON.stringify({
      "name": this.name,
      "collateral_asset_id": this.collateralAssetID,
      "borrow_asset_id": this.borrowAssetID,
      "lending_rate": this.lendingRate,
      "collateral_ratio": this.collateralRatio,
      "liquidation_threshold": this.liquidationThresold,
      "allow_external": this.allowExternal,
      "max_loan_period": this.maxLoanPeriod,
      "asset_price_oracle": this.assetPriceOracle,
      "price_unit": this.priceUnit
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
