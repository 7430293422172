import { Base64 } from "../../base64";

export class LendingPoolWithdrawData {
  pool: string;
  // assetID: number;
  quantity: string;
  gasPrice: string; // gas price - hex value
  gasLimit: number; // gas limit
  
  constructor( pool: string,
    // assetID: number,
    quantity: string,
    gasPrice: string,
    gasLimit: number) {
    
      this.pool = pool;
      // this.assetID = assetID;
      this.quantity = quantity;    
      this.gasPrice = gasPrice;
      this.gasLimit = gasLimit;  
  }

  public static fromJson(json: any): LendingPoolWithdrawData {
    return new LendingPoolWithdrawData(
      json["pool"],
      // json["asset_id"],
      json["quantity"],
      json["gas_price"],
      json["gas_limit"]
    );
  }

  public toJson(): string {
    return JSON.stringify({
      "pool": this.pool,
      // "asset_id": this.assetID,
      "quantity": this.quantity,
      "gas_price": this.gasPrice,
      "gas_limit": this.gasLimit
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
