import { Base64 } from "../base64";
import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { CreateInvoiceData } from "./data/data_invoice_create";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class CreateInvoiceTransaction extends Transaction {
  
  // type: number = TransactionTypeEnum.TX_TYPE_ORGANISATION_CREATE;
  type: number = 1531;

  // data variables
  name: string;

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, invoiceNumber: string, invoiceDate: number, irn: string, sellerGST: string, buyerGST: string, totalInvoiceAmount: number, currency: string, dueDate: number) {
    super();   
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new CreateInvoiceData(invoiceNumber, invoiceDate, irn, sellerGST, buyerGST, totalInvoiceAmount, currency, dueDate).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();
  }
}
