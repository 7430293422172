import React, {useEffect, useState} from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {config} from "../../config";

import TransferModal from "../Modals/TransferModal";
import ReceiveModal from "../Modals/ReceiveModal";
import TransactionModal from "../Modals/TransactionModal";
import { Quantity } from "kapow-sdk";
// import { faker } from '@faker-js/faker';
import { setCurrentAssetId } from "../../actionCreators/setAssetList";

import { setCurrentVault } from "../../actionCreators/setCurrentVault";
import kapow_transparent from "./../../assets/img/kapow_transparent.png"
import './assetlist.scss';

import {
    Button,
    CardBody,
    Table,
    UncontrolledTooltip,
} from "reactstrap";


const AssetList = (props) => {

    const baseLocations = useLocation();
    let { id } = useParams();
    let { currentView, getCurrentVaults } = props;

    const currentExchange = baseLocations.state ? baseLocations.state.titleExchange : "binance";
    // console.log("titleLocation", currentExchange);

    // const generateFaker = () => faker.image.avatar();
    // console.log("generateFaker", generateFaker());
    const dispatch = useDispatch();

    React.useEffect(() => {
        (async () => {
            // store.dispatch(startSocket());
        })();

        return function cleanup() {

        };
    });

    const [modalClassic, setModalClassic] = useState(false);
    const [modalClassic1, setModalClassic1] = useState(false);
    const [modalClassic2, setModalClassic2] = useState(false);

    const [portfolio, setPortfolio] = useState([]);
    const [coinPrice, setCoinPrice] = useState([])
    const [assetList, setAssetList] = useState(null);
    const [xAssetList, setXAssetList] = useState([]);

    const [total, setTotal] = useState(null);
    const [accountDetails, setAccountDetails] = useState(null);
    const [currentAssetAddress, setCurrentAssetAddress] = useState("");
    const [assetId, setAssetId] = useState(null);

    const exchange_token_logos = [
        {
            name: "BAT",
            url: "https://res.cloudinary.com/kapow-tech/image/upload/v1655622042/basic-attention-token-bat-logo_alfip8.svg"
        },
        {
            name: "EON",
            url: "https://res.cloudinary.com/kapow-tech/image/upload/v1655622043/dimension_chain_kno31x.png",
        },
        {
            name: "ADD",
            url: "https://res.cloudinary.com/kapow-tech/image/upload/v1655622042/add_xyz_ubef2q.png",
        },
        {
            name: "MEETONE",
            url: "https://res.cloudinary.com/kapow-tech/image/upload/v1655622042/meet_one_lxbuly.png",
        },
        {
            name: "ATD",
            url: "https://res.cloudinary.com/kapow-tech/image/upload/v1655622042/ATD_bbe7uh.png",
        },
        {
            name: "EOP",
            url: "https://res.cloudinary.com/kapow-tech/image/upload/v1655622042/EOP_pocqz7.jpg"
        }
    ]

    useEffect(() => {
        (async () => {
            await getPortfoliosAndCoinPrice();
            await getAssetList();
            await getCurrentVault();
        })();

        return () => {
            // unmount code cleanup goes here.
            dispatch(setCurrentVault(""));
            console.log("Inside Asset list Unmounting");
        }
        // requestXAccountDetails();
    }, []);

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const toggleModalClassic1 = (address) => {
        console.log("toggle classic 1");
        setCurrentAssetAddress(address);
        setModalClassic1(!modalClassic1);
    };

    const toggleModalClassic2 = () => {
        console.log("toggle classic 2");

        setModalClassic2(!modalClassic2);
    };

    async function getCurrentVault () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.vault}/${id}`
        );

        let vault = await response.json();
        console.log("vault List test", vault);
        dispatch(setCurrentVault(vault.vault_name));
    }

    // Requesting Portfolio Data
    async function getPortfoliosAndCoinPrice () {

        const [portfolioData, coinPriceData, assetData] = await Promise.all([
            fetch(`${config.api_url}/${config.v}/${config.endpoints.portfolio}/${id}`),
            fetch(`${config.price_api_url}/${config.v}/${config.price_endpoints.coins}`),
            fetch(`${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`),
        ]);

        const portfolio = await portfolioData.json();
        const coinPrice = await coinPriceData.json();
        const coreAssetList = await assetData.json();

        setAssetList(coreAssetList);

        // const currentTotalVault = getTotal(portfolio, coinPrice, coreAssetList);
        //
        // dispatch(setTotalVaultAmount(currentTotalVault));

        setPortfolio(portfolio);
        setCoinPrice(coinPrice);
        console.log("portfolio YO YO", portfolio);

        // morphFTXList(ftxAssetList);
        if (currentExchange === "binance") {
            // makeBalancedList(clonedXAssets, "binance");
        } else if (currentExchange === "ftx") {
            // makeBalancedList(ftxAssetList, "ftx");
        }

        return [portfolio, coinPrice];
    }

    // async function requestXAccountDetails() {
    //     let response = await fetch('http://127.0.0.1:8000/api/v1/xassets');
    //     let data = await response.json();
    //     console.log("shubham's first API Data", data);
    //     makeBalancedList(data)
    // }

    const morphFTXList = (data, exchange) => {
        let filteredList = data.filter((balance) => parseFloat(balance.free) > 0)
        const morphedList = filteredList = filteredList.map((elem, index) =>  {
            return {
                "id": index,
                "logo": exchange_token_logos[exchange_token_logos.findIndex(logos => logos.name === elem.coin)].url,
                "name": elem.coin,
                "quantity": elem.free,
                "sub_type": "0",
                "symbol": elem.coin,
                "type": "7001",
                "usd_value": elem.usdValue ? elem.usdValue : null,
                "not_hex": true
            }
        })

        if (currentExchange === "ftx") {
            setXAssetList(morphedList);
        }
    }

    const makeBalancedList = (data) => {

        let balancesList = data;
        const asArray = Object.entries(balancesList);
        console.log("balancesList", asArray);

        // awb => assets with balances. We ignore 0 balance assets for now
        let filteredList = Object.entries(balancesList).filter(([balance, value]) => {
            return parseFloat(value.free) > 0;
        });
        console.log("filteredList", filteredList);

        filteredList = filteredList.map(elem =>  {
            console.log("indexes", exchange_token_logos.findIndex(logos => logos.name === elem[0]));
            return {
                "id": elem[0],
                "logo": exchange_token_logos[exchange_token_logos.findIndex(logos => logos.name === elem[0])].url,
                "name": elem[0],
                "quantity": elem[1].free,
                "sub_type": "0",
                "symbol": elem[0],
                "type": "7001",
                "not_hex": true
            }
        })

        // const currentTotal = getTotal(filteredList, coinPrice);
        // dispatch(setTotalXchangeAmount(currentTotal));
        setXAssetList(filteredList);

    }

    let totalValue = null;
    const getTotal = (filteredList, coinPrice, coreAssetList) => {
        console.log("filtered", filteredList);
        filteredList.map((asset, index) => {
            let currentAssetValue;
            if(asset.not_hex) {
                // TODO  Get Prices of all the tokens
                currentAssetValue = 5
            } else {
                currentAssetValue = Number(Quantity.hexToDecimal(coreAssetList[coreAssetList.findIndex(elem => elem.id === asset.id)], asset.quantity) * ((coinPrice[index]).price ? (coinPrice[index]).price : 1));
            }
            if (!totalValue) {
                totalValue = currentAssetValue;
            } else {
              totalValue = (totalValue + currentAssetValue);
            }
        })
        return totalValue;
    }

    async function getAssetList () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`
        );

        let assetList = await response.json();
        console.log("asset list shubham ew added data", assetList);
        setAssetList(assetList);
    }

    // async function getTransactions () {
    //     const response = await fetch(
    //         `${config.api_url}/${config.v}/${config.endpoints.transactions}?limit=100&page=0&order=desc&txTypes=1513&assetID=${2}&vault=${id}`
    //     );
    //
    //     let transactionList = await response.json();
    //     console.log("Transaction response ", transactionList.data, transactionList.data.length);
    //     setTransaction(transactionList.data)
    // }

    return (
        <>
            {
                props && props.currentView === "vault" &&
                <CardBody className="asset-list">
                    <Table responsive>
                        <thead className="text-primary zero-h">
                        <tr style={{"height": "0px"}}>
                            <th className="text-center">&nbsp;</th>
                            <th>Crypto</th>
                            <th>Units</th>
                            <th>Amount<sub> in ({config.price_unit})</sub> </th>
                            <th>Current Price<sub> / Token</sub></th>
                            <th style={{textAlign: "right", paddingRight: "30px"}}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            portfolio && portfolio.length > 0 &&
                            portfolio.map((portfolio, index) => (
                                <tr key={portfolio.id}>
                                    <td className="text-center">
                                        <div className={`${portfolio.symbol === "KPW" ? "photo kapow-logo" : "photo"}`}>
                                            <img alt="..." src={portfolio.symbol === "KPW" ?  kapow_transparent : portfolio.logo} />
                                        </div>
                                    </td>
                                    <td>
                                        <div>{portfolio.symbol}</div>
                                        <div style={{color: "grey", fontSize: "12px"}}>{portfolio.name}</div>
                                    </td>
                                    <td>
                                        {
                                            assetList && assetList.length > 0 &&
                                            <div>
                                                <div>
                                                    { Number(Quantity.hexToDecimal(assetList[index], portfolio.confirmed_quantity)) }
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            coinPrice && coinPrice.length > 0 &&
                                            assetList && assetList.length > 0 &&
                                            <div>
                                                <div>
                                                    { Number(Quantity.hexToDecimal(assetList[index], portfolio.confirmed_quantity) * coinPrice[index].price).toFixed(4) } { config.price_unit }
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            coinPrice && coinPrice.length > 0 &&
                                            <div>
                                                <div>
                                                    { Number( coinPrice[index].price).toFixed(4) } { config.price_unit }
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td className="text-right action-buttons">
                                        <Button
                                            className="btn-simple" color="info"
                                            onClick={() => {
                                                dispatch(setCurrentAssetId(portfolio.id));
                                                setModalClassic2(true);
                                            }}
                                        >
                                            See Txs
                                        </Button>

                                        <Button
                                            className="btn-simple" color="info"
                                            onClick={() => {
                                                dispatch(setCurrentAssetId(portfolio.id));
                                                setModalClassic(true);
                                            }}
                                        >
                                            Transfer
                                        </Button>

                                        <Button
                                            className="btn-simple" color="info"
                                            onClick={() => {
                                                toggleModalClassic1(portfolio.address);
                                            }}>
                                            Receive
                                        </Button>

                                        {/*<Button*/}
                                        {/*    className="btn-link btn-icon btn-neutral"*/}
                                        {/*    color="success"*/}
                                        {/*    id="tooltip61829669999"*/}
                                        {/*    size="sm"*/}
                                        {/*    title="Refresh"*/}
                                        {/*    type="button"*/}
                                        {/*    onClick={toggleModalClassic2}*/}
                                        {/*>*/}
                                        {/*    /!*<i className="tim-icons icon-map-big" onClick={toggleModalClassic} />*!/*/}
                                        {/*    <i className="tim-icons icon-map-big"  />*/}
                                        {/*</Button>*/}
                                        {/*<UncontrolledTooltip*/}
                                        {/*    delay={0}*/}
                                        {/*    target="tooltip61829669999"*/}
                                        {/*>*/}
                                        {/*    See Transactions*/}
                                        {/*</UncontrolledTooltip>*/}

                                        {/*<Button*/}
                                        {/*    className="btn-link btn-icon btn-neutral"*/}
                                        {/*    color="success"*/}
                                        {/*    id="tooltip618296632"*/}
                                        {/*    size="sm"*/}
                                        {/*    title="Refresh"*/}
                                        {/*    type="button"*/}
                                        {/*    onClick={toggleModalClassic} >*/}
                                        {/*    <i className="tim-icons icon-send" />*/}
                                        {/*</Button>*/}


                                        {/*<UncontrolledTooltip*/}
                                        {/*    delay={0}*/}
                                        {/*    target="tooltip618296632"*/}
                                        {/*>*/}
                                        {/*    Send*/}
                                        {/*</UncontrolledTooltip>*/}
                                        {/*<Button*/}
                                        {/*    className="btn-link btn-icon btn-neutral btn-flip"*/}
                                        {/*    color="danger"*/}
                                        {/*    id="tooltip707467505"*/}
                                        {/*    size="sm"*/}
                                        {/*    title="Delete"*/}
                                        {/*    type="button"*/}
                                        {/*    onClick={() => {*/}
                                        {/*        toggleModalClassic1(portfolio.address);*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <i className="tim-icons icon-send" />*/}
                                        {/*</Button>*/}
                                        {/*<UncontrolledTooltip*/}
                                        {/*    delay={0}*/}
                                        {/*    target="tooltip707467505"*/}
                                        {/*>*/}
                                        {/*    Receive*/}
                                        {/*</UncontrolledTooltip>*/}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
            }
            {
                props && props.currentView === "exchange" &&
                <CardBody>
                    <Table responsive>
                        <thead className="text-primary" style={{"height": "0px"}}>
                        <tr style={{"height": "0px"}}>
                            <th className="text-center">&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th style={{textAlign: "right", paddingRight: "30px"}}>New</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            xAssetList && xAssetList.length > 0 &&
                            xAssetList.map((xAsset, index) => (
                                <tr key={xAsset.id}>
                                    <td className="text-center">
                                        <div className="photo">
                                            <img alt="..." src={xAsset.logo} />
                                        </div>
                                    </td>
                                    <td>
                                        <div>{xAsset.symbol}</div>
                                        <div style={{color: "grey", fontSize: "12px"}}>{xAsset.name}</div>
                                    </td>
                                    <td>
                                        {
                                            coinPrice && coinPrice.length > 0 &&
                                            <div>
                                                <div className="token-count">
                                                    { Number(xAsset.quantity).toFixed(4) }
                                                </div>
                                                <div className="current-amount" style={{color: "grey", fontSize: "12px"}}>
                                                    {(coinPrice[index] ? coinPrice[index] : coinPrice[0]).price.toFixed(4) } ${config.price_unit}
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td className="text-right">
                                        <Button
                                            className="btn-link btn-icon btn-neutral"
                                            color="success"
                                            id="tooltip618296632"
                                            size="sm"
                                            title="Refresh"
                                            type="button"
                                            onClick={toggleModalClassic2}
                                        >
                                            {/*<i className="tim-icons icon-map-big" onClick={toggleModalClassic} />*/}
                                            <i className="tim-icons icon-map-big"  />
                                        </Button>

                                        <Button
                                            className="btn-link btn-icon btn-neutral"
                                            color="success"
                                            id="tooltip618296632"
                                            size="sm"
                                            title="Refresh"
                                            type="button"
                                            onClick={toggleModalClassic} >
                                            <i className="tim-icons icon-send" />
                                        </Button>

                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip618296632"
                                        >
                                            Send
                                        </UncontrolledTooltip>
                                        <Button
                                            className="btn-link btn-icon btn-neutral"
                                            color="danger"
                                            id="tooltip707467505"
                                            size="sm"
                                            title="Delete"
                                            type="button"
                                        >
                                            <i className="tim-icons icon-send" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip707467505"
                                        >
                                            Receive
                                        </UncontrolledTooltip>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
            }
            <TransactionModal
                modalClassic2={modalClassic2}
                setModalClassic2={setModalClassic2}
                toggleModalClassic2={toggleModalClassic2}
                tx_type={`txTypes=1513&vault=${id}`}
                assetId={assetId}
            />
            <TransferModal
                modalClassic={ modalClassic }
                setModalClassic={ setModalClassic }
                toggleModalClassic={ toggleModalClassic }
                getPortfoliosAndCoinPrice={ getPortfoliosAndCoinPrice }
                refreshVaultList={ getCurrentVaults }
                vaultId={ id }
                assetId={ assetId }
                // vaultName={currVault}
            />
            <ReceiveModal
                modalClassic1={modalClassic1}
                setModalClassic1={setModalClassic1}
                toggleModalClassic1={toggleModalClassic1}
                assetAddress={currentAssetAddress}
            />
        </>
    );
};

export default AssetList;
