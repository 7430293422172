import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Avatar from 'react-avatar';

// nodejs library that concatenates classes
import classNames from "classnames";
import { config } from "config";

import { clearStorage } from "../../variables/utils";

import TopLoader from "../TopLoader/TopLoader";
import CurrentTransactionModal from "../../views/Modals/CurrentTransactionModal/CurrentTransactionModal";
import "./adminnavbar.scss";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip, ModalBody, Col, Row, Card, CardHeader, CardTitle, CardBody,
} from "reactstrap";

import { getCurrentUser } from "../../reducers/currentUser";
import ImageUpload from "../CustomUpload/ImageUpload";
// #TODO
import {setCurrentOrg} from "../../actionCreators/setCurrentOrg";

const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [modalSearch, setModalSearch] = useState(false);
  const [color, setColor] = useState("navbar-transparent");


  // const totalVaultAmount = useSelector(state => state.totalVaultAmount);
  const transactionStates = useSelector(state => state.transactionStates);
  const currentUser = useSelector(state => state.currentUser);
  const currentOrg = useSelector(state => state.currentOrg);

  const [loginRedirect, setLoginRedirectState] = useState(false);

  const [modalClassic_tx, setModalClassic_tx] = useState(true);
  const [modalClassicImage, setModalClassicImage] = useState(false);
  const [modalNewProducts, setModalNewProducts] = useState(false);

  const [upload_type, setUploadType] = useState(null);
  const [image_src, setImgSrc] = useState(null);
  // const [initState, setInitState] = useState(false);

  let timer = null;
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      // console.log("currentUser.member_name", currentUser.member_name);
      dispatch(getCurrentUser());
      if(!localStorage.getItem("org_hash")) {
        getOrganizations().then((res) => {
          console.log("Response", res);
        });
      }
    })();

    window.addEventListener("resize", updateColor);
    return () => {
      // this now gets called when the component unmounts
      window.removeEventListener("resize", updateColor);
      clearTimeout(timer);
    };
  }, [dispatch, timer]);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)

  async function getOrganizations () {
    const response = await fetch(
        `${config.api_url}/${config.v}/organisation-members/${localStorage.getItem("ws_address")}/organisations`
    );

    let organization_list = await response.json();
    if (!response.ok) {
      console.log("ADMIN NAV BAR - Org Error", loginRedirect);
      if (!loginRedirect) {
        console.log("Test How Logout Will Work is the state UPdated", loginRedirect);
        clearStorage();
        setTimeout(() => {
          setLoginRedirectState(true);
        }, 1000);
      }
    } else {
      console.log("Admin Nav Bar Success");
      console.log("organization Data ", organization_list, organization_list.data);

      if(organization_list.data.length > 0) {
        localStorage.setItem("org_hash", organization_list.data[0].organisation_hash);
        localStorage.setItem("org_list", JSON.stringify(organization_list.data));
        // Make ORG Live #TODO
        dispatch(setCurrentOrg(organization_list.data[0]));
      }
      // await getNonce();
      dispatch(getCurrentUser());
    }
  }

  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };

  const toggleModalClassic_tx = () => {
    setModalClassic_tx(!modalClassic_tx);
  }

  const refreshOrgAndProfileData = () => {
    timer = setTimeout(() => {
      console.log("Test user Callback");
      dispatch(getCurrentUser());
      getOrganizations().then((res) => {
        console.log("Test Response", res);
      });
    }, 5000)
  }

  const logOut = () => {
    clearStorage();
    setTimeout(() => {
      setLoginRedirectState(true);
    }, 2000);
  };

  if(loginRedirect) {
    return <Redirect to='/auth/login' />
  }

  const toggleModalClassicImage = () => {
    setModalClassicImage(!modalClassicImage);
  }

  const toggleModalNewProducts = () => {
    setModalNewProducts(!modalNewProducts);
  }

  const getOrgType = () => {
    return (JSON.parse(localStorage.getItem("org_list"))[0].organisation_type === 0) ? "sme" : "bank";
  }

  return (
    <>
      <TopLoader />
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            {/*{*/}
            {/*  transactionStates.message.status*/}
            {/*}*/}

            {
              transactionStates &&
              transactionStates.message &&
              <CurrentTransactionModal
                  modalClassic_tx={ modalClassic_tx }
                  setModalClassic_tx={ setModalClassic_tx }
                  toggleModalClassic_tx={toggleModalClassic_tx}
                  currentTx={ JSON.parse(transactionStates.message) }
              />
            }

            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <div className="nav_org">
              {
                  <img
                      alt=""
                      width="40"
                      src={localStorage.getItem("org_list") &&
                          JSON.parse(localStorage.getItem("org_list"))[0] &&
                          JSON.parse(localStorage.getItem("org_list"))[0].organisation_image &&
                          `https://api.devnet.kapow.tech/v1/files/images/${JSON.parse(localStorage.getItem("org_list"))[0].organisation_image}`}
                      onClick={ () => {
                        if (JSON.parse(localStorage.getItem("org_list"))[0].organisation_image) {
                          setImgSrc(`https://api.devnet.kapow.tech/v1/files/images/${JSON.parse(localStorage.getItem("org_list"))[0].organisation_image}`)
                        }
                        setUploadType("org_logo");
                        toggleModalClassicImage();
                      } }
                      className="organization_logo"
                  />
              }

              {
                  localStorage.getItem("org_list") &&
                  <div className="top-org">
                    <sub className="org_title">ORGANIZATION</sub>
                    <span className={ "org_type " + getOrgType() }>{getOrgType()}</span>
                    <div>
                      <span className="bank_name">{ JSON.parse(localStorage.getItem("org_list"))[0].organisation_name }</span>
                    </div>

                    {/*<span>{JSON.parse(localStorage.getItem("org_list"))[0].organisation_type}</span>*/}
                  </div>
              }
            </div>

            <button className="explore-products" onClick={ toggleModalNewProducts }>Explore New Products</button>

            <Nav className="ml-auto" navbar>
              <InputGroup className="search-bar" tag="li">
                <Button
                  color="link"
                  data-target="#searchModal"
                  data-toggle="modal"
                  id="search-button"
                  onClick={toggleModalSearch}
                >
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup>
              <UncontrolledDropdown nav>
                {/*<DropdownToggle*/}
                {/*  caret*/}
                {/*  color="default"*/}
                {/*  data-toggle="dropdown"*/}
                {/*  nav*/}
                {/*>*/}
                {/*  <div className="notification d-none d-lg-block d-xl-block" />*/}
                {/*  <i className="tim-icons icon-sound-wave" />*/}
                {/*  <p className="d-lg-none">Notifications</p>*/}
                {/*</DropdownToggle>*/}
                {/*<DropdownMenu className="dropdown-navbar" right tag="ul">*/}
                {/*  <NavLink tag="li">*/}
                {/*    <DropdownItem className="nav-item">*/}
                {/*      Mike John responded to your email*/}
                {/*    </DropdownItem>*/}
                {/*  </NavLink>*/}
                {/*  <NavLink tag="li">*/}
                {/*    <DropdownItem className="nav-item">*/}
                {/*      You have 5 more tasks*/}
                {/*    </DropdownItem>*/}
                {/*  </NavLink>*/}
                {/*  <NavLink tag="li">*/}
                {/*    <DropdownItem className="nav-item">*/}
                {/*      Your friend Michael is in town*/}
                {/*    </DropdownItem>*/}
                {/*  </NavLink>*/}
                {/*  <NavLink tag="li">*/}
                {/*    <DropdownItem className="nav-item">*/}
                {/*      Another notification*/}
                {/*    </DropdownItem>*/}
                {/*  </NavLink>*/}
                {/*  <NavLink tag="li">*/}
                {/*    <DropdownItem className="nav-item">*/}
                {/*      Another one*/}
                {/*    </DropdownItem>*/}
                {/*  </NavLink>*/}
                {/*</DropdownMenu>*/}
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  {
                      currentUser &&
                      currentUser.profile_image &&
                      <img
                          alt=""
                          width="40"
                          style={{
                            width: "auto",
                            maxHeight: "40px",
                            maxWidth: "40px",
                            borderRadius: "40px"
                          }}
                          src={`https://api.devnet.kapow.tech/v1/files/images/${currentUser.profile_image}`}
                          onClick={ () => {
                            setImgSrc(`https://api.devnet.kapow.tech/v1/files/images/${currentUser.profile_image}`);
                            setUploadType("profile_pic");
                            // toggleModalClassicImage();
                          } }
                          className="organization_logo"
                      />
                  }
                  {
                      currentUser &&
                      !currentUser.profile_image &&
                      <Avatar
                          name={currentUser.member_name}
                          size="40"
                          onClick={ () => {
                            setUploadType("profile_pic");
                            // toggleModalClassicImage();
                          } } />
                  }

                  {/*<div className="photo">*/}
                  {/*  */}
                  {/*  <img*/}
                  {/*    alt="..."*/}
                  {/*    src={require("assets/img/mike.jpg").default}*/}
                  {/*  />*/}
                  {/*  */}
                  {/*</div>*/}
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <Link to={`/admin/profile/${localStorage.getItem("ws_address")}`}>
                      <DropdownItem className="nav-item">Edit Profile</DropdownItem>
                    </Link>
                  </NavLink>
                  {/*<NavLink tag="li">*/}
                  {/*  <DropdownItem className="nav-item">Settings</DropdownItem>*/}
                  {/*</NavLink>*/}
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={ logOut }>Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>

      {/* Start Image Upload Modal */}
      <Modal
          isOpen={ modalClassicImage }
          toggle={ toggleModalClassicImage }
          className=""
      >
        <div className="modal-header justify-content-left">
          <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModalClassicImage}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>

        </div>
        <ModalBody className="justify-content-left connect-exchange-modal image-upload-modal">
          <div>
            <Col className="mb-4">
              <Row>
                <Col lg="12" md="6" sm="3" style={{padding: "0", maxWidth: "100%"}}>
                  <Card style={{marginTop: "20px", border: "0.5px solid grey", background: "transparent", boxShadow: "none"}}>
                    <CardHeader>
                      <CardTitle tag="h4">
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="text-center">
                      <h4>UPLOAD</h4>
                      <ImageUpload
                          addBtnColor="default"
                          changeBtnColor="default"
                          addBtnClasses="primary"
                          org_hash={ localStorage.getItem("org_hash") }
                          upload_type={ upload_type }
                          member_address={ currentUser.member_address }
                          image_src={ image_src }
                          refreshOrgAndProfileData={ refreshOrgAndProfileData }
                          toggleModalClassicImage={ toggleModalClassicImage }
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            {/*<input type="text" placeholder="Search Exchange Name" style={{width: "calc(100% - 35px)", marginBottom: "10px"}} />*/}

          </div>
        </ModalBody>

      </Modal>
      {/* End Image Upload Modal */}


      {/* Start Explore New Products Modal */}
      <Modal
          isOpen={ modalNewProducts }
          toggle={ toggleModalNewProducts }
          modalClassName="modal-black"
          className="pride-modal"
      >
        <div className="modal-header">
          <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={ toggleModalNewProducts }
          >
            <i className="tim-icons icon-simple-remove" />
          </button>

        </div>
        <ModalBody>
          <div className="explore-products-content">
            <div className="products-section">
              <h4>Tokenized Lending Pools</h4>
              <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1672742628/web_application/lending_init.png" alt="" height="100"/>
              <p>Create customised Digital Financial products for your clients with a few clicks using our intuitive UI.</p>
              <Button className="btn-simple" color="info">
                Book a Demo
              </Button>
            </div>
            <div className="vertical-separator-gradient">&nbsp;</div>
            <div className="products-section">
              <h4>Deep-Tier Invoice Discounting Solution</h4>
              <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1691921750/web_application/Untitled_design__7_-removebg-preview-fotor-20230813154513_aelbrw.png" alt="" height="100"/>
              <p>Transformative Business Solution that leverages supply chain relationships to address the challenges in Deep-Tier Financing.</p>
              <Button className="btn-simple" color="info">
                Book a Demo
              </Button>
            </div>

          </div>
        </ModalBody>

      </Modal>
      {/* End Explore New Products Modal */}
    </>
  );
};

export default AdminNavbar;
