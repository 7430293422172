import React from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";

// reactstrap components
import { Col } from "reactstrap";

// wizard steps
import Step1 from "./Steps/Step1.js";
import Step2 from "./Steps/Step2.js";
// import Step3 from "./Steps/Step3.js";
import RegisterOrganization from "./../../views/pages/RegisterOrganization.js";

var steps = [
    {
        stepName: "Register Organization",
        stepIcon: "tim-icons icon-single-02",
        component: RegisterOrganization,
    },
    {
        stepName: "User Profile",
        stepIcon: "tim-icons icon-settings-gear-63",
        component: Step2,
    },
    // {
    //     stepName: "Address",
    //     stepIcon: "tim-icons icon-delivery-fast",
    //     component: Step3,
    // },
];

const Onboarding = () => {
    return (
        <>
            <div className="content">
                <Col className="mr-auto ml-auto" md="10">
                    <ReactWizard
                        steps={steps}
                        navSteps
                        validate
                        title="Build Your Profile"
                        description="This information will let us know more about you."
                        headerTextCenter
                        finishButtonClasses="btn-wd btn-info"
                        nextButtonClasses="btn-wd btn-info"
                        previousButtonClasses="btn-wd"
                        progressbar
                        color="blue"
                    />
                </Col>
            </div>
        </>
    );
};

export default Onboarding;
