import { Base64 } from "../../base64";

export class CreateRuleData {
  name: string;
  txType : number;
  approvers: string[];
  rejectors: string[];
  thresholdApprovals: number;
  thresholdRejections: number;
  min: number;
  max: number;
  currency: string;
 
  constructor(name: string, txType : number, approvers: string[], rejectors: string[], thresholdApprovals: number, thresholdRejections: number, min: number, max: number, currency: string) {
    this.name = name;
    this.txType = txType;
    this.approvers = approvers;
    this.rejectors = rejectors;
    this.thresholdApprovals = thresholdApprovals;
    this.thresholdRejections = thresholdRejections;
    this.min = min;
    this.max = max;
    this.currency = currency;
  }

  public static fromJson(json: any): CreateRuleData {
    return new CreateRuleData(
      json["name"],
      json["tx_type"],
      json["approvers"],
      json["rejectors"],
      json["threshold_approvals"],
      json["threshold_rejections"],
      json["min"],
      json["max"],
      json["currency"]
      );
  }

  public toJson(): string {
    return JSON.stringify({
      "name": this.name,
      "tx_type": this.txType,
      "approvers": this.approvers,
      "rejectors": this.rejectors,
      "threshold_approvals": this.thresholdApprovals,
      "threshold_rejections": this.thresholdRejections, 
      "min": this.min,
      "max": this.max,
      "currency": this.currency
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
