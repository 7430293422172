// Language: typescript
// Path: src/address/index.ts
import {Base58, Hash} from '..';

// version 0x01
const version = Buffer.from([0x01]);

export class Address {
  public static fromPublicKey(publicKey: Uint8Array): string {
    const address = Address.generateChecksumAddress(publicKey);
    return Base58.encode(address);
  }
  //   generate checksum address
  private static generateChecksumAddress(publicKey: Uint8Array): Buffer {
    const addressHash = Hash.keccak256(Buffer.from(publicKey));
    const checksum = Address.generateChecksumBytes(addressHash);
    return Buffer.concat([version, checksum, addressHash]);
  }
  private static generateChecksumBytes(addressHash: Buffer): Buffer {
    const hash = Hash.keccak256(addressHash);
    return hash.slice(0, 3);
  }
}

// how address is generated
// 1. generate Keccak256 hash of public key (32 bytes)
// 2. generate Keccak256 hash of the hash generated in step 1 (32 bytes)
// 3. generate checksum by taking first 3 bytes of the hash generated
//  in step 2 (3 bytes)
// 4. concatenate the version, checksum and the hash generated in step 1
//  (36 bytes)
// 5. encode the result in Base58 encoding
// 6. the result is the address

