import React, { useEffect, useState, useRef } from "react";
import { config } from "config";
import { Quantity } from "kapow-sdk";
import Identicon from "react-identicons";

import {
    Modal, ModalBody,
} from "reactstrap";

import { tx_status } from "../../../constants";
import { clip_address } from "../../../variables/utils";
// import "./transactionmodal.scss";

const CurrentTransactionModal = (props) => {

    const lastVal = useRef();
    const { modalClassic_tx, setModalClassic_tx, currentTx } = props;
    console.log("currentTx", currentTx);

    if(lastVal.current === currentTx.status) {
        lastVal.current = currentTx.status;
        getTransactions(currentTx.hash);
    }


    console.log("currentTx", currentTx);

    const [assetList, setAssetList] = useState(null);
    const [transactions, setTransaction] = useState(null);

    useEffect((e) => {
        (async () => {
            await getAssetList();
            console.log("props.currentTx", props.currentTx, lastVal.current);
            lastVal.current = props.currentTx.hash;
            console.log("Get Transactions inside");
            await getTransactions(props.currentTx.hash);
        })();

    }, []);
    const previousHash = lastVal.current;

    async function getTransactions (tx_hash) {

        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}/${tx_hash}`
        );

        let transactionList = await response.json();
        console.log("Transaction response ", transactionList);
        setTransaction(transactionList);
    }

    async function getAssetList () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`
        );

        let assetList = await response.json();
        console.log("asset list shubham ew added data", assetList);
        setAssetList(assetList);
    }

    const toggleModalClassic_tx = () => {
        setModalClassic_tx(!modalClassic_tx);
    };

    return (
        <>
            <Modal isOpen={modalClassic_tx} toggle={toggleModalClassic_tx} className="action-model">
                <div className="modal-header justify-content-left">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalClassic_tx}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Transaction Notification</h6>
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    {
                        transactions &&
                        <div className="transaction-card">
                            <div>
                                <b className="title-wid-150">Status</b>
                                <span className="value-wid">{tx_status[transactions.status]}</span>
                            </div>
                            <div>
                                <b className="title-wid-150">From</b>
                                <span className="long-hash value-wid">
                                    {clip_address(transactions.data.from)}
                                    </span>
                                <span style={{width: "40px", display: "inline-block", textAlign: "center"}}>-></span>
                                {/*<b className="title-wid-150">To</b>*/}
                                <span className="long-hash value-wid">
                                    {clip_address(transactions.data.to)}
                                </span>
                            </div>
                            <div>
                                <b className="title-wid-150" style={{ marginTop: "20px"}}>Transaction</b>
                                <div className="vertical-details">
                                    <span className="title-wid-150">Amount</span>

                                    <span className="value-wid">
                                        {
                                            Number(Quantity.hexToDecimal(assetList.find(item => item.id === transactions.data.asset_id), (transactions.data.quantity) ? transactions.data.quantity : 0))
                                        }
                                        </span><span className="value-wid">
                                            {
                                                assetList.find(item => item.id === transactions.data.asset_id).symbol
                                            }
                                        </span>

                                </div>
                                <div className="vertical-details">
                                    <span className="title-wid-150">Date/Time</span>
                                    <span className="date-time-class">{new Date(transactions.time_stamp*1000).toString().toString()}</span>
                                </div>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </>
    );
};

export default CurrentTransactionModal;
