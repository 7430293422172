export class Utils {
  public static toArray(buffer: Buffer): Uint8Array {
      return new Uint8Array(buffer);
    // return buffer.buffer.slice(
    //   buffer.byteOffset,
    //   buffer.byteOffset + buffer.byteLength
    // );
  }

  public static toBuffer(byteArray: Uint8Array) {
    return Buffer.from(byteArray);
  }

  public static toHex(byteArray: Uint8Array): string {
    return Buffer.from(byteArray).toString('hex');
  }

  public static fromHex(hex: string): Buffer {
    return Buffer.from(hex, 'hex');
  }

  public static fromUtf8(str: string): Buffer {
    return Buffer.from(str, "utf-8");
  }

  public static zeroBuffer(length: number) {
    return Buffer.alloc(length, 0);
  }
}
