import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { CreateRuleData } from "./data/data_rule_create";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class CreateRuleTransferTransaction extends Transaction {

  // type: number = TransactionTypeEnum.TX_TYPE_MUA_CREATE_RULE;
  type: number = 1520

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, ruleName: string, approvers : string[], rejectors : string[], thresoldApprovals : number, thresoldRejections: number, min: number, max: number, valueType: string, txType: number) {
    super();
    // this.nonce = nonce;
    // this.address = address;
    // this.publicKey = publicKey;
    console.log("insie YS", nonce, sender, publicKey, ruleName, approvers, rejectors, thresoldApprovals, thresoldRejections, min, max, valueType)
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    // var ruleData = new CreateRuleTransferData(assetID, min, max, valueType).toBase64();
    var data = new CreateRuleData(ruleName,txType, approvers, rejectors, thresoldApprovals, thresoldRejections, min, max, valueType).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();
  }
}
