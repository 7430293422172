// The Keccak hash function
import {Keccak, SHA3} from 'sha3';
var RIPEMD160 = require('ripemd160')


export class Hash {
  // Keccak-256 hash
  // @param data - The data to hash
  // @returns The hash of the data
  public static keccak256(data: Buffer): Buffer {
    const hash = new Keccak(256);
    // Update the hash with the data
    hash.update(data);
    // Return the hash
    return hash.digest();
  }
  public static keccak256String(data: string): Buffer {
    return Hash.keccak256(Buffer.from(data));
  }

  public static hexkeccak256String(data: string): string {
    return Hash.keccak256(Buffer.from(data)).toString('hex');
  }

  public static hexKeccak256(data: Buffer): string {
    return Hash.keccak256(data).toString('hex');
  }

  public static base64Keccak256(data: Buffer): string {
    return Hash.keccak256(data).toString('base64');
  }
  public static base58Keccak256(data: Buffer): string {
    return Hash.keccak256(data).toString('base58');
  }

  public static sha256String(data: string): Buffer {
    return Hash.sha256(Buffer.from(data));
  }

  public static sha256(data: Buffer): Buffer {
    const hash = new SHA3(256);
    // Update the hash with the data
    hash.update(data);
    // Return the hash
    return hash.digest();
  }

  public static hexSha256(data: Buffer): string {
    return Hash.sha256(data).toString('hex');
  }

  public static hash160(data: Buffer): Buffer {
    var digest = new RIPEMD160()
    digest.update(data)
    return digest.digest()
  }
}
