import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { AddOrganisationMemberData } from "./data/data_organisation_member_add";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class AddOrganisationMember extends Transaction {
  
  // type: number = TransactionTypeEnum.TX_TYPE_ORGANISATION_ADD_MEMBER;
  type: number = 1503;

  // data variables
  inviteHash: string;
  memberName: string;
  memberEmail: string;
  memberType: number;
  permissions: string;
  vaults: string;
  lendingPools: string;

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, memberName: string, memberEmail: string, memberType: number, inviteHash: string, permissions: string, vaults: string, lendingPools: string) {
    super();
    this.memberName = memberName;
    this.memberEmail = memberEmail;
    this.memberType = memberType;
    this.inviteHash = inviteHash;
    this.permissions = permissions;
    this.vaults = vaults;
    this.lendingPools = lendingPools;
  
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new AddOrganisationMemberData(this.memberName, this.memberEmail, this.memberType, this.inviteHash, this.permissions, this.vaults, this.lendingPools).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();
  }
}
