import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useWebSocket from "react-use-websocket";
import { CreateApproveTransaction } from "kapow-sdk";

import { config } from "../../config";

import TransactionData from "./TransactionData";
import "./transactiondata.scss";
import { getNonce } from "../../reducers/currentNonce";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";

const TransactionList = () => {

  // const socketUrl = 'ws://128.199.101.24:8000/ws';
  const socketUrl = 'wss://trex.devnet.kapow.tech/ws';
  // const socketUrl = 'wss://65b3-103-87-59-122.ngrok.io/ws';
  const nonce = useSelector(state => state.currentNonce);
  const dispatch = useDispatch();

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log('opened');
      sendWalletAddress();
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  const [transactions, setTransactionList] = useState([]);
  const [messageHistory, setMessageHistory] = useState([]);
  const [logState, setLogState] = useState(false);
  const [modalClassic_tx, setModalClassic_tx] = useState(true);
  const [socketMessage, setSocketMessage] = useState(false);

  useEffect(() => {
    (async () => {
      console.log("Inside super trex list");
      await requestTransactionList("");
    })();

    if (lastMessage !== null) {
      console.log("Last message Inside", lastMessage);
      setMessageHistory((prev) => prev.concat(lastMessage));
      if(lastMessage.data && lastMessage.data !== "") {
        console.log("Inside TXXXXX");
      }
    }
    // const intervalCall = setInterval(() => {
    //   // setPage(page + 1);
    //   requestTransactionList();
    // }, 10000);
    // return () => {
    //   clearInterval(intervalCall);
    // };
  }, []);

  async function requestTransactionList (query_params) {
    const response = await fetch(
      `${config.api_url}/${config.v}/${config.endpoints.transactions}?limit=100&page=0&order=desc&txTypes=1513,1035,1533&orgHash=${localStorage.getItem("org_hash")}&${query_params}`
    );

    const json = await response.json();
    console.log("Transaction Data", json);
    setTransactionList(json);
  }

  const manageAuditSectionState = () => {
    requestTransactionList("");
    setLogState(!logState);
  }

  const sendWalletAddress = () => {
    sendJsonMessage({
          address: localStorage.getItem("ws_address")
        }
    );
  }

  const AcceptTx = () => {

    const tx = new CreateApproveTransaction(
        nonce,
        localStorage.getItem("ws_address"),
        Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
        localStorage.getItem("org_hash"),
        "",
        "",
        "0xa1eda6913be9fb55fbd19f44cfb3a2a42a8e2f3ed063347849f38be2d7c2e656"
    );
    tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
    const signedTx = tx.toBase64();
    sendTx(signedTx).then((res) => {
      console.log("response", res);
    });
    // sendJsonMessage({
    //       address: localStorage.getItem("ws_address")
    //     }
    // );
  }

  const RejectTx = () => {

    const tx = new CreateApproveTransaction(
        nonce,
        localStorage.getItem("ws_address"),
        Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
        localStorage.getItem("org_hash"),
        "",
        "",
        "tx_hash"
    );


    // sendJsonMessage({
    //       address: localStorage.getItem("ws_address")
    //     }
    // );
  }

  async function  sendTx (signedTx) {
    const settings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        "tx": signedTx
      })
    }
    const response = await fetch(
        `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
    );

    if (!response.ok) {
      console.log("YO YO MA NOT OKAY!");
      // notify("tr");
      return;
    } else {
      console.log("get Nonce area");
      dispatch(getNonce());
      // openPop();
    }
    const json = await response.json();
    console.log("successful transaction", json);
    // setTxResponse(json);
  }

  const toggleModalClassic_tx = () => {
    setModalClassic_tx(!modalClassic_tx);
  }

  return (
      <div className="audit-log-shadow">
        {/*<CurrentTransactionModal*/}
        {/*    modalClassic_tx={ modalClassic_tx }*/}
        {/*    setModalClassic_tx={ setModalClassic_tx }*/}
        {/*    toggleModalClassic_tx={ toggleModalClassic_tx }*/}
        {/*    currentTx={ JSON.parse(transactionStates.message) }*/}
        {/*/>*/}
        <div className="live-logs-opener" onClick={ manageAuditSectionState }>Active Transfers</div>
        <div className={ logState ? "tx-live-logs open" : "tx-live-logs"}>
          <div className="mask" onClick={manageAuditSectionState}>&nbsp;</div>
          <div>
            <h3>
              Active Transfers
              <span onClick={manageAuditSectionState}>×</span>
              {/*<button onClick={ sendWalletAddress }>*/}
              {/*  Send Message*/}
              {/*</button>*/}
              {/*<button onClick={ AcceptTx }>*/}
              {/*  Approve*/}
              {/*</button>*/}
            </h3>
            <div className="active-tx-btn-group">
              <Button
                  className="btn-simple all"
                  color="info"
                  onClick={() => {
                    requestTransactionList("");
                  }}
              >
                All
              </Button>
              <Button
                  className="btn-simple confirmed"
                  color="info"
                  onClick={() => {
                    requestTransactionList("statusCode=600");
                  }}
              >
                Confirmed
              </Button>
              <Button
                  className="btn-simple pending"
                  color="info"
                  onClick={() => {
                    requestTransactionList("statusCode=599");
                  }}>
                Pending
              </Button>
              <Button
                  className="btn-simple rejected"
                  color="info"
                  onClick={() => {
                    requestTransactionList("statusCode=612");
                  }}
              >
                Rejected
              </Button>
              {/*<Button className="btn-simple actions" color="info">*/}
              {/*  Actions*/}
              {/*</Button>*/}
            </div>
          </div>
          <div>
            {
                transactions && transactions.data && transactions.data.length !==0 &&
                transactions.data.map((transaction, index) =>(
                        <TransactionData
                            key={index}
                            index={index}
                            keyHash={transaction.hash}
                            blockNumber={transaction.block}
                            txHash={transaction.tx_hash}
                            sender={transaction.sender}
                            status={transaction.status}
                            type={transaction.tx_type}
                            timeStamp={transaction.time_stamp}
                            data={transaction.data}
                            // blockHash={transaction.block_hash}
                            // time={transaction.block_time}
                            // height={transaction.block_height}
                        />
                    )
                )
            }
            {
                transactions && (!transactions.data || transactions.data.length === 0) &&
                <div className="first-view">
                  <div className="step-1">
                    <div className="team-image">
                      <div>
                        <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1672930650/web_application/tl_qr60m5.webp" alt="" style={{width: "200px"}} />
                      </div>
                    </div>
                    <div className="step-1__text">No Active Transfers</div>
                  </div>
                </div>
            }

          </div>
        </div>
      </div>

  )
}

export default TransactionList;
