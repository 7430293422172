export function sessionPubKey(state= "", action) {
    // console.log("Inside reducer", action.type, action.payload)
    switch(action.type){
        case 'SET_SESSION_PUB_KEY':
            return action.payload;
        default:
            return state;
    }
}

export function wsAddress(state= "", action) {
    switch(action.type){
        case 'SET_WS_ADDRESS':
            return action.payload;
        default:
            return state;
    }
}