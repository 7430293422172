import { Base64 } from "../../base64";

export class CreateInvoiceData {
  invoiceNumber: string;
  invoiceDate: number;
  irn: string;
  sellerGST: string;
  buyerGST: string;
  totalInvoiceAmount: number;
  currency: string;
  dueDate: number;


  constructor(invoiceNumber: string, invoiceDate: number, irn: string, sellerGST: string, buyerGST: string, totalInvoiceAmount: number, currency: string, dueDate: number) {
    this.invoiceNumber = invoiceNumber;
    this.invoiceDate = invoiceDate;
    this.irn = irn;
    this.buyerGST = buyerGST;
    this.sellerGST = sellerGST;
    this.totalInvoiceAmount = totalInvoiceAmount;
    this.currency = currency;
    this.dueDate = dueDate;
  }

  public static fromJson(json: any): CreateInvoiceData {
    return new CreateInvoiceData(
      json["invoice_number"],
      json["invoice_date"],
      json["irn"],
      json["buyer_gst"],
      json["seller_gst"],
      json["total_invoice_amount"],
      json["currency"],
      json["due_date"],
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "invoice_number": this.invoiceNumber,
      "invoice_date": this.invoiceDate,
      "irn": this.irn,
      "buyer_gst": this.buyerGST,
      "seller_gst": this.sellerGST,
      "total_invoice_amount": this.totalInvoiceAmount,
      "currency": this.currency,
      "due_date": this.dueDate,
    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
