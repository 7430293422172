import { Base58 } from "../../base58";
import { Hash } from "../../hash";
import { Utils } from "../../utils";

let version = Uint8Array.from([0x01]);

export class Address {
  /// Generate kapow address from a public key.

  static fromPublicKey(publicKey:Uint8Array):string {
    var address = Address._generateChecksumAddress(publicKey);
    return Base58.encode(address);
  }

  //   generate checksum address
  static _generateChecksumAddress(publicKey: Uint8Array): Uint8Array  {
    var addressHash = Hash.keccak256(Utils.toBuffer(publicKey));
    var checksum = Address._generateChecksumBytes(addressHash);
    return Uint8Array.from([...version, ...checksum, ...addressHash]);
  }

  /// generate checksum bytes
  static _generateChecksumBytes(addressHash: Uint8Array): Uint8Array {
    var hash = Hash.keccak256(Utils.toBuffer(addressHash));
    return hash.slice(0, 3);
  }
}

// how address is generated
// 1. generate Keccak256 hash of public key (32 bytes)
// 2. generate Keccak256 hash of the hash generated in step 1 (32 bytes)
// 3. generate checksum by taking first 3 bytes of the hash generated
//  in step 2 (3 bytes)
// 4. concatenate the version, checksum and the hash generated in step 1
//  (36 bytes)
// 5. encode the result in Base58 encoding
// 6. the result is the address