// AvatarList Pure Functional Component
import React from "react";
import Avatar from "react-avatar";
import {Button, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";

const AvatarList = (props) => {
    return (
        <div className="avatar-list">
            {
                props.members.map(member => <div
                    key={member.member_address}
                    id={`tooltip${member.member_address}`}
                >
                    <Avatar name={ member.member_name }  size={props.size ? props.size: 35} src={`https://api.devnet.kapow.tech/v1/files/images/${member.profile_image}`} />
                    <UncontrolledTooltip
                        delay={0}
                        target={`tooltip${member.member_address}`}
                    >
                        { member.member_name === "" ? "Owner" : member.member_name }
                    </UncontrolledTooltip>
                    {
                        props.party === "approval" &&
                        props.show_state &&
                        props.state_check && props.state_check.length > 0  &&
                        (props.state_check.indexOf(member.member_address) > -1) &&
                        <i className="tim-icons icon-check-2 text-primary" />
                    }
                    {
                        props.party === "rejection" &&
                        props.show_state &&
                        props.state_check && props.state_check.length > 0  &&
                        (props.state_check.indexOf(member.member_address) > -1) &&
                        <i className="tim-icons icon-simple-remove text-primary" />
                    }
                </div>)
            }
            {
                props.add_member &&
                <Link to={`/admin/console/new`} id="tooltip-add-new-member">
                    <Button className="btn-simple add_member" color="info">
                        +
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        target={`tooltip-add-new-member`}
                    >
                        Add New Member
                    </UncontrolledTooltip>
                </Link>
            }

        </div>
    )
}

export default AvatarList;