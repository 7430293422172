import React, { useState, useEffect } from "react";
import classnames from "classnames";
import QRCode from "react-qr-code";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import {sessionPubKey, wsAddress} from "./../../reducers/index";
import { setTotalVaultAmount, setTotalXchangeAmount } from "./../../actionCreators/setSessions";
import { config } from "../../config";

import {KeyPair, Base58, Address, Mnemonic, CreateGroupTransaction, AddMemberTransaction, MultiAccountTransferTransaction, Conversion, Hash, CreateMultiUserTransaction } from "kapow-sdk";
import cryptoRandomString from 'randomstring';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { DotPulse } from '@uiball/loaders'
import "./login.scss";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

const Login = () => {

  // console.log("Inside Login Page");
  const [state, setState] = useState({});
  const [session, setSession] = useState(
    ""
  );

  const sessionPubKey = useSelector(state => state.sessionPubKey);
  const wsAddress = useSelector(state => state.wsAddress);

  const [messageHistory, setMessageHistory] = useState([]);
  const [browser, setBrowser] = useState("");
  const [organization, setOrganization] = useState(null);
  const [loginProgress, setLoginProgress] = useState(false);
  const socketUrl = 'wss://weblogin.devnet.kapow.tech/ws';
  // const socketUrl = 'wss://157.245.154.224:6500/ws';
  // const socketUrl = 'wss://c2f0-2401-4900-1f39-6b87-2d78-3f68-7729-ee47.in.ngrok.io';
  // const socketUrl = 'ws://0.tcp.in.ngrok.io:15848';

  const {
      sendMessage,
      sendJsonMessage,
      lastMessage,
      lastJsonMessage,
      readyState,
      getWebSocket,
    } = useWebSocket(socketUrl, {
      onOpen: () => {
        console.log('opened');

        // send qr code on reconnection.
        if(localStorage.getItem("buffer_hex") && localStorage.getItem("browser_string")) {
          sendJsonMessage({
            qr: localStorage.getItem("buffer_hex"),
            browser: localStorage.getItem("browser_string")
          })
        }

      },
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: (closeEvent) => true,
    });

  const generateSessionOnce = () => {

    if(!session) {
      // clear local storage before setting new session
      localStorage.clear();
      const key_pair = KeyPair.generateKeyPair();
      const newSession = Conversion.bytesToHex(key_pair.publicKey);
      const newHash = Hash.hash160(new Buffer(key_pair.publicKey));
      const bufferHex = [...new Uint8Array(newHash)].map(x => x.toString(16).padStart(2, '0')).join('');

      const session_pub_key_string = Buffer.from(key_pair.publicKey).toString('hex');

      const session_secret_key_string = Buffer.from(key_pair.secretKey).toString('hex');
      if(localStorage.getItem("session_pub_key") === null) {
        localStorage.setItem("session_pub_key", session_pub_key_string);
      }
      if(localStorage.getItem("session_secret_key") === null) {
        localStorage.setItem("session_secret_key", session_secret_key_string);
      }

      // TODO - Remove Session Address ?
      const suraj = Address.fromPublicKey(key_pair.publicKey);

      // console.log("sessionAddress", sessionAddress);
      localStorage.setItem("session_address", suraj);

      localStorage.setItem("buffer_hex", bufferHex);
      const browser = detectBrowser();
      localStorage.setItem("browser_string", browser);

      sendJsonMessage({
            qr: bufferHex,
            browser
          }
      );
      setSession(newSession);
    } else {
      return session;
    }
  };

  async function getOrganizationsByMember (ws_address) {
    const response = await fetch(
        `${config.api_url}/${config.v}/organisation-members/${ws_address}/organisations`
    );

    let organization_list = await response.json();
    console.log("portfolio Data ", organization_list.data);
    setOrganization(organization_list.data)
  }

  useEffect(() => {

    generateSessionOnce();

    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
      // setMessageHistory((prev) => prev.concat(lastMessage));
      // sessionStorage.setItem('session', JSON.stringify(lastMessage.data));
      if(lastMessage.data && lastMessage.data !== "Login In Progress" && lastMessage.data !== "") {
        localStorage.setItem("ws_address", lastMessage.data);
        getOrganizationsByMember(lastMessage.data).then((res)=>{
          console.log("res", res);
          localStorage.setItem("random-string", browser);
        });
      } else if (lastMessage.data === "Login In Progress") {
        setLoginProgress(true);
      }
    }

    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, [lastMessage, setMessageHistory]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const detectBrowser = () => {
    let userAgent = navigator.userAgent;
    let browserName;
    if(localStorage.getItem("random-string")) {
      browserName = localStorage.getItem("random-string");
    }else if(userAgent.match(/chrome|chromium|crios/i)){
      browserName = "chromeX" + cryptoRandomString.generate();
    }else if(userAgent.match(/firefox|fxios/i)){
      browserName = "firefox" + cryptoRandomString.generate();
    }  else if(userAgent.match(/safari/i)){
      browserName = "safari" + cryptoRandomString.generate();
    }else if(userAgent.match(/opr\//i)){
      browserName = "opera" + cryptoRandomString.generate();
    } else if(userAgent.match(/edg/i)){
      browserName = "edge" + cryptoRandomString.generate();
    }else{
      browserName="No browser detection";
    }

    setBrowser(browserName);
    console.log(`You are using ${browserName} browser`);
    return browserName;

    // document.querySelector("h1").innerText="You are using "+ browserName +" browser";
  }


  if(organization != null && organization.length === 0) {
    return <Redirect to='/auth/register' />
  } else if (organization != null && organization.length > 0) {
    return <Redirect to='/admin/vaults' />
  }
  return (
    <>
      <div className="content login-view">
        {/*<button onClick={() => {*/}
        {/*  setLoginProgress(true);*/}
        {/*}}>Test</button>*/}
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>
                  <img
                    alt="..."
                    src={require("assets/img/card-primary.png").default}
                  />
                  <CardTitle tag="h1">Log in</CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                  <div style={{height: "256px"}}>
                    {
                      session &&
                      <QRCode value={session} />
                    }  
                  </div>
                </CardBody>
                <CardFooter>

                  <Button
                    block
                    className="mb-3 scan-login"
                    color="primary"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    {
                      loginProgress &&
                        <div className="login_progress">
                          <div>Logging In</div>
                          <DotPulse color="#ffffff" className="anim_loader" />
                        </div>
                    }
                    {
                      !loginProgress &&
                        "Scan with your Xaults App"
                    }
                  </Button>
                  <div className="pull-left">
                    <h6>
                      <a
                        className="link footer-link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Create Account
                      </a>
                    </h6>
                  </div>
                  <div className="pull-right">
                    <h6>
                      <a
                        className="link footer-link"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Need Help?
                      </a>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Login;
