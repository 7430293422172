// import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { config } from "../config";

export function currentUser (state= {}, action) {
    switch(action.type){
        case 'SET_CURRENT_USER':
            return action.payload;
        default:
            return state;
    }
}

//asynchronous action
export const getCurrentUser = () => async dispatch => {
    let res = {data:[]};
    try {
        res = await axios.get(`${config.api_url}/${config.v}/organisations/${localStorage.getItem("org_hash")}/${config.endpoints.members}`);
        console.log("res Current Member List", res.data.data.find((member) => member.member_address === localStorage.getItem("ws_address")));

        const current_user_data = res.data.data.find((member) => member.member_address === localStorage.getItem("ws_address"));
        await dispatch({
            type: 'SET_CURRENT_USER',
            payload: current_user_data
        })
    } catch (error) {
        //res.data = error;
        console.log(error);
    }
}