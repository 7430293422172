import React, {useEffect, useState} from "react";
import SingleApp from "./SingleApp";
import "./singleapp.scss";

const Apps = (props) => {

    const [binanceStatus, setBinanceStatus] = useState(null);
    const [ftxStatus, setFtxStatus] = useState(null);
    const [coinbaseStatus, setCoinbaseStatus] = useState(null);
    const [krakenStatus, setKrakenStatus] = useState(null);

    useEffect(() => {
        getsAndSetsExchangeStatuses();
    }, []);

    async function getsAndSetsExchangeStatuses () {
        const [binance_status, ftx_status] = await Promise.all([
            fetch(`http://127.0.0.1:8000/api/v1/binance/status`),
            fetch(`http://127.0.0.1:8000/api/v1/ftx`)
        ]);

        const binanceStatus = await binance_status.json();
        console.log("binanceStatus", binanceStatus.info.canDeposit);
        if(binanceStatus.info) {
            setBinanceStatus(binanceStatus.info.canDeposit && binanceStatus.info.canTrade && binanceStatus.info.canWithdraw);
        }

        const ftxStatus = await ftx_status.json();
        console.log("ftxStatus", ftxStatus);
        if (ftxStatus && ftxStatus.info && ftxStatus.info.success) {
            setFtxStatus(ftxStatus.info.success)
        }
    }

    return (
        <>
            <div className="content apps app-box">
                {/*<SingleApp status={binanceStatus} app_name="Binance" />*/}
                {/*<SingleApp status={ftxStatus} app_name="FTX" />*/}
                {/*<SingleApp status={coinbaseStatus} app_name="Coinbase" />*/}
                {/*<SingleApp status={krakenStatus} app_name="Kraken" />*/}
                <SingleApp
                    status={binanceStatus}
                    app_name="State Bank of India"
                    logo_url="https://res.cloudinary.com/kapow-tech/image/upload/v1684987690/Indian%20Bank%20Logos/SBI-Logo-768x432_jtov0d.png" />
                <SingleApp
                    status={ftxStatus}
                    app_name="HDFC"
                    logo_url="https://res.cloudinary.com/kapow-tech/image/upload/v1684988060/Indian%20Bank%20Logos/HDFC-Bank-emblem_fqdmpe.png" />
                <SingleApp
                    status={coinbaseStatus}
                    app_name="Axis Bank"
                    logo_url="https://res.cloudinary.com/kapow-tech/image/upload/v1684987976/Indian%20Bank%20Logos/Axis_Bank_logo.svg_uxzuke.png" />
                <SingleApp
                    status={krakenStatus}
                    app_name="ICICI Bank"
                    logo_url="https://res.cloudinary.com/kapow-tech/image/upload/v1684987875/Indian%20Bank%20Logos/608px-ICICI_Bank_Logo.svg_izg1yg.png" />
            </div>
        </>
    );
};

export default Apps;
