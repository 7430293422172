import React, { useState, useEffect } from "react";
// import { Base64 } from "../.kapow-sdk";
import { useSelector, useDispatch } from "react-redux";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Modal, ModalBody, Alert,
} from "reactstrap";

import "./singlepools.scss";
import { config } from "../../../config";
import { gasValues } from "../../../constants";
import {LendingPoolDispenseTransaction, LendingPoolSupplyTransaction, Quantity} from "kapow-sdk";
import Swal from "sweetalert2";
import { getNonce } from "../../../reducers/currentNonce";


const SinglePool = (props) => {
    const [gasData, setGasData] = useState(null);
    const [coreAssets, setCoreAssets] = useState([]);
    const [supplyWithdrawModal, setSupplyWithdrawModal] = useState(false);
    const [assetQuantity, setAssetQuantity] = useState(0);

    const [gasETHData, setETHGasData] = useState(null);
    const [gasBTCData, setBTCGasData] = useState(null);
    const [gasKPWData, setKPWGasData] = useState(null);
    const nonce = useSelector(state => state.currentNonce);

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            // await getLocalAssetAddress();
            await getCoreAssets();
            dispatch(getNonce());

            await getETHGasData();
            await getBTCGasData();
            await getKPWGasData();
        })();

    }, []);

    async function getETHGasData () {
        const response = await fetch(
            `${config.eth_gas_api}`
        );

        const gas_data = await response.json();
        console.log("ETH gas_data", gas_data.data);
        setETHGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getBTCGasData () {
        const response = await fetch(
            `${config.btc_gas_api}`
        );

        const gas_data = await response.json();
        console.log("BTC gas_data", gas_data.data);
        setBTCGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getKPWGasData () {
        const response = await fetch(
            `${config.kapow_gas_api}`
        );

        const gas_data = await response.json();
        console.log("KPW gas_data", gas_data.data);
        setKPWGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getCoreAssets () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.assets}?limit=100`
        );

        const json = await response.json();
        console.log("Core Asset Data", json.data);
        setCoreAssets(json.data);
    }

    // dedicated to the goddess Laxmi.
    function lendingPoolSupply (lendingPoolAddress, vaultAddress) {
        console.log("Pool Supply Deposit Transaction", nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            localStorage.getItem("org_hash"),
            vaultAddress,
            lendingPoolAddress,
            lendingPoolAddress,
            coreAssets.find(item => item.id === props.pool.borrow_asset_id),
            "0.001",
            (props.pool.borrow_asset_id.id === 1) ? gasKPWData.price : (props.pool.borrow_asset_id === 2 ? gasETHData.price : (props.pool.borrow_asset_id === 3 ? gasBTCData.price : gasETHData.price)),
            parseInt((props.pool.borrow_asset_id === 1) ? gasKPWData.limit : (props.pool.borrow_asset_id === 2 ? gasETHData.limit : (props.pool.borrow_asset_id === 3 ? gasBTCData.limit : gasETHData.limit))),
        );

        const tx = new LendingPoolSupplyTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            localStorage.getItem("org_hash"),
            vaultAddress,
            lendingPoolAddress,
            lendingPoolAddress,
            coreAssets.find(item => item.id === props.pool.borrow_asset_id),
            assetQuantity.toString(),
            (props.pool.borrow_asset_id === 1) ? gasKPWData.price : (props.pool.borrow_asset_id === 2 ? gasETHData.price : (props.pool.borrow_asset_id === 3 ? gasBTCData.price : gasETHData.price)),
            parseInt((props.pool.borrow_asset_id === 1) ? gasKPWData.limit : (props.pool.borrow_asset_id === 2 ? gasETHData.limit : (props.pool.borrow_asset_id === 3 ? gasBTCData.limit : gasETHData.limit))),
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTransaction(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });

        console.log("black magic Done");
    }

    function lendingPoolWithdraw (lendingPoolAddress, vaultAddress) {


        const tx = new LendingPoolDispenseTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            localStorage.getItem("org_hash"),
            vaultAddress,
            lendingPoolAddress,
            lendingPoolAddress,
            coreAssets.find(item => item.id === props.pool.borrow_asset_id),
            assetQuantity.toString(),
            gasValues.find(item => item.id === props.pool.borrow_asset_id).price,
            gasValues.find(item => item.id === props.pool.borrow_asset_id).limit
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTransaction(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    }

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Transaction Sent!',
            'success'
        )
    }

    // XHR Request to send transaction to Blockchain
    async function  sendTransaction (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            // notify("tr");
            return;
        } else {
            toggleSupplyWithdrawModal();
            openPop();
        }
        dispatch(getNonce());
        const json = await response.json();
        console.log("successful transaction", json);
        // setTxResponse(json);
    }

    const toggleSupplyWithdrawModal = () => {
        setSupplyWithdrawModal(!supplyWithdrawModal);
    };

    const getCurrentAssetBalance = (portfolio) => {
        return Number(
            Quantity.hexToDecimal(
                coreAssets.find(item => item.id === props.pool.borrow_asset_id),
                portfolio.find(item => item.id === props.pool.borrow_asset_id).confirmed_quantity)
        );
    };

    console.log("pool", props.pool.name, props.pool.max_loan_period);

    return (
        <Card className="single-pool">
            {/* Vault Modal - 3 for Create Vault Modal */}
            <Modal isOpen={supplyWithdrawModal} toggle={toggleSupplyWithdrawModal} className="" style={{width: "600px", maxWidth: "600px", height: "250px"}}>
                <div className="modal-header justify-content-left">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleSupplyWithdrawModal}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    {/*<h6 className="title title-up" style={{color: "white"}}>Create Vault</h6>*/}
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    <div>
                        <Col className="mb-4">
                            {/*<CardTitle tag="h4">Customisable Select</CardTitle>*/}
                            <Row>
                                <Col lg="12" md="6" sm="3" style={{padding: "0", maxWidth: "100%"}}>
                                    <Card style={{marginTop: "20px", border: "0.5px solid grey", background: "transparent", boxShadow: "none"}}>
                                        <CardHeader>
                                            <CardTitle tag="h4">
                                                <div className="asset-logo-pop">
                                                    <div>
                                                        {
                                                            coreAssets && coreAssets.length > 0 &&
                                                            <div>
                                                                <img src={ coreAssets.find(item => item.id === props.pool.borrow_asset_id).logo } alt="" />
                                                                <div>{ coreAssets.find(item => item.id === props.pool.borrow_asset_id).name }</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Form>
                                                <label>Borrowable Asset</label>
                                                <FormGroup>
                                                    <Input type="number" placeholder="Enter Asset Amount" onChange={(event) => {
                                                        setAssetQuantity(event.target.value)
;                                                    }}/>
                                                </FormGroup>

                                            </Form>
                                            <Form className="lending_details">
                                                <label>Source Vault: </label>
                                                <label>{props.pool.vault.vault_name}</label>
                                            </Form>
                                            <Form className="lending_details">
                                                <label>Vault Balance: </label>
                                                {
                                                    coreAssets &&
                                                    coreAssets.length > 0 &&
                                                    <label>
                                                        { getCurrentAssetBalance(props.pool.vault.portfolio) }
                                                        <span> { coreAssets.find(item => item.id === props.pool.borrow_asset_id).symbol }</span>
                                                    </label>
                                                }
                                            </Form>
                                            <Form className="lending_details">
                                                <label>Lending Pool Balance: </label>
                                                {
                                                    coreAssets &&
                                                    coreAssets.length > 0 &&
                                                    <label>
                                                        { getCurrentAssetBalance(props.pool.portfolio) }
                                                        <span> { coreAssets.find(item => item.id === props.pool.borrow_asset_id).symbol }</span>
                                                    </label>
                                                }
                                            </Form>
                                            <div>

                                            </div>
                                        </CardBody>
                                        <CardFooter style={{textAlign: "center"}}>
                                            <Button className="btn-fill" color="primary"  onClick={() => {
                                                lendingPoolSupply(props.pool.address, props.pool.vault.vault_address);
                                            }}>
                                                {
                                                    coreAssets && coreAssets.length > 0 &&
                                                    <span>Supply {coreAssets.find(item => item.id === props.pool.borrow_asset_id).symbol}</span>
                                                }
                                            </Button>
                                            <Button className="btn-fill" color="primary"  onClick={() => {
                                                lendingPoolWithdraw(props.pool.address, props.pool.vault.vault_address);
                                            }}>
                                                {
                                                    coreAssets && coreAssets.length > 0 &&
                                                    <span>Withdraw {coreAssets.find(item => item.id === props.pool.borrow_asset_id).symbol}</span>
                                                }
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        {/*<input type="text" placeholder="Search Exchange Name" style={{width: "calc(100% - 35px)", marginBottom: "10px"}} />*/}

                    </div>
                </ModalBody>
            </Modal>
            {/* End Vault Modal - 3 for Create Vault Modal */}

            <div className="pool-header">
                <div>{props.pool.name}</div>
                {
                    coreAssets &&
                    coreAssets.length > 0 &&
                    <div className="asset-logos">
                        <img src={ coreAssets.find(item => item.id === props.pool.borrow_asset_id).logo } alt="" />
                        <img src={ coreAssets.find(item => item.id === props.pool.collateral_asset_id).logo } alt="" />
                    </div>
                }
                {
                    coreAssets.find(item => item.id === props.pool.borrow_asset_id) &&
                    <div>
                        {/*<div>*/}
                        {/*    <div>{ props.pool.portfolio.find(item => item.id === props.pool.borrow_asset_id).name }</div>*/}
                        {/*    <div>*/}
                        {/*        Confirmed -*/}
                        {/*        {*/}
                        {/*            Number(*/}
                        {/*                Quantity.hexToDecimal(*/}
                        {/*                    coreAssets.find(item => item.id === props.pool.borrow_asset_id),*/}
                        {/*                    props.pool.portfolio.find(item => item.id === props.pool.borrow_asset_id).confirmed_quantity*/}
                        {/*                )*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*    </div> <br/>*/}
                        {/*    <div>*/}
                        {/*        UnConfirmed In - {*/}
                        {/*            Number(*/}
                        {/*                Quantity.hexToDecimal(*/}
                        {/*                    coreAssets.find(item => item.id === props.pool.borrow_asset_id),*/}
                        {/*                    props.pool.portfolio.find(item => item.id === props.pool.borrow_asset_id).unconfirmed_in_quantity*/}
                        {/*                )*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*    </div><br/>*/}
                        {/*    <div>*/}
                        {/*        UnConfirmed Out -*/}
                        {/*        {*/}
                        {/*            Number(Quantity.hexToDecimal(*/}
                        {/*                coreAssets.find(item => item.id === props.pool.borrow_asset_id),*/}
                        {/*                props.pool.portfolio.find(item => item.id === props.pool.borrow_asset_id).unconfirmed_out_quantity*/}
                        {/*            )*/}
                        {/*            )*/}
                        {/*        }</div>*/}
                        {/*</div>*/}
                        {/*<br/>*/}
                        {/*<div>*/}
                        {/*    <div>{props.pool.portfolio.find(item => item.id === props.pool.collateral_asset_id).name}</div>*/}
                        {/*    <div>Confirmed -*/}
                        {/*        {*/}
                        {/*            Number(*/}
                        {/*                Quantity.hexToDecimal(*/}
                        {/*                    coreAssets.find(item => item.id === props.pool.collateral_asset_id),*/}
                        {/*                    props.pool.portfolio.find(item => item.id === props.pool.collateral_asset_id).confirmed_quantity*/}
                        {/*                )*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*    </div> <br/>*/}
                        {/*    <div>UnConfirmed In - { Number(Quantity.hexToDecimal(*/}
                        {/*        coreAssets.find(item => item.id === props.pool.collateral_asset_id),*/}
                        {/*        props.pool.portfolio.find(item => item.id === props.pool.collateral_asset_id).unconfirmed_in_quantity)) }</div><br/>*/}
                        {/*    <div>UnConfirmed Out - { Number(Quantity.hexToDecimal(*/}
                        {/*        coreAssets.find(item => item.id === props.pool.collateral_asset_id),*/}
                        {/*        props.pool.portfolio.find(item => item.id === props.pool.collateral_asset_id).unconfirmed_out_quantity)) }</div>*/}
                        {/*</div>*/}
                    </div>
                }
            </div>
            <div className="pool-body">
                <div className="pool-body-style" style={{display: "flex", justifyContent: "space-between"}}>
                    <div className="lender-borrowers">
                        <div>
                            <span>Borrowers</span>
                            <span>100</span>
                        </div>
                        <div>
                            <span>Interest Accrued</span>
                            <span>
                                21,000
                                <sub style={{fontSize: "11px", marginLeft: "3px"}}>USDT</sub>
                            </span>
                        </div>
                        <div>
                            <span>Liquidity Left</span>
                            <span>51,000<sub style={{fontSize: "11px", marginLeft: "3px"}}>USDT</sub></span>
                        </div>
                    </div>
                    {/*<div className="straight">*/}
                    {/*    <div>*/}
                    {/*        <span> <img src={require("assets/img/bitcoin.png").default} width="20" alt=""/> </span>*/}
                    {/*    </div>*/}
                    {/*    <div> : </div>*/}
                    {/*    <div>*/}
                    {/*        <span> <img src={require("assets/img/USDT.png").default} width="20" alt=""/> </span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div>
                        <span>Collateral Ratio</span>
                        <span>{(props.pool.collateral_ratio)*100 + "%"}</span>
                    </div>

                    <div>
                        <span>Lending Rate</span>
                        <span>{props.pool.lending_rate}%</span>
                    </div>

                    <div>
                        <span>Liquidation Threshold</span>
                        <span>{((props.pool.liquidation_threshold)*100).toFixed(0) + "%"}</span>
                    </div>

                    <div>
                        <span>Max Load Period</span>
                        <span>{props.pool.max_loan_period/(60*24*60).toFixed(2)} days</span>
                    </div>
                </div>

                <div className="action-items">
                    <Button color="info" onClick={ toggleSupplyWithdrawModal }>Supply</Button>
                    <Button color="secondary" onClick={ toggleSupplyWithdrawModal }>Withdraw</Button>
                </div>
            </div>
        </Card>
    );
};

export default SinglePool;