import React, { useEffect, useState, useRef } from "react";
import { Link, Route, Switch, useParams, useRouteMatch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// import {config} from "../../config";
// import { xconfig } from "../../exchangeconfig";

import {
    Button, Card, CardBody, CardFooter, CardHeader, CardTitle,
    Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row, Table, UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
import "./adminconsole.scss";
import MemberPermissions from "../MemberPermissions";
// import { currentView } from "./../../reducers/currentView";
import {config} from "../../config";
import Avatar from "react-avatar";
import {PBMTransferTransaction, Quantity, RemoveOrganisationMemberTransaction} from "kapow-sdk";
import { getNonce } from "../../reducers/currentNonce";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import {NER} from "../../constants";
import {currentTokenPool} from "../../reducers/currentTokenPool";


// const t = "assets/img/binance-logo.svg"
const AdminConsole = (props) => {

    // const currentTokenPool = useSelector(state => state.setCurrentTokenPool);

    const [modalClassic, setModalClassic] = useState(false);
    const [currentView, setCurrentView] = useState("init");
    const [orgMemberList, setOrgMemberList] = useState([]);
    const [invitedMemberList, setInvitedMemberList] = useState([]);
    const [currentMemberList, setCurrentMemberList] = useState([]);
    const [allMemberList, setAllMemberList] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);
    // const currentMemberView = useSelector(state => state.setCurrentMemberView);
    const currentTokenPool = useSelector(state => state.currentTokenPool);

    const [currentUserTokenAddress, setCurrentUserTokenAddress] = useState("");

    // Pagination Items
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 5;

    const dispatch = useDispatch();
    const nonce = useSelector(state => state.currentNonce);
    const notificationAlertRef = useRef(null);
    const [modalClassicToken, setModalClassicToken] = useState(false);
    const [tokenQuantity, setTokenQuantity] = useState("0.00");
    const [gasETHData, setETHGasData] = useState(null);

    useEffect(() => {

        (async () => {
            dispatch(getNonce());
            await getOrganizationMembers();
            await getETHGasData();
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [nonce]);

    const openPop = (msg) => {
        Swal.fire(
            'Success!',
            msg,
            'success'
        )
    }

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    async function getETHGasData () {
        const response = await fetch(
            `${config.eth_gas_api}`
        );

        const gas_data = await response.json();
        console.log("ETH gas_data", gas_data.data);
        setETHGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getOrganizationMembers (page_number=1) {
        const response = await fetch(
            `${config.api_url}/${config.v}/organisations/${localStorage.getItem("org_hash")}/${config.endpoints.members}?limit=${itemsPerPage}&page=${page_number}&order=desc`
        );

        let org_member_list = await response.json();

        setAllMemberList(org_member_list.data);
        setCurrentMemberList(org_member_list.data);
        setOrgMemberList(org_member_list.data);

        if(org_member_list.data && org_member_list.data.length > 0) {
            setCurrentView("member-list");
        }
        if (org_member_list.paginate && org_member_list.paginate.total_page) {
            setTotalPages(org_member_list.paginate.total_page);
        }
    }

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        if (currentView === "member-list") {
            getOrganizationMembers(selectedPage.selected + 1).then((res) => {
                console.log("Get Organization Members - Response", res);
            });
        } else if (currentView === "invite-list") {
            getOrganizationInvitedMembers(selectedPage.selected + 1).then((res) => {
                console.log("Get Invited Organization Members - Response", res);
            });
        }
    };

    async function getOrganizationInvitedMembers (page_number=1) {
        const response = await fetch(
            `${config.api_url}/${config.v}/organisations/${localStorage.getItem("org_hash")}/${config.endpoints.members_invite}/?status=3553&limit=${itemsPerPage}&page=${page_number}&order=desc`
        );

        let invited_member_list = await response.json();

        setAllMemberList(invited_member_list.data);
        setCurrentMemberList(invited_member_list.data);
        setInvitedMemberList(invited_member_list.data);

        if(invited_member_list.data && invited_member_list.data.length > 0) {
            setCurrentView("invite-list");
        }
        if (invited_member_list.paginate && invited_member_list.paginate.total_page) {
            setTotalPages(invited_member_list.paginate.total_page);
        }
    }

    const switchView = (view) => {
        // reset the current Selected Paginated Item.
        setCurrentPage(0)
        if (view === "invite-list") {
            getOrganizationInvitedMembers().then(() =>{});
        } else if (view === "member-list") {
            getOrganizationMembers().then(() => {});
        }
    };

    const removeMemberFromOrg = (member_hash) => {
        const tx = new RemoveOrganisationMemberTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), "hex"),
            localStorage.getItem("org_hash"),
            "",
            "",
            member_hash
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx, "Member Removed!").then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    }

    const toggleModalClassicToken = () => {
        setModalClassicToken(!modalClassicToken);
    };

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx, msg) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        const json = await response.json();

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            notify("tr", json.message);
            
        } else {
            openPop(msg);
            toggleModalClassicToken();
            // notify("successful transaction");
            // dispatch(setCurrentMemberView("member-list"));
            // history.push("/admin/governance");
        }
    
        dispatch(getNonce());
        console.log("Rule Created Successfully", json);
        return;
        
    }

    const notify = (place, msg) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    // Front End Filter for Vault Names.
    const filterList = (searchValue) => {
        if (searchValue === '') {
            if (currentTab === 1) {
                setCurrentMemberList(orgMemberList);
            } else {
                setCurrentMemberList(invitedMemberList);
            }
        } else {
            const filteredData = allMemberList.filter((member) => {
                return member.member_name.toLowerCase().includes(searchValue.toLowerCase())
            });
            if (currentTab === 1) {
                setCurrentMemberList(filteredData);
            } else {
                setCurrentMemberList(filteredData);
            }
        }
    }

    const disbursalTransaction = () => {

        const disbursalTx = new PBMTransferTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            tokenQuantity,
            JSON.parse(localStorage.getItem("current_token_pool")).pbm_asset,
            currentUserTokenAddress,
            JSON.parse(localStorage.getItem("current_token_pool")).address,
            "0x00",
            parseInt(gasETHData.limit),
            "testing 123, 321!"
        );

        disbursalTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = disbursalTx.toBase64();
        sendTx(signedTx, "Tokens Disbursed!").then((res) => {
            console.log("response", res);
        });
    };



    let { path } = useRouteMatch();

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>

            {/* Disbursement Modal -for Token Disbursement */}
            <Modal isOpen={modalClassicToken} toggle={toggleModalClassicToken} className="">
                <div className="modal-header justify-content-left">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalClassicToken}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    {/*<h6 className="title title-up" style={{color: "white"}}>Create Vault</h6>*/}
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    <div>
                        <Col className="mb-4">
                            {/*<CardTitle tag="h4">Customisable Select</CardTitle>*/}
                            <Row>
                                <Col lg="12" md="6" sm="3" style={{padding: "0", maxWidth: "100%"}}>
                                    <Card style={{marginTop: "20px", border: "0.5px solid grey", background: "transparent", boxShadow: "none"}}>
                                        <CardHeader>
                                            <CardTitle tag="h4">
                                                <div className="vault-logo">
                                                    <div>
                                                        <img src={require("assets/img/vault-transparent.png").default} alt="" />
                                                    </div>
                                                </div>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Form>
                                                <label>Disburse Token to </label>
                                                <FormGroup>
                                                    <Input type="text" placeholder="Enter the Tokens that you want to Disburse"
                                                           onChange={(e) => setTokenQuantity(e.target.value)}
                                                    />
                                                </FormGroup>
                                                {/*<label>Choose Assets</label>*/}
                                            </Form>
                                        </CardBody>
                                        <CardFooter style={{textAlign: "center"}}>
                                            <Button className="btn-fill" color="primary"  onClick={ disbursalTransaction }>
                                                Disburse Tokens
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        {/*<input type="text" placeholder="Search Exchange Name" style={{width: "calc(100% - 35px)", marginBottom: "10px"}} />*/}

                    </div>
                </ModalBody>

            </Modal>
            {/* Disbursement Modal -for Token Disbursement */}

            <div className="content admin-console dark-theme">
                <Switch>
                    <Route exact path={path}>
                        <Col xs="12">
                            <Card>
                                <CardHeader>
                                    Manage People & Permissions
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal">
                                        <Row>
                                            {/*<Label md="3">Create Group</Label>*/}
                                            <Col md="12">
                                                <FormGroup className="people-top-bar">
                                                    <div>

                                                        <input
                                                            type="text"
                                                            placeholder="Search By Name or Email"
                                                            onChange={(e) => {
                                                                return filterList(e.target.value)
                                                            }}
                                                        />
                                                        <i className="tim-icons icon-zoom-split" />
                                                    </div>
                                                    <Link className="new-vault" to={`console/new`}>
                                                        + Add new Member
                                                    </Link>
                                                    {/*<div className="email-invite-holder">*/}
                                                    {/*    <input type="text" placeholder="Invite By Email (multiple emails separated by comma)" />*/}
                                                    {/*    <Button color="primary" onClick={createGroup}>Invite</Button>*/}
                                                    {/*</div>*/}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/*<Button color="info" onClick={createGroup}>Create Group</Button>*/}
                                        <div className="filter-tab-group">
                                            <Button
                                                color="link"
                                                className={currentTab === 1 ? `active-tab` : `` }
                                                onClick={() => {
                                                    setCurrentTab(1);
                                                    switchView("member-list");
                                                }}
                                            >
                                                Confirmed Members
                                            </Button>
                                            <Button
                                                color="link"
                                                className={currentTab === 2 ? `active-tab` : `` }
                                                onClick={() => {
                                                    setCurrentTab(2);
                                                    switchView("invite-list");

                                                }}
                                            >
                                                Invited Members
                                            </Button>
                                        </div>
                                    </Form>

                                    {
                                        currentView === "init" &&
                                        <div className="first-view">
                                            <div className="step-1">
                                                <div className="team-image">
                                                    <div>
                                                        <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1664353674/web_application/add_team_injcee.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="step-1__text">Add Your Team Member</div>
                                                <Link to={`console/new`}>
                                                    <div className="step-1__action">
                                                        <Button className="btn-primary">+ Add Team</Button>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                    {
                                        currentView !== "init" &&
                                        <Table responsive className="member-list">
                                            <colgroup>
                                                <col span="1" width="10%" />
                                                <col span="1" width="20%" />
                                                <col span="1" width="23%" />
                                                <col span="1" width="10%" />
                                                <col span="1" width="15%" />
                                                <col span="1" width="25%" />
                                            </colgroup>
                                            <thead className="text-primary">
                                            <tr>
                                                <th className="text-center">#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Permissions</th>
                                                <th>Pool Balance</th>
                                                <th >Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                currentMemberList && currentMemberList.length > 0 &&
                                                currentMemberList.map((member, index) => (
                                                    <tr key={member.member_address}>
                                                        <td className="text-center">
                                                            {
                                                                member.profile_image &&
                                                                <div className="photo">

                                                                    <img
                                                                        alt="..."
                                                                        src={`https://api.devnet.kapow.tech/v1/files/images/${member.profile_image}`}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !member.profile_image &&
                                                                <Avatar
                                                                    name={member.member_name}
                                                                    size="40"
                                                                />
                                                            }
                                                        </td>
                                                        <td>{member.member_name === "" ? "Root Admin" : member.member_name}</td>
                                                        <td>{member.member_email === "" ? "-" : member.member_email}</td>
                                                        <td>
                                                            {
                                                                (member.member_name === "" && member.member_type === "Employee") ?
                                                                    "Owner" :
                                                                    member.member_type
                                                            }
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {
                                                                    localStorage.getItem("current_token_pool") &&
                                                                    localStorage.getItem("current_token_pool") !== "undefined" &&
                                                                    Number(
                                                                        Quantity.hexToDecimal(
                                                                            JSON.parse(localStorage.getItem("current_token_pool")).pbm_asset,
                                                                            JSON.parse(localStorage.getItem("current_token_pool")).portfolio[JSON.parse(localStorage.getItem("current_token_pool")).portfolio.findIndex(obj => obj.id === JSON.parse(localStorage.getItem("current_token_pool")).pbm_asset.id)].confirmed_quantity
                                                                        )
                                                                    )
                                                                }
                                                                {
                                                                    localStorage.getItem("current_token_pool") &&
                                                                    localStorage.getItem("current_token_pool") !== "undefined" &&
                                                                    <img src={JSON.parse(localStorage.getItem("current_token_pool")).pbm_asset.logo} alt="" width="24px" style={{marginLeft: "10px"}} />
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="text-right">

                                                            {/*{*/}
                                                            {/*    localStorage.getItem("current_token_pool") &&*/}
                                                            {/*    <div>*/}
                                                            {/*        { JSON.parse(localStorage.getItem("current_token_pool")).invalid_mcc }*/}
                                                            {/*    </div>*/}
                                                            {/*}*/}

                                                            <Button
                                                                color="primary"
                                                                className="new-vault btn-simple"
                                                                style={{height: "35px", marginRight: "20px", width: "150px", float: "left"}}
                                                                onClick={ () => {
                                                                    setCurrentUserTokenAddress(member.member_address)
                                                                    toggleModalClassicToken ()
                                                                }
                                                            }
                                                            >
                                                                Disburse Tokens
                                                            </Button>
                                                            <Link to={`console/${member.member_address}`}>
                                                                <Button
                                                                    className="btn-link btn-icon"
                                                                    color="success"
                                                                    id="tooltip324367706119"
                                                                    size="sm"
                                                                >
                                                                    <i className="tim-icons icon-pencil" />
                                                                </Button>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target="tooltip324367706119"
                                                                >
                                                                    Edit Member Details
                                                                </UncontrolledTooltip>
                                                            </Link>

                                                            {
                                                                currentView === "member-list" &&
                                                                <span>
                                                                    <Button
                                                                        className="btn-link"
                                                                        color="danger"
                                                                        id="tooltip974171201345"
                                                                        size="sm"
                                                                        onClick={ () => {
                                                                            removeMemberFromOrg(member.member_address);
                                                                        } }
                                                                    >
                                                                        <i className="tim-icons icon-simple-remove" />
                                                                    </Button>
                                                                    <UncontrolledTooltip
                                                                        delay={0}
                                                                        target="tooltip974171201345"
                                                                    >
                                                                        Remove Member
                                                                    </UncontrolledTooltip>
                                                                </span>
                                                            }
                                                        </td>
                                                    </tr>
                                                    )
                                                )
                                            }
                                            </tbody>
                                        </Table>
                                    }
                                    <ReactPaginate
                                        pageCount={ totalPages }
                                        onPageChange={ handlePageChange }
                                        forcePage={ currentPage }
                                        renderOnZeroPageCount={ null }
                                        className="pagination"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        activeClassName="active"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Route>
                </Switch>


                <Route path={`${path}/:id`}>
                    <MemberPermissions
                        modalClassic={modalClassic}
                        toggleModalClassic={toggleModalClassic}
                    />
                </Route>
            </div>
        </>
    );
};

export default AdminConsole;
