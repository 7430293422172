import React from "react";
import {useSelector} from "react-redux";
// import { topLoaderState } from "./../../reducers";

import "./toploader.scss"

const TopLoader = () => {
    const topLoaderState = useSelector(state => state.topLoaderState);

    return (
        <div className={ topLoaderState ? "top-loader active" : "top-loader" }></div>
    );
}

export default TopLoader;