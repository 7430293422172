
import { connect } from '@giantmachines/redux-websocket';
import store from "./../store"

const socketUrl = 'wss://api.devnet.kapow.tech/ws';
// const socket_v2 =
// const socketUrl = 'ws://d2d1-103-87-59-240.ngrok.io/ws';

store.dispatch(connect(socketUrl));
export function setTransactionStates (transaction_state) {
    return { type:"SET_TRANSACTION_STATUS", payload: transaction_state }
}

// export function getIncomingStatus (payload) {
//     console.log("Incoming Message", payload);
//     return { type:"REDUX_WEBSOCKET::MESSAGE", payload: payload }
// }

export const startSocket = () => async dispatch => {
    // console.log("Start WebSocket Server");
    // store.dispatch(connect(socketUrl));
}

// export const SocketClient = () => {
//     const {
//         sendMessage,
//         sendJsonMessage,
//         lastMessage,
//         lastJsonMessage,
//         readyState,
//         getWebSocket,
//     } = useWebSocket(socketUrl, {
//         onOpen: () => {
//             console.log('opened');
//         },
//         //Will attempt to reconnect on all close events, such as server shutting down
//         shouldReconnect: (closeEvent) => true,
//     });
// }

