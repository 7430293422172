import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {config} from "./../config";
import Identicon from 'react-identicons';

import {
    Button,
    Col, FormGroup, Input, Modal, ModalBody, Row, Table, UncontrolledTooltip,
    UncontrolledCollapse, CardBody, Card
} from "reactstrap";
import ReactPaginate from "react-paginate";

import {CreateApproveTransaction, CreateRejectTransaction, KeyPair, Quantity, TransferTransaction} from "kapow-sdk";


import { clip_address, getSimpleDate, formatDate } from "../variables/utils";
import {tx_status} from "../constants";
import { getNonce } from "../reducers/currentNonce";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import ActionTakers from "./../views/Modals/CurrentTransactionModal/ActionTakers";
import { currentAssetId } from "../reducers/assetList";
import LongHash from "../components/LongHash/LongHash";
import AvatarList from "./components/AvatarList/AvatarList";
import Note from "./components/Note/Note";
import NotificationAlert from "react-notification-alert";

const TransactionTable = (props) => {

    const { tx_type } = props;
    const currentAssetId = useSelector(state => state.currentAssetId);
    const tx_keys = ["Transaction Id", "Asset", "Status", "Tx Amount", "Date | Time", "From", "To", "Action"];
    const [transactions, setTransaction] = useState(null);
    const [assetList, setAssetList] = useState(null);
    const [coinPrice, setCoinPrice] = useState([]);
    const [modalClassic, setModalClassic] = useState(false);
    const [currentTx, setCurrentTx] = useState(null);
    const [currentStatusTx, setCurrentStatusTx] = useState(null);
    const [currentTab, setCurrentTab] = useState(1);
    const [initWait, setInitWait] = useState(false);

    const notificationAlertRef = useRef(null);

    // Pagination Items
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;


    const nonce = useSelector(state => state.currentNonce);
    const currentUser = useSelector(state => state.currentUser);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await getAssetList();
            // console.log("Inside Transaction table", props.assetId);
            await getTransactions("all");
            // await getTransactions("all");
            await getCoinPrice();
            dispatch(getNonce());
        })()
    }, []);

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Transaction Sent!',
            'success'
        )
    }

    const notify = (place, msg) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    async function getAssetList () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`
        );

        let assetList = await response.json();
        console.log("asset list shubham ew added data", assetList);
        setAssetList(assetList);
    }

    async function getCoinPrice () {
        const coinPriceData = await fetch(
            `${config.price_api_url}/${config.v}/${config.price_endpoints.coins}`
        );

        const coinPrice = await coinPriceData.json();
        setCoinPrice(coinPrice);
    }

    async function getTransactions (query_param, page_number=1) {
        // setCurrentStatusTx(statusCode);
        // console.log("Top G", statusCode, currentAssetId);
        console.log("Inside Get Transactions List npp");
        const endPoint = (query_param || query_param === "all") ?
            `${config.api_url}/${config.v}/${config.endpoints.transactions}?limit=${itemsPerPage}&page=${page_number}&order=desc&${tx_type}&orgHash=${localStorage.getItem("org_hash")}&${query_param}` :
            `${config.api_url}/${config.v}/${config.endpoints.transactions}?limit=${itemsPerPage}&page=${page_number}&order=desc&${tx_type}&assetID=${currentAssetId}`;

        const response = await fetch(
            endPoint
        );

        let transactionList = await response.json();
        if(!response.ok) {

        } else {
            if (transactionList && transactionList.length === 0) {
                setInitWait(null);
            } else {
                setInitWait(true);
                // Pagination Items

            }
        }

        setTransaction(transactionList.data);
        if (transactionList.paginate && transactionList.paginate.total_page) {
            setTotalPages(transactionList.paginate.total_page);
        }
    }

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        getTransactions("all", selectedPage.selected + 1).then((res) => {
            console.log("Get Current Txs - Response", res);
        });
        window.scrollTo({ top: 0, left: 0 });
    };

    async function getUserActionItems(member_address) {

        const endPoint = `${config.api_url}/${config.v}/account/${member_address}/required-actions`

        const response = await fetch(
            endPoint
        );

        let trans_actionList = await response.json();
        if(!response.ok) {

        } else {
            if (trans_actionList && trans_actionList.length === 0) {
                setInitWait(null);
            } else {
                setInitWait(true);
            }
        }

        setTransaction(trans_actionList.data);
    }

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const ApproveTx = (tx_hash) => {

        const tx = new CreateApproveTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            tx_hash
        );
        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();
        sendTx(signedTx).then((res) => {
            console.log("response", res);
        });
    }

    const RejectTx = (tx_hash) => {

        const tx = new CreateRejectTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            tx_hash
        );
        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();
        sendTx(signedTx).then((res) => {
            console.log("response", res);
        });
    }

    async function  sendTx (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        const json = await response.json();

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY! what not okay");
            notify("tr", json.message);
            return;
        } else {
            console.log("get Nonce area");
            toggleModalClassic();
            dispatch(getNonce());
            openPop();
            console.log("successful transaction", json);
        }
        getTransactions("all");
        // setTxResponse(json);
    }

    // Important Conditions Checks to Handle ive Scenarios.
    // #TODO Needs to be simplified with filers.
    // @mechanicals
    const isActionRequired = (tx) => {
        if (
            // why did this happen? Ask @Jjnu [Tx List Was working fine, and then it stopped working. had to add this check]
            tx.rules && tx.rules.length > 0 &&
            (
                (tx.rules[0].approvers && tx.rules[0].approvers.length > 0)
                ||
                (tx.rules[0].rejectors && tx.rules[0].rejectors.length)
            )
            &&
            (
                (tx.rules[0].approvers.findIndex(member => member.member_address === currentUser.member_address) > -1)
                ||
                (tx.rules[0].rejectors.findIndex(member => member.member_address === currentUser.member_address) > -1)
            )
        ) {
            return true;
        }
    }


    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <div>
                {/* Incoming Transaction Appear Disappear */}
                <Modal isOpen={modalClassic} toggle={toggleModalClassic} className="action-model">
                    <div className="modal-header justify-content-left">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassic}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up">Approve | Reject Transaction</h6>
                    </div>
                    <ModalBody className="justify-content-left connect-exchange-modal">
                        {
                            currentTx &&
                            <div className="transaction-card">
                                <div>
                                    <b className="title-wid-150">Status</b>
                                    <span className="value-wid">{tx_status[currentTx.status]}</span>
                                </div>
                                <div>
                                    <b className="title-wid-150">From</b>
                                    <span className="long-hash value-wid">
                                        {clip_address(currentTx.data.from)}
                                    </span>
                                    <span style={{width: "100px", display: "inline-block", textAlign: "center"}}>--------></span>
                                    {/*<b className="title-wid-150">To</b>*/}
                                    <span className="long-hash value-wid">
                                        {clip_address(currentTx.data.to)}
                                    </span>
                                </div>
                                <div>
                                    <b className="title-wid-150" style={{ marginTop: "20px"}}>Transaction</b>
                                    <div className="vertical-details">
                                        <span className="title-wid-150">Amount</span>
                                        <span className="value-wid">
                                            {
                                                Number(Quantity.hexToDecimal(assetList.find(item => item.id === currentTx.data.asset_id), (currentTx.data && currentTx.data.quantity) ? currentTx.data.quantity : 0))
                                            }
                                        </span><span className="value-wid">
                                            {
                                                assetList.find(item => item.id === currentTx.data.asset_id).symbol
                                            }
                                        </span>

                                    </div>
                                    <div className="vertical-details">
                                        <span className="title-wid-150">Date | Time</span>
                                        <span className="date-time-class">{new Date(currentTx.time_stamp*1000).toString().toString()}</span>
                                    </div>
                                </div>
                                <div className="approve-rejector-list">

                                    {
                                        currentTx.rules && currentTx.rules.length > 0 &&
                                        currentTx.rules[0].approvers && currentTx.rules[0].approvers.length > 0 &&
                                        <div>
                                            <b className="title-wid-150">Approver</b>
                                            <AvatarList
                                                members={ currentTx.rules[0].approvers }
                                                add_member={ false } size="30"
                                                show_state={ true }
                                                state_check={currentTx.approvals}
                                                party="approval"
                                            />
                                        </div>
                                    }
                                    {
                                        currentTx.rules && currentTx.rules.length > 0 &&
                                        currentTx.rules[0].rejectors && currentTx.rules[0].rejectors.length > 0 &&
                                        <div>
                                            <b className="title-wid-150">Rejector</b>
                                            <AvatarList
                                                members={ currentTx.rules[0].rejectors }
                                                add_member={ false }
                                                size="30"
                                                show_state={ true }
                                                state_check={currentTx.rejections}
                                                party="rejection"
                                            />
                                        </div>
                                    }
                                    {/*<ActionTakers id={ "tooltip6133296879" } actionTaker={ "approvers" } rules={ currentTx.rules } />*/}
                                    {/*<ActionTakers id={ "tooltip63390111" } actionTaker={ "rejectors" } rules={ currentTx.rules } />*/}
                                </div>
                                <div>
                                    {/*{*/}
                                    {/*    currentTx.rules && currentTx.rules.length > 0 &&*/}
                                    {/*    <div>Shubham</div>*/}
                                    {/*}*/}
                                    {
                                        currentTx.rules && currentTx.rules.length > 0 &&
                                        (currentTx.rules[0].approvers && currentTx.rules[0].approvers.length > 0) &&
                                        (currentTx.rules[0].approvers.findIndex(member => member.member_address === currentUser.member_address) > -1) &&
                                        <Button
                                            className="btn-simple" color="info"
                                            onClick={ () => {
                                                ApproveTx(currentTx.tx_hash);
                                            } }
                                        >
                                            Approve
                                        </Button>
                                    }
                                    {
                                        currentTx.rules && currentTx.rules.length > 0 &&
                                        (currentTx.rules[0].rejectors && currentTx.rules[0].rejectors.length) &&
                                        (currentTx.rules[0].rejectors.findIndex(member => member.member_address === currentUser.member_address) > -1) &&
                                        <Button
                                            className="btn-simple" color="info"
                                            onClick={ () => {
                                                RejectTx(currentTx.tx_hash);
                                            } }
                                        >
                                            Reject
                                        </Button>
                                    }
                                </div>
                            </div>
                        }
                    </ModalBody>
                </Modal>

                <div>
                    <div className="filter-tab-group">
                        <Button
                            color="link"
                            className={currentTab === 1 ? `active-tab` : `` }
                            onClick={() => {
                                setCurrentTab(1);
                                getTransactions("all");
                            }}
                        >
                            All
                        </Button>
                        <Button
                            color="link"
                            className={currentTab === 2 ? `active-tab status-600` : `status-600` }
                            onClick={() => {
                                setCurrentTab(2);
                                getTransactions("statusCode=600");
                            }}
                        >
                            Confirmed
                        </Button>
                        <Button
                            color="link"
                            className={currentTab === 3 ? `active-tab status-599` : `status-599` }
                            onClick={() => {
                                setCurrentTab(3);
                                getTransactions("statusCode=599");
                            }}
                        >
                            Pending | Action Required
                        </Button>
                        <Button
                            color="link"
                            className={currentTab === 4 ? `active-tab status-612` : `status-612` }
                            onClick={() => {
                                setCurrentTab(4);
                                getTransactions("statusCode=612");
                            }}
                        >
                            Rejected
                        </Button>
                        <Button
                            color="link"
                            className={currentTab === 5 ? `active-tab status-612` : `status-612` }
                            onClick={() => {
                                setCurrentTab(5);
                                getUserActionItems(currentUser.member_address);
                            }}
                        >
                            My Action Items
                        </Button>
                    </div>

                    <div>
                        <Table responsive className="transaction-table">
                            <colgroup>
                                <col span="1" width="12.5%" />
                                <col span="1" width="10%" />
                                <col span="1" width="11.5%" />
                                <col span="1" width="10%" />
                                <col span="1" width="15%" />
                                <col span="1" width="12.5%" />
                                <col span="1" width="12.5%" />
                                <col span="1" width="16%" />
                            </colgroup>
                            <thead className="text-primary zero-h">
                                <tr className="zero-h">
                                    {
                                        tx_keys.map((tx_header, index) => <th key={index} className="text-center">{tx_header}</th>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                transactions && transactions.length > 0 &&
                                transactions.map((tx) => (
                                    <tr key={tx.tx_hash}>
                                        <td>
                                            <LongHash hash={tx.tx_hash} mode="dark" />
                                        </td>
                                        <td>
                                            {
                                                assetList && assetList.length > 0 &&
                                                <span>
                                                        { assetList.find(item => item.id === tx.data.asset_id).name }
                                                </span>
                                            }
                                            {/*{tx.blockchain} <sup>[{tx.network}]</sup>*/}
                                        </td>
                                        <td><span className={`status-${tx.status}`}>{tx_status[tx.status]}</span></td>
                                        <td>
                                            {/*{(tx.Data && tx.Data.quantity) ? tx.Data.quantity : ""}*/}
                                            {
                                                assetList && assetList.length > 0 &&
                                                <div>
                                                    <span>
                                                        {
                                                            coinPrice && coinPrice.length > 0 &&
                                                            <div>
                                                                <div>
                                                                    { Number(Quantity.hexToDecimal(assetList.find(item => item.id === tx.data.asset_id), (tx.data && tx.data.quantity) ? tx.data.quantity : 0) * coinPrice[coinPrice.findIndex(elem => elem.id === tx.data.asset_id)].price).toFixed(4) } { config.price_unit }
                                                                </div>
                                                            </div>
                                                        }
                                                    </span>
                                                    <span>
                                                    {
                                                        Number(Quantity.hexToDecimal(assetList.find(item => item.id === tx.data.asset_id), (tx.data && tx.data.quantity) ? tx.data.quantity : 0))
                                                    }
                                                    </span> <span>
                                                        {
                                                            assetList.find(item => item.id === tx.data.asset_id).symbol
                                                        }
                                                    </span>
                                                </div>

                                            }
                                        </td>
                                        <td>
                                            <span className="date-time-class">
                                                {
                                                    formatDate(new Date(tx.time_stamp*1000))
                                                }
                                                {/*{new Date(tx.time_stamp*1000).toString().toString()}*/}
                                            </span>
                                        </td>
                                        <td>
                                            <LongHash hash={tx.data.from} mode="dark" />
                                        </td>
                                        <td>
                                            {
                                                tx.data.to &&
                                                <LongHash hash={tx.data.to} mode="dark" />
                                            }
                                        </td>
                                        {/*<td>*/}
                                        {/*    {*/}
                                        {/*        tx.rules && tx.rules.length > 0 &&*/}
                                        {/*        tx.rules[0].approvers && tx.rules[0].approvers.length > 0 &&*/}
                                        {/*        <AvatarList members={ tx.rules[0].approvers } add_member={ false } size="30" />*/}
                                        {/*    }*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*    {*/}
                                        {/*        tx.rules && tx.rules.length > 0 &&*/}
                                        {/*        tx.rules[0].rejectors && tx.rules[0].rejectors.length > 0 &&*/}
                                        {/*        <AvatarList members={ tx.rules[0].rejectors } add_member={ false } size="30" />*/}
                                        {/*    }*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*    <Button*/}
                                        {/*        className="btn-simple" color="info"*/}

                                        {/*    >*/}
                                        {/*        Approve*/}
                                        {/*    </Button>*/}
                                        {/*    /!*<div>{tx.action}</div>*!/*/}
                                        {/*    /!*<div>*!/*/}
                                        {/*    /!*    {*!/*/}
                                        {/*    /!*        tx.actors.map((actor) => (*!/*/}
                                        {/*    /!*                <sub key={actor.user_id}>{actor.username}</sub>*!/*/}
                                        {/*    /!*            )*!/*/}
                                        {/*    /!*        )*!/*/}
                                        {/*    /!*    }*!/*/}
                                        {/*    /!*</div>*!/*/}
                                        {/*</td>*/}
                                        {/*<td>*/}
                                        {/*    <Button*/}
                                        {/*        className="btn-simple no-space" color="info"*/}
                                        {/*        onClick={ () => {*/}
                                        {/*            setCurrentTx(tx);*/}
                                        {/*            toggleModalClassic();*/}
                                        {/*        } }*/}
                                        {/*    >*/}
                                        {/*        Approve*/}
                                        {/*    </Button>*/}
                                        {/*</td>*/}
                                        <td>
                                            {/*{*/}
                                            {/*    tx.status !== 599 &&*/}
                                            {/*    <div className="text-right small-size">No Action Required</div>*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*    tx.rules && tx.rules.length > 0 &&*/}
                                            {/*    <div>Shubham</div>*/}
                                            {/*}*/}

                                            <div className="action-items-txs">
                                                {
                                                    tx.status === 599 &&
                                                    tx.rules && tx.rules.length > 0 &&
                                                    isActionRequired(tx) &&
                                                    <div className="accept-reject-btn-group text-right">
                                                        <button
                                                            className="btn-simple no-space approve-btn" color="info"
                                                            onClick={ () => {
                                                                setCurrentTx(tx);
                                                                toggleModalClassic();
                                                            } }
                                                        >
                                                            Action Required
                                                        </button>
                                                        {/*<span className="action-separator">|</span>*/}
                                                        {/*<button*/}
                                                        {/*    className="btn-simple no-space reject-btn" color="info"*/}
                                                        {/*    onClick={ () => {*/}
                                                        {/*        setCurrentTx(tx);*/}
                                                        {/*        toggleModalClassic();*/}
                                                        {/*    } }*/}
                                                        {/*>*/}
                                                        {/*    Reject*/}
                                                        {/*</button>*/}
                                                    </div>
                                                }

                                                {/*Approval Button View Test*/}
                                                {/*{*/}
                                                {/*    tx.status === 599 &&*/}
                                                {/*    <div className="accept-reject-btn-group text-right">*/}
                                                {/*        <button*/}
                                                {/*            className="btn-simple no-space approve-btn" color="info"*/}
                                                {/*            onClick={ () => {*/}
                                                {/*                setCurrentTx(tx);*/}
                                                {/*                toggleModalClassic();*/}
                                                {/*            } }*/}
                                                {/*        >*/}
                                                {/*            Action Required*/}
                                                {/*        </button>*/}
                                                {/*        /!*<span className="action-separator">|</span>*!/*/}
                                                {/*    </div>*/}
                                                {/*}*/}
                                                {
                                                    tx.note && tx.note !== "" &&
                                                    <Note note={tx.note} />
                                                }
                                                {
                                                    !isActionRequired(tx) &&
                                                    (!tx.note || tx.note === "") &&
                                                    <span>No Action Required</span>
                                                }
                                            </div>
                                            {/*<Button color="primary" id={`buttonToggle_${tx.tx_hash}`}>*/}
                                            {/*    Show Details*/}
                                            {/*</Button>*/}
                                        </td>
                                        {/*<UncontrolledCollapse toggler={`buttonToggle_${tx.tx_hash}`}>*/}
                                        {/*    <Card>*/}
                                        {/*        <CardBody>*/}
                                        {/*            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt*/}
                                        {/*            magni, voluptas debitis similique porro a molestias consequuntur*/}
                                        {/*            earum odio officiis natus, amet hic, iste sed dignissimos esse*/}
                                        {/*            fuga! Minus, alias.*/}
                                        {/*        </CardBody>*/}
                                        {/*    </Card>*/}
                                        {/*</UncontrolledCollapse>*/}
                                    </tr>
                                ))
                            }
                            </tbody>

                        </Table>
                        {
                            (transactions && transactions.length > 0) &&
                            <ReactPaginate
                                pageCount={ totalPages }
                                onPageChange={ handlePageChange }
                                forcePage={ currentPage }
                                renderOnZeroPageCount={ null }
                                className="pagination"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                activeClassName="active"
                            />
                        }
                        {
                            (!transactions || transactions.length === 0) &&
                            // (currentStatusTx === "all") &&
                            (initWait === true) &&
                            <div className="first-view">
                                <div className="step-1">
                                    <div className="team-image">
                                        <div>
                                            <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1672930650/web_application/tl_qr60m5.webp" alt="" style={{width: "200px"}} />
                                        </div>
                                    </div>
                                    <div className="step-1__text">No Transactions Records Found</div>
                                    <Link color="info" to={`governance/new`}>
                                        <div className="step-1__action">
                                            {/*<Button className="btn-primary" toggleModalClassic={toggleModalClassic}>+ Make a Transaction</Button>*/}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransactionTable;
