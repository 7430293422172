// import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { config } from "../config";

export function currentVaultList (state= "", action) {
    switch(action.type){
        case 'SET_CURRENT_VAULT_LIST':
            return action.payload;
        default:
            return state;
    }
}

//asynchronous action
export const getCurrentVaultList = () => async dispatch => {
    let res = {data:[]};
    try {
        res = await axios.get(`${config.api_url}/${config.v}/${config.endpoints.accounts}/${config.endpoints.vaults}?address=${localStorage.getItem("ws_address")}&organisationHash=${localStorage.getItem("org_hash")}&limit=100`);
        await dispatch({
            type: 'SET_CURRENT_VAULT_LIST',
            payload: res.data
        })
    } catch (error) {
        //res.data = error;
        console.log(error);
    }
}