import { Base64 } from "../../base64";

export class CreateInvoiceDiscountingProposalData {
  maxDiscountingProposalPrecentage: number;
  feesPercent: number;
  


  constructor(maxDiscountingProposalPrecentage: number, feesPercent: number) {
    this.maxDiscountingProposalPrecentage = maxDiscountingProposalPrecentage;
    this.feesPercent = feesPercent;
  }

  public static fromJson(json: any): CreateInvoiceDiscountingProposalData {
    return new CreateInvoiceDiscountingProposalData(
      json["max_discounting_percent"],
      json["fees_percent"],
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "max_discounting_percent": this.maxDiscountingProposalPrecentage,
      "fees_percent": this.feesPercent,
    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
