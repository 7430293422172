import React, {useState} from "react";
import "./note.scss";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Modal,
    ModalBody,
    Row
} from "reactstrap";

const Note = (props) => {
    const [modalNote, setModalNote] = useState(false);

    const toggleModalNote = () => {
        setModalNote(!modalNote);
    };

    return (
        <>
            <div className="tx-note">
                {/* Vault Modal - 3 for Create Vault Modal */}
                <Modal isOpen={modalNote} toggle={toggleModalNote} className="note-modal">
                    <div className="modal-header justify-content-left">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalNote}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up">Transaction Note</h6>
                    </div>
                    <ModalBody>
                        <div>
                            <Col className="mb-4">

                                <Row>
                                    <Col lg="12" md="6" sm="3">
                                        <p>{props.note}</p>
                                    </Col>
                                </Row>
                            </Col>
                            {/*<input type="text" placeholder="Search Exchange Name" style={{width: "calc(100% - 35px)", marginBottom: "10px"}} />*/}

                        </div>
                    </ModalBody>

                </Modal>
                {/* End Vault Modal - 3 for Create Vault Modal */}
                <div className="accept-reject-btn-group text-center">
                    <button
                        className="btn-simple no-space see-note-btn" color="info"
                        onClick={ toggleModalNote }
                    >
                        View Note
                    </button>
                </div>
            </div>
        </>
    )
}

export default Note;