import React, {useEffect, useState} from "react";
import "./singleapp.scss"

import {
    Button, Card, CardBody, CardFooter, CardHeader, CardTitle,
    Col, Form, FormGroup, Input, Modal, ModalBody, Row,
} from "reactstrap";

const SingleApp = (props) => {
    const [modalClassic, setModalClassic] = useState(false);

    useEffect(() => {

    }, []);

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    return (
        <>
            <div className="app-section">
                {/* Classic Modal - 2 Exchange View*/}
                <Modal isOpen={modalClassic} toggle={toggleModalClassic} className="exchange-modal">
                    <div className="modal-header justify-content-left">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassic}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up">Bank Integrations</h6>
                    </div>
                    <ModalBody className="justify-content-left connect-exchange-modal">
                        <div>
                            <Col className="mb-4">
                                <Row>
                                    <Col lg="12" md="6" sm="3" style={{padding: "0", maxWidth: "100%"}}>
                                        <Card style={{marginTop: "20px", border: "0.5px solid grey", background: "transparent", boxShadow: "none"}}>
                                            <CardHeader>
                                                <CardTitle tag="h4" style={{textAlign: "center"}}>
                                                    <img
                                                        alt=""
                                                        style={{width: "auto", height: "60px"}}
                                                        src={props.logo_url}
                                                    />
                                                    <div>{props.app_name}</div>
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <Form action="#">
                                                    <label>Name on Xaults</label>
                                                    <FormGroup>
                                                        <Input type="email" />
                                                    </FormGroup>
                                                    <label>API Key</label>
                                                    <FormGroup>
                                                        <Input type="password" autoComplete="off" />
                                                    </FormGroup>
                                                    <label>App Secret</label>
                                                    <FormGroup>
                                                        <Input type="password" autoComplete="off" />
                                                    </FormGroup>
                                                </Form>
                                            </CardBody>
                                            <CardFooter style={{textAlign: "center"}}>
                                                <Button className="btn-fill" color="primary" type="submit">
                                                    Connect
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            {/*<input type="text" placeholder="Search Exchange Name" style={{width: "calc(100% - 35px)", marginBottom: "10px"}} />*/}

                        </div>
                    </ModalBody>

                </Modal>
                {/* End Classic Modal - 2 for Exchanges View*/}

                <div className="single-app">
                        <div>
                            {
                                props.status &&
                                <div className="xchange-status">
                                    <span className={`blink_text ${props.status === true ? "green" : "red"}`}>&nbsp;</span>
                                    <span>{props.status === true ? 'Live' : 'Under Maintenance'}</span>
                                 </div>
                                }
                        <div>
                            {/*<div className="xchange-logo-box">*/}
                            {/*    {*/}
                            {/*        props.app_name === "Binance" &&*/}
                            {/*        <img  alt="" key={props.key} src={require("assets/img/binance-logo.svg").default} />*/}
                            {/*    }*/}
                            {/*    {*/}
                            {/*        props.app_name === "FTX" &&*/}
                            {/*        <img  alt=""  key={props.key} src={require("assets/img/FTX-US_Logo_black.svg").default} />*/}
                            {/*    }*/}
                            {/*    {*/}
                            {/*        props.app_name === "Coinbase" &&*/}
                            {/*        <img  alt=""  key={props.key} src={require("assets/img/coinbase.svg").default}*/}
                            {/*        style={{marginTop: "15px"}} />*/}
                            {/*    }*/}
                            {/*    {*/}
                            {/*        props.app_name === "Kraken" &&*/}
                            {/*        <img  alt=""*/}
                            {/*            key={props.key}*/}
                            {/*              src={require("assets/img/Kraken-lockup-new-whitebg.png").default}*/}
                            {/*              style={{height: "auto", marginTop: "15px"}}*/}
                            {/*        />*/}
                            {/*    }*/}
                            {/*</div>*/}
                            <div className="xchange-logo-box">
                                {
                                    props.app_name === "State Bank of India" &&
                                    <img
                                        alt=""
                                        key={props.key}
                                        style={{height: "auto"}}
                                        src="https://res.cloudinary.com/kapow-tech/image/upload/v1684987690/Indian%20Bank%20Logos/SBI-Logo-768x432_jtov0d.png"
                                    />
                                }
                                {
                                    props.app_name === "HDFC" &&
                                    <img
                                        alt=""
                                        key={props.key}
                                        src="https://res.cloudinary.com/kapow-tech/image/upload/v1684988060/Indian%20Bank%20Logos/HDFC-Bank-emblem_fqdmpe.png"
                                    />
                                }
                                {
                                    props.app_name === "Axis Bank" &&
                                    <img
                                        alt=""
                                        key={props.key}
                                        style={{marginTop: "15px"}}
                                        src="https://res.cloudinary.com/kapow-tech/image/upload/v1684987976/Indian%20Bank%20Logos/Axis_Bank_logo.svg_uxzuke.png"
                                    />
                                }
                                {
                                    props.app_name === "ICICI Bank" &&
                                    <img
                                        alt=""
                                        key={props.key}
                                        style={{height: "auto", marginTop: "15px"}}
                                        src="https://res.cloudinary.com/kapow-tech/image/upload/v1684987875/Indian%20Bank%20Logos/608px-ICICI_Bank_Logo.svg_izg1yg.png"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="text-label text-center">{props.app_name}</div>
                    <div className="bottom-card">
                        {
                            props.status &&
                            <div>
                                <div>
                                    Connected
                                </div>
                            </div>
                        }
                        {
                            !props.status &&
                            <div className="x-connect-btn">
                                <Button
                                    className="btn-simple" color="info" onClick={toggleModalClassic}>Connect
                                </Button>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleApp;
