import React, { useEffect, useState, useRef } from "react";
import {useHistory} from "react-router-dom";
import {config} from "../../../config";
import "./createpool.scss";
import { KeyPair, CreateLendingPoolTransaction, } from "kapow-sdk"

import {
    Button,
    Card, CardBody, CardFooter,
    Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, Row,
} from "reactstrap";
import Select from "react-select";
import Slider from "nouislider";
import Swal from "sweetalert2";

import NotificationAlert from "react-notification-alert";

const CreatePool = (props) => {

    const { modalClassic, toggleModalClassic } = props;
    const [poolName, setPoolName] = useState("");
    const [APY, setAPY] = useState("");
    const [collateral, setCollateral] = useState("");
    const [threshold, setThreshold] = useState("");
    const [externalDeposits, setExternalDeposits] = useState(false);

    const [singleSelect, setSingleSelect] = useState(null);
    const [singleSelect1, setSingleSelect1] = useState(null);
    const [multipleSelect1, setMultipleSelect1] = useState(null);
    const [multipleSelect2, setMultipleSelect2] = useState(null);
    const [multipleSelect3, setMultipleSelect3] = useState(null);

    const [slider1, setSlider1] = useState(0);
    const [slider2, setSlider2] = useState(0);
    const [nonce, setNonce] = useState(0);
    const [assetList, setAssetList] = useState([]);

    const [poolResponse, setPoolResponse] = useState([]);
    const [collateralAsset, setCollateralAsset] = useState({});
    const [borrowableAsset, setBorrowableAsset] = useState({});
    const [sourceVault, setSourceVault] = useState(null);
    const [vault_list, setCurrentVaults] = useState(null);

    const slider1Ref = useRef(null);
    const notificationAlertRef = useRef(null);

    let history = useHistory();

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Lending Pool Created',
            'success'
        )
    }

    const notify = (place) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        const notifyMessage = "Pool Create Successfully";
        let options = {};
        options = {
            place: "tr",
            message: notifyMessage,
            type: "primary",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        (async () => {
            await getNonce();
            await getAssetList();
            await getCurrentVaults();
        })();

        const timeout = setTimeout(() => {
            let slider1 = slider1Ref.current;
            console.log("nested slider", slider1);
            if (slider1.className === "slider") {
                Slider.create(slider1, {
                    start: [0],
                    connect: [true, false],
                    step: 1,
                    range: { min: 0, max: 365 },
                });

                slider1.noUiSlider.on('update', (value) => {
                    console.log("slider1.noUiSlider.get()", slider1.noUiSlider.get(), Math.round(slider1.noUiSlider.get()));
                    document.getElementsByClassName("noUi-touch-area")[0].setAttribute("data-content", Math.round(slider1.noUiSlider.get()) + " days");
                    setSlider1(value);
                });
            }
        }, 5000);

        return () => {
            // this now gets called when the component unmounts
            window.clearTimeout(timeout);
        };

    }, []);

    async function getAssetList () {

        const [portfolioData, binanceAddressData, ftxAddressData] = await Promise.all([
            fetch(`${config.api_url}/${config.v}/${config.endpoints.portfolio}/${localStorage.getItem("ws_address")}`),
            // fetch('http://127.0.0.1:8000/api/v1/binanceaddress'),
            // fetch('http://127.0.0.1:8000/api/v1/ftxaddress'),
        ]);

        const portfolio = await portfolioData.json();
        // const binanceAddressList = await binanceAddressData.json();
        // const ftxAddressList = await ftxAddressData.json();


        // console.log("binanceAddressList", binanceAddressList);
        // console.log("ftxAddressList", ftxAddressList);


        const assetList = [];
        assetList.push(portfolio);
        // assetList.push(binanceAddressList);
        // assetList.push(ftxAddressList);

        let useAbleList = [];
        useAbleList.unshift({
            value: "",
            label: "Choose an Asset",
            name: "Choose an Asset",
            isDisabled: true,
        });

        console.log("Bew assetList", assetList);
        assetList.map((assetGroup, index) => {
            assetGroup.map((asset, innerIndex) => {
                useAbleList.push({
                    value: "",
                    label: asset.symbol,
                    name: asset.name,
                    logo: asset.logo,
                    id: asset.id
                })
            })
        })

        // let pop =  useAbleList.splice(-1);
        // useAbleList.push({
        //     value: "",
        //     label: "BAT",
        //     name: "Basic Attention Token",
        //     logo: "https://res.cloudinary.com/kapow-tech/image/upload/v1655622042/basic-attention-token-bat-logo_alfip8.svg"
        // });

        console.log("useAbleList", useAbleList);

        // let finalList = assetList.map((elem, index) => {
        //
        //     elem.map((e, innerIndex) => {
        //         useAbleList.push({
        //             value: "",
        //             label: e.id ? e.symbol : "",
        //             name: e.id ? e.symbol: "",
        //             logo: e.id ?
        //         })
        //     })
        // })
        setAssetList(useAbleList);
    }

    async function getNonce () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${localStorage.getItem("ws_address")}/sequence`
        );

        const nonce = await response.json();
        setNonce(nonce + 1);
    }

    const createPool = () => {

        console.log ("Create Pool",
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            localStorage.getItem("org_hash"),
            sourceVault.address,
            "",
            poolName,
            collateralAsset.id,
            borrowableAsset.id,
            parseFloat(APY),
            parseFloat(parseInt(collateral)/100),
            (parseFloat(threshold)/100),
            true,
            parseInt(slider1)*60*24*60,
            config.oracle_address,
            "usd"
        );
        let tx = new CreateLendingPoolTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            localStorage.getItem("org_hash"),
            sourceVault.address,
            "",
            poolName,
            collateralAsset.id,
            borrowableAsset.id,
            parseFloat(APY),
            parseFloat(parseInt(collateral)/100),
            (parseFloat(threshold)/100),
            true,
            parseInt(slider1)*60*24*60,
            config.oracle_address,
            "usd"
        )

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();
        sendTransaction(signedTx);
    }

    // XHR Request to send transaction to Blockchain
    async function  sendTransaction (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );


        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            // notify("tr");
            return;
        } else {
            openPop();
            notify("tr");
            history.push("/admin/pools");
        }
        const data = await response.json();
        setPoolResponse(data);
    }

    async function getCurrentVaults () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${config.endpoints.vaults}?address=${localStorage.getItem("ws_address")}&organisationHash=${localStorage.getItem("org_hash")}`
        );

        let vault_list = await response.json();
        console.log("vault Data ", vault_list.data);

        let useAbleList = [];
        useAbleList.unshift({
            value: "",
            label: "Choose a Source Vault",
            name: "Choose a Source Vault",
            isDisabled: true,
        });


        vault_list.data.map((vault, innerIndex) => {
            useAbleList.push({
                value: "",
                label: vault.vault_name,
                name: vault.vault_name,
                address: vault.vault_address
            })
        });
        console.log("Vault List", useAbleList);
        setCurrentVaults(useAbleList);
        // if(vault_list.data && vault_list.data.length > 0) {
        //     setCurrentView("vault");
        // }
    }

    return (
        <>
            <div className="create-pool">
                <div className="rna-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <div className="modal-header justify-content-left">
                    <h6 className="title title-up" style={{color: "#000"}}>Lending Pool Details</h6>
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    <div>
                        <Row>
                            <Col md="12">
                                <Form id="RegisterValidation">
                                    <Card className="rule-set">
                                        <CardBody>
                                            <FormGroup>
                                                <label>Pool Name</label>
                                                <Input
                                                    name="pool"
                                                    id="pool_name"
                                                    type="text"
                                                    value={poolName}
                                                    placeholder="Enter the Lending Pool Name"
                                                    onChange={(e) => setPoolName(e.target.value)}
                                                />
                                            </FormGroup>

                                            <div className="rules-form-elements calc-width">
                                                <label>Source Vault</label>
                                                <div style={{marginLeft: "20px"}}>
                                                    {
                                                        vault_list && vault_list.length > 0 &&
                                                        <Select
                                                            style={{color: "#fff"}}
                                                            className="react-select info"
                                                            classNamePrefix="react-select"
                                                            name="vault"
                                                            value={sourceVault}
                                                            onChange={(value) => {
                                                                console.log("value", value)
                                                                setSourceVault(value);
                                                            }}
                                                            getOptionLabel={value => (
                                                                <div>
                                                                    <img
                                                                        className="asset-logo-icon"
                                                                        src={require("assets/img/vault-transparent.png").default}
                                                                        alt=""
                                                                    />
                                                                    <span>{value.name}</span>
                                                                </div>
                                                            )}
                                                            options={vault_list}
                                                            placeholder="Choose Source Vault"
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <div className="rules-form-elements calc-width">
                                                <label>Collateral</label>
                                                <div style={{marginLeft: "20px"}}>
                                                    {
                                                        assetList && assetList.length > 0 &&
                                                        <Select
                                                            style={{color: "#fff"}}
                                                            className="react-select info"
                                                            classNamePrefix="react-select"
                                                            name="collateralAsset"
                                                            value={collateralAsset}
                                                            onChange={(value) => {
                                                                console.log("ASSET ID", value.id)
                                                                setCollateralAsset(value);
                                                            }}
                                                            getOptionLabel={value => (
                                                                <div>
                                                                    {
                                                                        value.logo
                                                                            ?
                                                                            <img alt="..." src={value.logo} className="asset-logo-icon" />
                                                                            :
                                                                            <span className="asset-logo-icon">&nbsp;</span>
                                                                    }
                                                                    <span>{value.name}</span>
                                                                </div>
                                                            )}
                                                            options={assetList}
                                                            placeholder="Choose Asset"
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <div className="rules-form-elements calc-width">
                                                <label>Borrowable Asset</label>
                                                <div style={{marginLeft: "20px"}}>
                                                    {
                                                        assetList && assetList.length > 0 &&
                                                        <Select
                                                            style={{color: "#fff"}}
                                                            className="react-select info"
                                                            classNamePrefix="react-select"
                                                            name="borrowableAsset"
                                                            value={borrowableAsset}
                                                            onChange={(value) => {
                                                                console.log("Borrowable Asset ID", value.id)
                                                                setBorrowableAsset(value);
                                                            }}
                                                            getOptionLabel={value => (
                                                                <div>
                                                                    {
                                                                        value.logo
                                                                            ?
                                                                            <img alt="..." src={value.logo} className="asset-logo-icon" />
                                                                            :
                                                                            <span className="asset-logo-icon">&nbsp;</span>
                                                                    }
                                                                    <span>{value.name}</span>
                                                                </div>
                                                            )}
                                                            options={assetList}
                                                            placeholder="Choose Asset"
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <FormGroup>
                                                <label>Interest Rate</label>
                                                <Input
                                                    name="pool"
                                                    id="pool_name"
                                                    type="text"
                                                    value={APY}
                                                    placeholder="Enter the Interest Rate you want to charge"
                                                    onChange={(e) => setAPY(e.target.value)}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <label>% of Over Collateralization</label>
                                                <Input
                                                    name="pool"
                                                    id="pool_name"
                                                    type="text"
                                                    value={collateral}
                                                    placeholder="Enter the % for Over Collateralization for the Borrowable Asset"
                                                    onChange={(e) => setCollateral(e.target.value)}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <label>Liquidation Threshold</label>
                                                <Input
                                                    name="pool"
                                                    id="pool_name"
                                                    type="text"
                                                    value={threshold}
                                                    placeholder="Enter the % at which Assets will be Liquidated"
                                                    onChange={(e) => setThreshold(e.target.value)}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <div className="rules-form-elements maturity-date-picker">
                                                    <label>Max Maturity Date</label>
                                                    <Col md="6" className="slider-1">
                                                        <div className="slider" ref={slider1Ref} />
                                                    </Col>
                                                </div>
                                            </FormGroup>

                                            {/*<FormGroup>*/}
                                            {/*    <label>Allow External Deposits</label>*/}
                                            {/*    <div className="card-stats justify-content-center toggle-switch">*/}
                                            {/*        <div className="d-flex justify-content-center align-items-center">*/}
                                            {/*            <span className="mr-2">Off</span>*/}
                                            {/*            <CustomInput*/}
                                            {/*                type="switch"*/}
                                            {/*                id="switch-2"*/}
                                            {/*                className="mt-n4"*/}
                                            {/*                onChange={(e) => setExternalDeposits(!externalDeposits)}*/}
                                            {/*            />*/}
                                            {/*            <span className="ml-n4">On</span>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</FormGroup>*/}
                                        </CardBody>

                                        <CardFooter className="text-right">
                                            <FormGroup check className="pull-left">
                                                <Label check>
                                                    &nbsp;
                                                </Label>
                                            </FormGroup>
                                            <Button color="primary" onClick={createPool}>
                                                Create Lending Pool
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </div>
        </>
    );
};

export default CreatePool;
