import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { EditOrganisationMemberPermissionData } from "./data/data_organisation_member_permission_edit";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class OrganisationMemberPermissionEditTransaction extends Transaction {
  
  // type: number = TransactionTypeEnum.TX_TYPE_ORGANISATION_ADD_PERMISSION;
  type: number = 1534;

  // data variables
  memberHash: string;
  permissions: Array<Number>;
  vaults: Array<string>;

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, memberHash: string, permissions: Array<Number>, vaults: Array<string>) {
    super();
    // this.nonce = nonce;
    // this.address = address;
    // this.publicKey = publicKey;
    this.memberHash = memberHash;
    this.permissions = permissions;
    this.vaults = vaults;

    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new EditOrganisationMemberPermissionData(this.memberHash, this.permissions, this.vaults).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();
  }
}
