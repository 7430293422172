import React, {useEffect, useState, useRef, } from "react";
import {useDispatch, useSelector} from "react-redux";
import store from "./../../store"
import useWebSocket from 'react-use-websocket';
// import { connect } from '@giantmachines/redux-websocket';
import { send, connect } from '@giantmachines/redux-websocket';
import {config} from "../../config";
import "./transfermodal.scss";

import {
    Button,
    Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row,
} from "reactstrap";
import Select from "react-select";
import {PBMRedeemTransaction, Quantity, TransferTransaction, VaultTransferTransaction} from "kapow-sdk";
import NotificationAlert from "react-notification-alert";
import Swal from 'sweetalert2';
import {NER, tx_status} from "./../../constants";
import { startSocket, SocketClient } from "./../../actionCreators/setTransactionStates";
import { currentAssetId } from "../../reducers/assetList";
import { setCurrentAssetId } from "../../actionCreators/setAssetList";
import { currentVaultNameReducer } from "../../reducers/currentVault";
import { getNonce } from "./../../reducers/currentNonce";

const TransferModal = (props) => {

    const dispatch = useDispatch();
    const currentAssetId = useSelector(state => state.currentAssetId);
    const currentVaultNameReducer = useSelector(state => state.currentVaultNameReducer);
    console.log("Cheing Asset Id", currentAssetId, props.vaultName, props.vaultId);
    const [alert, setAlert] = React.useState(null);

    // to stop the warning of calling setState of unmounted component
    React.useEffect(() => {
        (async () => {
            // store.dispatch(startSocket());
        })();

        handleSingleAsset(currentAssetId);
        console.log("vault data", props, props.vaultName);

        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    });

    const { modalClassic, setModalClassic, getPortfoliosAndCoinPrice, vaultId, assetId, vaultName, refreshVaultList } = props;
    const nonce = useSelector(state => state.currentNonce);
    const [assetList, setAssetList] = useState([]);
    const [singleAsset, setSingleAsset] = useState({});
    const [receiver, setReceiver] = useState("cnCEFpjcUwQDNx7NAadcGRjKxh6bnjdAJBmgLr1HKc6iML44");
    const [quantity, setQuantity] = useState("0.00");
    const [tabIndex, setTabIndex] = React.useState(1);
    const [binanceAddresses, setBinanceAddresses] = React.useState(null);
    const [ftxAddresses, setFtxAddresses] = React.useState(null);
    const [exchangeMapping, setExchangeMapping] = React.useState(null);
    const [chosenAssetId, setChosenAssetId] = React.useState(props.assetId);
    // const [modalClassic, setModalClassic] = React.useState(false);

    const final_tabMenuItems = ["All", "Vaults", "Exchanges", "Fiat", "Whitelisted"];
    const tabMenuItems = ["Vaults"];

    const notificationAlertRef = useRef(null);

    // admin console section
    const [ethAddress, setETHAddress] = useState(null);
    const [member, setMember] = useState(null);
    const [filtered_addresses, setFilteredAddresses] = useState(null);
    const [fromAddress, setFromAddress] = useState("");
    console.log("currentVaultNameReducer", currentVaultNameReducer);
    const [fromVaultName, setFromVaultName] = useState(currentVaultNameReducer);
    const [toVaultName, setToVaultName] = useState("");
    const [toAddress, setToAddress] = useState("");
    const [fromBalance, setFromBalance] = useState(0);
    const [toBalance, setToBalance] = useState(0);
    const [showFromVaultList, setFromVaultListState] = useState(false);
    const [showToVaultList, setToVaultListState] = useState(false);
    const [fromVaultLogo, setFromVaultLogo] = useState("");
    const [toVaultLogo, setToVaultLogo] = useState("");
    const [vault_list, setCurrentVaults] = useState(null);
    const [vaultPortfolioList, setVaultPortfolioList] = useState([]);
    const [gasETHData, setETHGasData] = useState(null);
    const [gasBTCData, setBTCGasData] = useState(null);
    const [gasKPWData, setKPWGasData] = useState(null);

    const [messageHistory, setMessageHistory] = useState([]);
    const [txStatus, setTxStatus] = useState("");
    const [initState, setInitState] = useState(false);
    const [txNote, setTxNote] = useState("");

    const exchange_vault_logo = [
        "https://res.cloudinary.com/kapow-tech/image/upload/v1655709677/exchange_logos/vault_ljiemj.jpg",
        "https://res.cloudinary.com/kapow-tech/image/upload/v1655709670/exchange_logos/binance_logo_msft6o.jpg",
        "https://res.cloudinary.com/kapow-tech/image/upload/v1655709670/exchange_logos/ftx_exchange_rligay.png",
    ];

    const [coreAssets, setCoreAssets] = useState([]);
    const exchange_accounts = ["All", "Core Vaults", "Binance Wallet", "FTX"];
    const socketUrl = 'wss://api.devnet.kapow.tech/ws';

    // const socketUrl = 'ws://157.245.154.224:6500/ws';

    // const {
    //     sendMessage,
    //     sendJsonMessage,
    //     lastMessage,
    //     lastJsonMessage,
    //     readyState,
    //     getWebSocket,
    // } = useWebSocket(socketUrl, {
    //     onOpen: () => {
    //         console.log('opened');
    //     },
    //     //Will attempt to reconnect on all close events, such as server shutting down
    //     shouldReconnect: (closeEvent) => true,
    // });

    React.useEffect(() => {
        // console.log("Inside Use Effect OUTSIDE WITHOUT DEP", currentVaultNameReducer);
        if(currentVaultNameReducer !== "" && initState === false) {
            console.log("TEST WITHOUT DEP", currentVaultNameReducer);
            setInitState(true);
            setFromVaultName(currentVaultNameReducer);
        }
    })

    useEffect(() => {
        (async () => {
            // await getLocalAssetAddress();
            dispatch(getNonce());
            await getAssetList();
            await getCoreAssets();
            

            await getETHGasData();
            await getBTCGasData();
            await getKPWGasData();

            // if (lastMessage !== null) {
            //     setMessageHistory((prev) => prev.concat(lastMessage));
            //
            //     if(lastMessage.data && lastMessage.data !== "") {
            //         // localStorage.setItem("ws_address", lastMessage.data);
            //         console.log("YO YO", lastMessage.data, JSON.parse(lastMessage.data).status);
            //         console.log("Last Test Message", tx_status[(JSON.parse(lastMessage.data).status).toString()], JSON.parse(lastMessage.data).error);
            //         // setTxStatus()
            //     }
            // }
        })();
        console.log("Inside Use Effect 1st W DEP");
        setFromVaultName(currentVaultNameReducer);
        return () => {
            refreshVaultList();
        }
        // setFromVaultName("Coinbase Treasury");
    // }, [lastMessage, setMessageHistory]);
    }, []);

    const toggleModalClassic3 = () => {
        setModalClassic(!modalClassic);
    };

    async function getCoreAssets () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.assets}?limit=100`
        );

        const json = await response.json();
        console.log("JSON DATA", json.data);
        if(!response.ok) {

        } else {
            console.log("Asset Id", currentAssetId, json.data);
            setSingleAsset(json.data.find(asset => asset.id === currentAssetId));
            console.log("YO MA TA TA DATA=>", props.vaultId, props.vaultName);
            setFromAddress(props.vaultId);
            console.log("Inside TEST DGDG", currentVaultNameReducer);
            setFromVaultLogo("https://res.cloudinary.com/kapow-tech/image/upload/v1655709677/exchange_logos/vault_ljiemj.jpg");
            // setFromVaultName(currentVaultNameReducer);
            // handleSingleAsset(json.data[0]);
        }
        console.log("set Core Assets", json.data);
        setCoreAssets(json.data);

        getCurrentVaults(json.data);
    }

    const notify = (place, msg) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    // async function getLocalAssetAddress () {
    //     const response = await fetch(
    //         `${config.api_url}/${config.v}/${config.endpoints.addresses}?limit=100`
    //     );
    //
    //     const json = await response.json();
    //     const ethJson = json.data.filter((elem) => ( ( elem.owner === config.wallet) && (elem.blockchain_id === 2 || elem.blockchain_id === 3)));
    //     console.log("getAddress ETH", ethJson);
    //     setETHAddress(ethJson[0].address);
    // }

    async function getETHGasData () {
        const response = await fetch(
            `${config.eth_gas_api}`
        );

        const gas_data = await response.json();
        console.log("ETH gas_data", gas_data.data);
        setETHGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getBTCGasData () {
        const response = await fetch(
            `${config.btc_gas_api}`
        );

        const gas_data = await response.json();
        console.log("BTC gas_data", gas_data.data);
        setBTCGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getKPWGasData () {
        const response = await fetch(
            `${config.kapow_gas_api}`
        );

        const gas_data = await response.json();
        console.log("KPW gas_data", gas_data.data);
        setKPWGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    async function getAssetList () {

        const [portfolioData, binanceAddressData, ftxAddressData] = await Promise.all([
            fetch(`${config.api_url}/${config.v}/${config.endpoints.portfolio}/${localStorage.getItem("ws_address")}`),
            // fetch(`${config.api_url}/${config.v}/${config.endpoints.accounts}/${config.endpoints.vaults}?address=${localStorage.getItem("ws_address")}&organisationHash=${localStorage.getItem("org_hash")}`),
            // fetch('http://127.0.0.1:8000/api/v1/binanceaddress'),
            // fetch('http://127.0.0.1:8000/api/v1/ftxaddress'),
        ]);

        const portfolio = await portfolioData.json();
        const assetList = [];
        assetList.push(portfolio);
        console.log("Portfolio Yo Yo ", portfolio);
        // assetList.push(binanceAddressList);
        // assetList.push(ftxAddressList);

        let useAbleList = [];
        useAbleList.unshift({
            value: "",
            label: "Choose an Asset",
            name: "Choose an Asset",
            isDisabled: true,
        });

        // console.log("Bew assetList", assetList);
        assetList.map((assetGroup, index) => {
            assetGroup.map((asset, innerIndex) => {
                useAbleList.push({
                    value: "",
                    label: asset.symbol,
                    name: asset.name,
                    logo: asset.logo,
                    id: asset.id
                })
            })
        });
        console.log("useAbleList", useAbleList);

        setAssetList(useAbleList);
        // setSingleAsset();
    }

    const updateExchangeMapping = (elem) => {
        const v_address = [];
        console.log("elem", elem);
        let filteredAddresses = [];
        exchangeMapping.map((assetAddressGroup, index) => {

            if(assetAddressGroup.kapow) {
                filteredAddresses.push(assetAddressGroup.kapow.filter((asset) => asset.name === elem.label));
            } else if(assetAddressGroup.binance) {
                filteredAddresses.push(assetAddressGroup.binance.filter((asset) => {
                    return asset.currency === elem.label
                }));
            } else if(assetAddressGroup.ftx) {
                filteredAddresses.push(assetAddressGroup.ftx.filter((asset) => {
                    return asset.currency === elem.label;
                }));
            }
            console.log("filteredAddresses", filteredAddresses);

            setFilteredAddresses(filteredAddresses);
        })
    };

    // XHR Request to send transaction to Blockchain
    async function  sendTransaction (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        const json = await response.json();

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            notify("tr", json.message);
            return;
        } else {
            toggleModalClassic3();
            openPop();
        }
        // refreshVaultList();
        await getPortfoliosAndCoinPrice();
        dispatch(getNonce());
        // console.log("successful transaction", json);
        // // store.dispatch(send({"hash": "0x" + json.hash}));
        // console.log("Transaction Message Dispatched");
        // setTxResponse(json);
    }

    // dedicated to the goddess Laxmi.
    function blackMagic () {

        // openPop();
        // window.setTimeout(() => {
        //     toggleModalClassic();
        // }, 500);
        console.log("Quantity", quantity);
        const tx = new VaultTransferTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            localStorage.getItem("org_hash"),
            vaultId,
            "",
            quantity,
            singleAsset,
            toAddress,
            fromAddress,
            "0x00",
            parseInt((singleAsset.id === 1) ? gasKPWData.limit : (singleAsset.id === 2 ? gasETHData.limit : (singleAsset.id === 3 ? gasBTCData.limit : gasETHData.limit))),
            txNote
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTransaction(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
        // let assetForTransaction = coreAssets.filter((elem) => elem.name === singleAsset.label)
        // console.log("assetForTransaction", assetForTransaction);
        // var tx = new MultiAccountTransferTransaction(nonce, config.hack.address, key_pair.publicKey, config.multi_account_address, quantity, assetForTransaction[0], receiver, config.multi_account_address);
        // tx.signTx(key_pair.secretKey);
        // const signedTx = tx.toBase64();
        //
        // toggleModalClassic();


        console.log("black magic Done");
    }

    const redeemTransaction = () => {

        const redeemTx = new PBMRedeemTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            "0.5",
            NER,
            "cTuaZoVwUg6KRwd32CRyETCYYR1xqwkTYbGxEB4xLUJFnXbk",
            "ZHuJXiLspnJdjnSRDCfatUZ18HXNkSimzLTcw9oAnNHhxs8s",
            "0x00",
            parseInt(gasETHData.limit),
            "testing 123, 321!"
        );

        redeemTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = redeemTx.toBase64();
        sendTransaction(signedTx, "Meal Tokens Disbursed!").then((res) => {
            console.log("response", res);
        });
    };

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Transaction Sent!',
            'success'
        )
    }

    async function getCurrentVaults (core_asset_list) {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${config.endpoints.vaults}?address=${localStorage.getItem("ws_address")}&organisationHash=${localStorage.getItem("org_hash")}&limit=100`
        );

        let vault_list = await response.json();
        console.log("vault Data Test ", vault_list.data, coreAssets);
        setCurrentVaults(vault_list.data);
        handleBalance(1, vault_list.data.filter((item) => item.vault_address === vaultId)[0].portfolio, "from", core_asset_list);
    }

    const handleBalance = (assetID, portfolio, direction, core_asset_list) => {
        console.log("Inside Handle Balance portfolio", assetID, portfolio, core_asset_list);
        const balance = Number(
            Quantity.hexToDecimal(
                core_asset_list.find(item => item.id === assetID),
                portfolio.find(item => item.id === assetID).confirmed_quantity)
        );

        if(direction === "from") {
            setFromBalance(balance)
        } else if(direction === "to") {
            setToBalance(balance)
        }
        console.log("current Asset Balance", balance);
    }

    const handleSingleAsset = (asset) => {
        console.log("Handle you", coreAssets);
        if (coreAssets) {
            setSingleAsset(coreAssets.find((currAsset) => currAsset.id === asset));
        } else {
            setSingleAsset(asset);
        }
    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Modal isOpen={modalClassic} toggle={toggleModalClassic3} style={{height: "250px"}} backdropClassName="what-the-sex" modalClassName="new-sex">
                <div className="modal-header justify-content-left">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalClassic3}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">New Transfer</h6>
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal transfer-modal">
                    <div>
                        <Col className="mb-4">
                            {/*<CardTitle tag="h4">Customisable Select</CardTitle>*/}
                            <Row>
                                <Col lg="12" md="6" sm="3" style={{padding: "0", maxWidth: "100%"}}>
                                    <FormGroup>
                                        <label>Asset</label>
                                        {
                                            assetList && assetList.length > 0 &&
                                            currentAssetId &&
                                            <Select
                                                style={{color: "#fff"}}
                                                className="react-select info"
                                                classNamePrefix="react-select"
                                                name="singleAsset"
                                                value={ singleAsset }
                                                onChange={(value) => {
                                                    console.log("value", value);
                                                    let assetForTransaction = coreAssets.filter((elem) => elem.id === value.id);
                                                    console.log("assetForTransaction", assetForTransaction[0]);
                                                    handleSingleAsset(assetForTransaction[0]);
                                                    dispatch(setCurrentAssetId(assetForTransaction[0].id));
                                                    // updateExchangeMapping(value);
                                                }}
                                                getOptionLabel={value => (
                                                <div>
                                                    {
                                                        value.logo
                                                            ?
                                                            <img alt="..." src={value.logo} className="asset-logo-icon" />
                                                            :
                                                            <span className="asset-logo-icon">&nbsp;</span>
                                                    }
                                                <span>{value.name}</span>
                                                </div>
                                                )}
                                                options={assetList}
                                                placeholder="Choose Asset"
                                            />
                                        }
                                        {/*{*/}
                                        {/*    gasData && gasData.price &&*/}
                                        {/*    <sub>Gas Price: {parseInt(gasData.price, 16)*Math.pow(10, -18)} eth</sub>*/}
                                        {/*}*/}
                                    </FormGroup>

                                    <FormGroup autocomplete="off">
                                        <label>From Vault</label>
                                        <div className="selected-vault">
                                            <Input
                                                type="text"
                                                value={fromVaultName}
                                                name="foo"
                                                autocomplete="off"
                                                placeholder="Source Vault Account or Address"
                                                onChange={(e) => {
                                                    console.log("from address Input value",  e.target.value);
                                                    setFromVaultLogo("");
                                                    setFromAddress(e.target.value);
                                                    setFromVaultName(e.target.value);
                                                    setFromVaultListState(false);
                                                }}
                                                onBlur={(e) => {
                                                    setTimeout(() => {
                                                        setFromVaultListState(false);
                                                    }, 200);
                                                }}
                                                onClick={() => {
                                                    setFromVaultListState(!showFromVaultList);
                                                }}
                                            />

                                            {
                                                fromVaultLogo &&
                                                <img src={fromVaultLogo} alt="" />
                                            }
                                            <div className="balance-details">
                                                <label>Vault Balance: </label>
                                                <span>{fromBalance}</span>
                                            </div>
                                        </div>
                                        <div className={showFromVaultList ? "source-selectors show" : "source-selectors"}>
                                            <div className="tab-menu">
                                                <ul>
                                                    {
                                                        tabMenuItems.map((elem, index) => <li
                                                            key={index}
                                                            className={tabIndex === (index + 1) ? "active text-center" : "text-center"}
                                                            onClick={(e) => setTabIndex(index + 1)}>
                                                            {elem}
                                                        </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            <div className="tab-body">
                                                <ul className={tabIndex === 2 ? "active" : ""}>
                                                    <li>Ha Na!</li>
                                                    {/*{*/}
                                                    {/*    exchange_accounts.map((elem, index) => <li*/}
                                                    {/*        key={index}*/}
                                                    {/*        onClick={() => {*/}
                                                    {/*            setFromAddress(filtered_addresses[index][0].address);*/}
                                                    {/*            setFromVaultLogo(exchange_vault_logo[index]);*/}
                                                    {/*            setFromVaultListState(!showFromVaultList);*/}
                                                    {/*        }}>*/}
                                                    {/*            {*/}
                                                    {/*                elem === "Core Vaults" &&*/}
                                                    {/*                vault_list && vault_list.length > 0 &&*/}
                                                    {/*                vault_list.map((vault, index) => (<div key={vault.vault_address}>*/}
                                                    {/*                    <img src={require("assets/img/vault-transparent.png").default} alt=""/>*/}
                                                    {/*                    <span>{vault.vault_name}</span>*/}
                                                    {/*                </div>))*/}
                                                    {/*            }*/}
                                                    {/*            {*/}
                                                    {/*                elem === "Binance Wallet" &&*/}
                                                    {/*                <div>*/}
                                                    {/*                    <img src={require("assets/img/binance_logo.jpeg").default} alt=""/>*/}
                                                    {/*                    <span>{elem}</span>*/}
                                                    {/*                </div>*/}
                                                    {/*            }*/}
                                                    {/*            {*/}
                                                    {/*                elem === "FTX" &&*/}
                                                    {/*                <div>*/}
                                                    {/*                    <img src={require("assets/img/ftx_exchange.png").default} alt=""/>*/}
                                                    {/*                    <span>{elem}</span>*/}
                                                    {/*                </div>*/}
                                                    {/*            }*/}
                                                    {/*        </li>*/}
                                                    {/*    )*/}
                                                    {/*}*/}
                                                </ul>

                                                <ul className={tabIndex === 1 ? "active" : ""}>
                                                    {
                                                        vault_list && vault_list.length > 0 &&
                                                        vault_list.map((vault, index) => (<li
                                                            key={vault.vault_address}
                                                            onClick={() => {
                                                                let fromAssetAddress = "";
                                                                vault.portfolio.map((asset, index) => {
                                                                    if (asset.id === singleAsset.id) {
                                                                        fromAssetAddress = asset.address;
                                                                        handleBalance(asset.id, vault.portfolio, "from", coreAssets);
                                                                    }
                                                                });
                                                                console.log("From Asset Address", fromAssetAddress);
                                                                setFromVaultName(vault.vault_name);
                                                                setFromAddress(fromAssetAddress);
                                                                setFromVaultLogo("https://res.cloudinary.com/kapow-tech/image/upload/v1655709677/exchange_logos/vault_ljiemj.jpg");
                                                                setFromVaultListState(!showFromVaultList);
                                                            }}>{vault.vault_name}</li>))
                                                    }
                                                </ul>
                                                {/*<ul className={tabIndex === 2 ? "active" : ""}>*/}
                                                {/*    {*/}
                                                {/*        vaultPortfolioList && vaultPortfolioList.length > 0 &&*/}
                                                {/*        vaultPortfolioList.map(() => (<li onClick={() => {*/}
                                                {/*            setFromAddress(filtered_addresses[0][0].address);*/}
                                                {/*            setFromVaultLogo("https://res.cloudinary.com/kapow-tech/image/upload/v1655709677/exchange_logos/vault_ljiemj.jpg");*/}
                                                {/*            setFromVaultListState(!setFromVaultListState);*/}
                                                {/*        }}>*/}
                                                {/*            <img src={require("assets/img/vault-transparent.png").default} alt=""/>*/}
                                                {/*            <span>TrustLink Vault</span>*/}
                                                {/*        </li>))*/}
                                                {/*    }*/}
                                                {/*</ul>*/}

                                                <ul className={tabIndex === 3 ? "active" : ""}>
                                                    <li onClick={() => {
                                                        setFromAddress(filtered_addresses[1][0].address);
                                                        setFromVaultLogo("https://res.cloudinary.com/kapow-tech/image/upload/v1655709670/exchange_logos/binance_logo_msft6o.jpg");
                                                        setFromVaultListState(!showFromVaultList);
                                                    }}>
                                                        <img src={require("assets/img/binance_logo.jpeg").default} alt=""/>
                                                        <span>Binance Wallet</span>
                                                    </li>
                                                    <li onClick={() => {
                                                        setFromAddress(filtered_addresses[2][0].address);
                                                        setFromVaultLogo("https://res.cloudinary.com/kapow-tech/image/upload/v1655709670/exchange_logos/ftx_exchange_rligay.png");
                                                        setFromVaultListState(!showFromVaultList);
                                                    }}>
                                                        <img src={require("assets/img/ftx_exchange.png").default} alt=""/>
                                                        <span>FTX</span>
                                                    </li>
                                                </ul>

                                                <ul className={tabIndex === 4 ? "active" : ""}>
                                                    <li>Silvergate</li>
                                                    <li>Transak</li>
                                                </ul>

                                                <ul className={tabIndex === 5 ? "active" : ""}>
                                                    <li>JP Morgan Vault</li>
                                                    <li>Credit Suisse Vault</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </FormGroup>

                                    <FormGroup autocomplete="off">
                                        <label>To</label>
                                        <div className="selected-vault">
                                            <Input
                                                type="text"
                                                value={ toVaultName }
                                                name="foo2"
                                                autocomplete="off"
                                                placeholder="Destination Account or Address"
                                                onChange={(e) => {
                                                    setToAddress(e.target.value);
                                                    setToVaultName(e.target.value);
                                                    setToVaultLogo("");
                                                    setToVaultListState(false);
                                                }}
                                                onClick={() => setToVaultListState(!showFromVaultList)}
                                                onBlur={(e) => {
                                                    setTimeout(() => {
                                                        setToVaultListState(false);
                                                    }, 200);
                                                }}
                                            />
                                            {
                                                toVaultLogo &&
                                                <img  src={toVaultLogo} alt=""/>
                                            }
                                            <div className="balance-details">
                                                <label>Vault Balance: </label>
                                                <span>{toBalance}</span>
                                            </div>
                                        </div>


                                        <div className={showToVaultList ? "source-selectors from-address show" : "source-selectors from-address"}>
                                            <div className="tab-menu">
                                                <ul>
                                                    {
                                                        tabMenuItems.map((elem, index) => <li
                                                            key={index}
                                                            className={tabIndex === (index + 1) ? "active" : ""}
                                                            onClick={(e) => setTabIndex(index + 1)}>
                                                            {elem}
                                                        </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            <div className="tab-body">
                                                <ul className={tabIndex === 2 ? "active" : ""}>
                                                    {
                                                        exchange_accounts.map((elem, index) => <li
                                                            key={index}
                                                                onClick={() => {
                                                                    setToAddress(filtered_addresses[index][0].address);
                                                                    setToVaultLogo(exchange_vault_logo[index]);
                                                                    setToVaultListState(!showToVaultList);
                                                                }}>
                                                                {elem}
                                                            </li>
                                                        )
                                                    }
                                                </ul>

                                                <ul className={tabIndex === 1 ? "active" : ""}>
                                                    {
                                                        vault_list && vault_list.length > 0 &&
                                                        vault_list.map((vault) => (<li
                                                            key={vault.vault_address}
                                                            onClick={() => {
                                                            let toAssetAddress = "";
                                                            vault.portfolio.map((asset) => {
                                                                if (asset.id === singleAsset.id) {
                                                                    toAssetAddress = asset.address;
                                                                    handleBalance(asset.id, vault.portfolio, "to", coreAssets);
                                                                }
                                                            });
                                                            console.log("TO Asset Address", toAssetAddress);
                                                            setToVaultName(vault.vault_name);
                                                            setToAddress(toAssetAddress);
                                                            setToVaultLogo("https://res.cloudinary.com/kapow-tech/image/upload/v1655709677/exchange_logos/vault_ljiemj.jpg");
                                                            setToVaultListState(!showToVaultList);
                                                        }}>{vault.vault_name}</li>))
                                                    }
                                                </ul>

                                                <ul className={tabIndex === 3 ? "active" : ""}>
                                                    <li onClick={() => {
                                                        setToAddress(filtered_addresses[1][0].address);
                                                        setToVaultLogo("https://res.cloudinary.com/kapow-tech/image/upload/v1655709670/exchange_logos/binance_logo_msft6o.jpg");
                                                        setToVaultListState(!showToVaultList);
                                                    }}>Binance Wallet</li>
                                                    <li onClick={() => {
                                                        setToAddress(filtered_addresses[2][0].address);
                                                        setToVaultLogo("https://res.cloudinary.com/kapow-tech/image/upload/v1655709670/exchange_logos/ftx_exchange_rligay.png");
                                                        setToVaultListState(!showToVaultList);
                                                    }}>FTX</li>
                                                </ul>

                                                <ul className={tabIndex === 4 ? "active" : ""}>
                                                    <li>Silvergate</li>
                                                    <li>Transak</li>
                                                </ul>

                                                <ul className={tabIndex === 5 ? "active" : ""}>
                                                    <li onClick={() => {
                                                        setToAddress("h4pcTZApMQqsFWwqWDpNycZzEKu8fyQWrT7HgCimy7fC56iX");
                                                        setToVaultListState(!showToVaultList);
                                                    }}>PB Neeraj - JP Morgan</li>
                                                    <li>Credit Suisse Vault</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <label>Amount</label>
                                        <Input
                                            name="rule"
                                            type="text"
                                            value={quantity}
                                            onChange={(e) => setQuantity(e.target.value)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="exampleText">Add a Note:</Label>
                                        <Input type="textarea" name="text" id="exampleText" value={txNote} onChange={(e) => setTxNote(e.target.value)} />
                                    </FormGroup>

                                    <Button color="info" onClick={blackMagic} className="tx-send-btn">
                                        Send Transaction
                                    </Button>

                                    {/*<button onClick={successAlert}>success alert</button>*/}

                                </Col>
                            </Row>
                        </Col>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default TransferModal;
