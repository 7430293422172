import { Base64 } from "../../base64";

export class AcceptInvoiceData {
  invoiceHash: string;
  invoiceDiscountingProposalHash: string;
  accept: boolean;
  


  constructor(invoiceHash: string, invoiceDiscountingProposalHash: string, accept: boolean) {
    this.invoiceHash = invoiceHash;
    this.invoiceDiscountingProposalHash = invoiceDiscountingProposalHash;
    this.accept = accept;
  }

  public static fromJson(json: any): AcceptInvoiceData {
    return new AcceptInvoiceData(
      json["invoice_hash"],
      json["invoice_discounting_proposal_hash"],
      json["accept"],
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "invoice_hash": this.invoiceHash,
      "invoice_discounting_proposal_hash": this.invoiceDiscountingProposalHash,
      "accept": this.accept,
    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
