import { Link } from "react-router-dom";
import { tx_status, tx_types } from "../../constants";
import LongHash from "../../components/LongHash/LongHash";
// reactstrap components

const TransactionData = (props) => {

  return (
    <div className={`txData t-${props.status}`} key={props.index + 1}>
      {/*<div className="roger-stone">*/}
      {/*  { props.index + 1 }*/}
      {/*</div>*/}
      {/*<div className="tx-connector">&nbsp;</div>*/}
      <div className="tx-cards">
        {
          props.data &&
            props.data.from &&
            props.data.to &&
            <div className="logs_to_from">
              <div>
                <span>From</span>
                <div><LongHash hash={props.data.from} /></div>
              </div>
              <div>
                <span>To</span>
                <div><LongHash hash={props.data.to} /></div>
              </div>
            </div>
        }

        <ul>
          <li>
            <span className="tx-label">Tx Hash</span>
            <LongHash hash={ props.txHash } />
            {/*<a className="hash-link" href="https://www.block-explorer.com">*/}
            {/*  <LongHash hash={ props.txHash } />*/}
            {/*</a>*/}
          </li>
          {/*<li>*/}
          {/*  <span>Block Hash:</span>*/}
          {/*  <span>{props.blockHash}</span>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <span>Block Height:</span>*/}
          {/*  <Link to={`/block/${props.height}`}>*/}
          {/*    {props.height}*/}
          {/*  </Link>*/}
          {/*  /!*<span>{props.height}</span>*!/*/}
          {/*</li>*/}
          <li>
            <span className="tx-label">Sender:</span>
            {/*<Link className="hash-link" to={`/address/${props.sender}`}>*/}
            {/*  <LongHash hash={ props.sender } />*/}
            {/*</Link>*/}
            <LongHash hash={ props.sender } />
          </li>
          {
            props.receiver &&
              <li>
                <span>Receiver:</span>
                <Link to={`/address/${props.receiver}`}>
                  {props.receiver}
                </Link>
              </li>
          }
          <li>
            <span className="tx-label">Type:</span>
            <span className="status-type">
              {props.type} - { tx_types[props.type] }
            </span>
          </li>
        </ul>
        <div className="date-status date-status-outside">
          <div className="date-status-inside">
            <span className="status-states tx-label">Status:</span>
            <span className={`status-${props.status}`}>{tx_status[props.status]}</span>
          </div>
          <div className="date-status">
            {/*<ReactTimeAgo date={new Date(props.timeStamp * 1000)} locale="en-US"/>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionData;
