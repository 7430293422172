import React, { useState, useEffect, useRef } from "react";
import {Link, Route, Switch, useParams, useRouteMatch, useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    FormText,
} from "reactstrap";
import {
    AddOrganisationMember,
    Hash,
    Conversion,
    UpdateOrganisationMemberProfileTransaction,
    OrganisationMemberPermissionAddTransaction,
    OrganisationMemberPermissionRemoveTransaction,
    OrganisationMemberPermissionEditTransaction
} from "kapow-sdk";
import cryptoRandomString from 'randomstring';
import "./memberPermission.scss";

import {config} from "../config";
import { setCurrentMemberView } from "./../actionCreators/setCurrentView";
import { getNonce } from "../reducers/currentNonce";
import NotificationAlert from "react-notification-alert";
import Swal from "sweetalert2";

const MemberPermissions = () => {
    const [memberName, setMemberName] = useState("");
    const [memberEmail, setMemberEmail] = useState("");
    const [memberDetails, setMemberDetails] = useState({});
    const [orgState, setOrgState] = useState("");
    const [vaultState, setVaultState] = useState("admin");

    const [lendingState, setLendingState] = useState("admin");
    const [permissionList, setPermissionList] = useState([]);
    const [originalPermissionList, setOriginalPermissionList] = useState([]);
    const [lendingTransfer, setLendingTransfer] = useState(false);
    const [lendingWithdraw, setLendingWithdraw] = useState(false);
    const [randomString, setRandomString] = useState("");
    const [vaultList, setVaultList] = useState([]);
    const [vaultAccessList, setVaultAccessList] = useState([]);
    const [existingVaultAccessList, setExistingVaultAccessList] = useState([]);
    const [vaultAccessPermissions, setVaultAccessPermissions] = useState("");
    const [redirect, setRedirect] = useState(false);
    const nonce = useSelector(state => state.currentNonce);


    let { id } = useParams();
    const dispatch = useDispatch();
    const notificationAlertRef = useRef(null);

    useEffect(() => {
        (async () => {
            dispatch(getNonce());
            await getCurrentVaults();
            if(id !== "new") {
                await getMemberDetails(id);
            }
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const isFormReadyToSubmit = (pass = true) => {
        if (pass && memberName === "") {
            notify("tr", "Please enter your Team Member's name");
            return false;
        } else if (pass && memberEmail === "") {
            notify("tr", "Please enter the Team Member's email");
            return false;
        } else if (permissionList.length === 0) {
            notify("tr", "Please Assign at least one permission to the Team Member");
            return false;
        } else if (vaultAccessList.length === 0) {
            notify("tr", "Please Give the Team Member Access to at least one vault");
            return false;
        } else {
            return true;
        }
    }

    const openPop = (message) => {
        Swal.fire(
            'Success!',
            message,
            'success'
        )
    }


    const notify = (place, msg) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    async function getMemberDetails (id) {
        const response = await fetch(
            `${config.api_url}/${config.v}/organisation-members/member?orgHash=${localStorage.getItem("org_hash")}&memAddress=${id}`
        );

        const memberDetails = await response.json();
        const memberPermissionArray = memberDetails.permissions.split(",").filter((elem) => elem !== "");
        console.log("memberPermissionArray", memberPermissionArray);
        setOriginalPermissionList(memberPermissionArray);
        setPermissionList(memberPermissionArray);

        console.log("memberDetails",  memberDetails);
        console.log("memberPermissionArray", memberPermissionArray.indexOf("1512"));
        setMemberName(memberDetails.member_name);
        setMemberEmail(memberDetails.member_email);

        const member_current_vaults_access = [];
        memberDetails.vaults.map((vault) => member_current_vaults_access.push(vault.vault_address));
        setVaultAccessList(member_current_vaults_access);
        setExistingVaultAccessList(member_current_vaults_access);
    }

    async function getCurrentVaults () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.organisation}/${localStorage.getItem("org_hash")}/${config.endpoints.vaults}`
        );

        let vault_list = await response.json();
        console.log("Vault Data => ", vault_list.data);
        if(vault_list.data && vault_list.data.length > 0) {
            setVaultList(vault_list.data);
        }
    }

    const orgPermissions = (event)=>{
        console.log("Org Perm Values", event.target.id);
        // setOrgState(event.target.value);
        const orgPermArr = event.target.id.split(",");
        const checkOrgPerms = [...permissionList];
        console.log("orgPermArr", orgPermArr);

        if (permissionList.filter(elem => orgPermArr.includes(elem)).length > 0) {
            console.log("Org Perm Removal Shastra");
            permissionList.map((perm) => {
                console.log("Inside Before Splicing", perm, orgPermArr.indexOf(perm));
                if(orgPermArr.indexOf(perm) > -1) {
                    checkOrgPerms.splice(checkOrgPerms.indexOf(perm), 1);
                }
                return perm;
            });
            console.log("permissionList", checkOrgPerms);
            setPermissionList(checkOrgPerms);
        } else {
            console.log("Org Perm Adding Shastra");
            console.log("permissionList", [...permissionList, ...orgPermArr]);
            setPermissionList([...permissionList, ...orgPermArr]);
        }
    }

    const viewPermissions = (event) => {
        const removePermissions = [];
        console.log("originalPermissionList", originalPermissionList);
        // const viewPermissions = originalPermissionList.splice("1503");
        //     .filter((permission) => {
        //     console.log("permission", permission);
        //    return  (permission === "1503");
        // });
        console.log("viewPermissions", viewPermissions);
        setPermissionList(viewPermissions);
    }

    const txPermissions = (event)=>{

        console.log("TX Rules Values", event.target.id);
        // setOrgState(event.target.value);
        const rulesPermArr = event.target.id.split(",");
        const txRulePerms = [...permissionList];
        console.log("rulesPermArr", rulesPermArr);

        if (permissionList.filter(elem => rulesPermArr.includes(elem)).length > 0) {
            console.log("Removal Shastra");
            permissionList.map((perm, index) => {
                console.log("Inside Before Splicing", perm, rulesPermArr.indexOf(perm));
                console.log("index", index);
                if(rulesPermArr.indexOf(perm) > -1) {
                    txRulePerms.splice(txRulePerms.indexOf(perm), 1);
                }
                return perm;
            });
            console.log("permissionList", txRulePerms);
            setPermissionList(txRulePerms);
        } else {
            console.log("Adding Shastra");
            console.log("TX PER permissionList", [...permissionList, ...rulesPermArr]);
            setPermissionList([...permissionList, ...rulesPermArr]);
        }
    }

    const lendingPermissions = (event)=>{
        console.log("Lending Rules Values", event.target.id);
        // setOrgState(event.target.value);
        const lendingPermArr = event.target.id.split(",");
        const lendingRulePerms = [...permissionList];
        console.log("rulesPermArr", lendingPermArr);

        if (permissionList.filter(elem => lendingPermArr.includes(elem)).length > 0) {
            console.log("Removal Lending Perm Shastra");
            permissionList.map((perm, index) => {
                if(lendingPermArr.indexOf(perm) > -1) {
                    lendingRulePerms.splice(lendingRulePerms.indexOf(perm), 1);
                }
                return perm;
            });
            console.log("Inside Lending permissionList", lendingRulePerms);
            setPermissionList(lendingRulePerms);
        } else {
            console.log("Adding Lending Perm Shastra");
            console.log("Lending PER permissionList", [...permissionList, ...lendingPermArr]);
            setPermissionList([...permissionList, ...lendingPermArr]);
        }
        setLendingState(event.target.value);
    }

    const handleLendingTransferPermissions = (event)=>{
        const permissionCheck = permissionList.indexOf(event.target.id);
        console.log("permissionCheck", permissionCheck);
        console.log("event", event.target.id);
        let newPermissionList;
        if (permissionCheck > -1) {
            newPermissionList = permissionList.filter((str) => str !== event.target.id);
        } else {
            newPermissionList = [...permissionList, event.target.id];
        }
        console.log("permissionList", permissionList);
        console.log("Previous Lending Permissions", lendingWithdraw);
        setLendingTransfer(!lendingTransfer);
        setPermissionList(newPermissionList);
    }

    const handleLendingWithdrawPermissions = (event)=>{
        const permissionCheck = permissionList.indexOf(event.target.id);
        console.log("permissionCheck", permissionCheck);
        console.log("event", event.target.id);
        let newPermissionList;
        if (permissionCheck > -1) {
            newPermissionList = permissionList.filter((str) => str !== event.target.id);
        } else {
            newPermissionList = [...permissionList, event.target.id];
        }
        console.log("Previous Deposit Permissions", lendingTransfer);
        setLendingWithdraw(!lendingWithdraw);
        setPermissionList(newPermissionList);
    }

    const handleVaultAccess = (event) => {
        let selectedVaults = [...vaultAccessList, event.target.id];
        if (vaultAccessList.includes(event.target.id)) {
            selectedVaults = selectedVaults.filter(vaultId => vaultId !== event.target.id);
        }
        console.log("selectedVaults", selectedVaults);
        setVaultAccessList(selectedVaults);
    }

    const handleVaultPermissions = (event) => {
        const permissionCheck = permissionList.indexOf(event.target.id);
        console.log("permissionCheck", permissionCheck);
        console.log("event", event.target.id);
        let newPermissionList;
        if (permissionCheck > -1) {
            newPermissionList = permissionList.filter((str) => str !== event.target.id);
        } else {
            newPermissionList = [...permissionList, event.target.id];
        }
        console.log("permissionList", newPermissionList);
        setPermissionList(newPermissionList);
    }

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx, msg, code) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        console.log("HERE Member Added Success");

        const json = await response.json();
        if (!response.ok) {
            console.log("YO YO MA TEST NOT OKAY!", json);
            notify("tr", json.message);
            return;
        } else {
            console.log("WHERE Member Added Success", json);
            // dispatch(setCurrentMemberView("member-list"));
            openPop(msg);
            postQRCode(code).then((res) => {
                console.log("response of PostQRCode", res);
            });
            // if (type === "add") {
            //
            // }
            // history.push("/admin/console");

        }

        console.log("Member Added Success", json);
    }

    async function  postQRCode (qrCode) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "intent": "addOrganisationMember",
                "to": memberEmail,
                "data" : {
                    "qr": qrCode
                }
            })
        }
        const response = await fetch(
            `https://weblogin.devnet.kapow.tech/v1/addOrganisationUser`, settings
        );

        const json = await response.json();
        console.log("Member Added Success", json);
        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            notify("tr", json.message);
        } else {
            // notify("successful transaction");
            // dispatch(setCurrentMemberView("member-list"));
            setRedirect(true);
            // if (type === "add") {
            //
            // }
            // history.push("/admin/console");
        }
    }

    // Updates Member Email & Name.
    const updateMemberProfile = () => {
        if (isFormReadyToSubmit(false) === false) {
            return false;
        }
        updateMemberPermissions();
        return;
        // const tx = new UpdateOrganisationMemberProfileTransaction(
        //     nonce,
        //     localStorage.getItem("ws_address"),
        //     Buffer.from(localStorage.getItem("session_pub_key"), "hex"),
        //     localStorage.getItem("org_hash"),
        //     "",
        //     "",
        //     id,
        //     memberName,
        //     memberEmail
        // )
        //
        // tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        // const signedTx = tx.toBase64();
        // sendTx(signedTx, "Member Profile Updated").then((res) => {
        //     console.log("Member Permission Updated", res);
        // });
    }

    // Add/Remove Member Permissions
    const updateMemberPermissions = () => {
        const filteredPermissions = permissionList.filter((item, index) => permissionList.indexOf(item) === index);
        // const filteredPermissions = ["1518", "1519"];
        if(vaultAccessList.length > 0 && filteredPermissions.indexOf("1509") === -1){
            filteredPermissions.push("1509");
        }

        if(vaultAccessList.length > 0 && filteredPermissions.indexOf("1537") === -1){
            filteredPermissions.push("1537");
            filteredPermissions.push("1538");
            filteredPermissions.push("1539");
            filteredPermissions.push("1540");
        }

        console.log("filteredPermissions", filteredPermissions.filter((item)=>item !== ""));
        const tx = new OrganisationMemberPermissionEditTransaction(
           nonce,
           localStorage.getItem("ws_address"),
           Buffer.from(localStorage.getItem("session_pub_key"), "hex"),
           localStorage.getItem("org_hash"),
           "",
           "",
           id,
           filteredPermissions.filter((item)=>item !== "").map((item) => parseInt(item)),
            vaultAccessList
       );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();
        sendTx(signedTx, "Member Permissions Updated")
        .then((res) => {
            console.log("Member Permission Updated", res);
        });

    }

    const updateMemberVaults = () => {

    }

    const createMember = ()=>{
        if (isFormReadyToSubmit() === false) {
            return false;
        }

        const randomString = cryptoRandomString.generate();
        const secretHash = Hash.sha256String(randomString);
        const dataHash =  Hash.sha256String(Hash.sha256String(memberEmail) + secretHash);
        const inviteHash = Conversion.bufferToHex(Hash.sha256String(Conversion.bufferToHex(dataHash) + Conversion.bufferToHex(secretHash)));
        const filteredPermissions = permissionList.filter((item, index) => permissionList.indexOf(item) === index);
        // const filteredPermissions = ["1518", "1519"];
        if(vaultAccessList.length > 0 && filteredPermissions.indexOf("1509") === -1){
            filteredPermissions.push("1509");
        }

        if(vaultAccessList.length > 0 && filteredPermissions.indexOf("1538") === -1){
            filteredPermissions.push("1537");
            filteredPermissions.push("1538");
            filteredPermissions.push("1539");
            filteredPermissions.push("1540");
        }
        const confirm_code = `confirm_${Conversion.bufferToHex(dataHash)}_${Conversion.bufferToHex(secretHash)}`;
        console.log("filteredPermissions", filteredPermissions);

        console.log("Invite Hash", `confirm_${Conversion.bufferToHex(dataHash)}_${Conversion.bufferToHex(secretHash)}`);
        console.log("add member data", nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            localStorage.getItem("org_hash"),
            "",
            "",
            7802,
            inviteHash,
            filteredPermissions.toString(),
            vaultAccessList.toString()
        )
        const tx = new AddOrganisationMember(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8,
            localStorage.getItem("org_hash"),
            "",
            "",
            memberName,
            memberEmail,
            // 7801,
            7802,
            inviteHash,
            filteredPermissions.toString(),
            vaultAccessList.toString(),
            "",
        );
        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx, "Member Invited", confirm_code).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });

        // postQRCode(confirm_code).then((res) => {
        //     console.log("response of PostQRCode", res);
        // });
    };

    const handleDefaultChecks = (opList, perm) => {
        console.log("opList", opList.length, perm);
        return opList.indexOf(perm) > -1;
    }

    const isEditableVault = (vault_address) => {
        if ((id !== "new") && (existingVaultAccessList.indexOf(vault_address) > -1)) {
            console.log("Inside", vault_address, existingVaultAccessList.indexOf(vault_address));
            return true
        }
    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <div className="content member-permissions">
                <Row>
                    <Col className="text-center" lg="12" sm="12">
                        <Card className="card-tasks text-left">
                            <CardHeader>
                                <h4 className="title d-inline">
                                    {
                                        id === "new" ? "Add a New Member" : "Member Details & Permissions"
                                    }
                                </h4>
                            </CardHeader>
                            {
                                <CardBody>
                                        <FormGroup>
                                            <label>Member Name</label>
                                            <Input
                                                name="rule"
                                                id="rule_name"
                                                type="text"
                                                value={memberName}
                                                placeholder="Enter team Members Name"
                                                onChange={(e) => setMemberName(e.target.value)}
                                                disabled={ id !== "new" }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Invitation Email</label>
                                            <Input
                                                name="rule"
                                                id="rule_name"
                                                type="text"
                                                value={memberEmail}
                                                placeholder="Enter the Email you want to send Invite to"
                                                onChange={(e) => setMemberEmail(e.target.value)}
                                                disabled={ id !== "new" }
                                            />
                                            <FormText color="muted">
                                                We'll never share your email with anyone else.
                                            </FormText>
                                        </FormGroup>
                                        <h4 className="title perm-header">Permission Checklist</h4>
                                        <div className="permission-checklist check-1">
                                            <Row className="permission-section">
                                                <Label sm="2">Team Management</Label>
                                                <Col className="checkbox-radios" sm="10">
                                                    <FormGroup check>
                                                        <Label check>
                                                            {
                                                                (
                                                                    (id !== "new" &&  originalPermissionList && originalPermissionList.length !== 0)
                                                                    ||
                                                                    (id === "new")
                                                                ) &&
                                                                <Input
                                                                    defaultChecked={ ["1503", "1505", "1506", "1507", "1508", "1512", "1523", "1534"].every(i => originalPermissionList.includes(i)) }
                                                                    // defaultChecked={ originalPermissionList.indexOf("1503") > -1 }
                                                                    type="checkbox"
                                                                    id="1503,1505,1506,1507,1508,1512,1523,1534"
                                                                    onChange={ orgPermissions }
                                                                />
                                                            }
                                                            <span className="form-check-sign" />
                                                            Admin Access
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="permission-section">
                                                <Label sm="2">Vault Access</Label>
                                                <Col className="checkbox-radios" sm="10">
                                                    {
                                                        <div>
                                                            <div className="additional-permission choose-vault">
                                                                <h5>Give Access to Specific Vaults</h5>
                                                                <div>
                                                                    {
                                                                        (
                                                                            (id !== "new" && vaultAccessList && vaultAccessList.length > 0)
                                                                            ||
                                                                            (id === "new")
                                                                        ) &&
                                                                        vaultList && vaultList.length > 0 &&
                                                                        vaultList.map((vault, index) => (
                                                                            <FormGroup check >
                                                                                <Label
                                                                                    check
                                                                                    className={ isEditableVault(vault.vault_address) ? "lock" : "unlock" }
                                                                                >
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        id={ vault.vault_address}
                                                                                        onChange={ handleVaultAccess }
                                                                                        defaultChecked={ vaultAccessList.indexOf(vault.vault_address) > -1 }
                                                                                        disabled={ isEditableVault(vault.vault_address) }
                                                                                    />
                                                                                    <span className="form-check-sign" />
                                                                                    {vault.vault_name}
                                                                                    {
                                                                                        isEditableVault(vault.vault_address) &&
                                                                                        <i className="tim-icons icon-lock-circle" id={ `tootltip${vault.vault_address}` } />
                                                                                    }
                                                                                </Label>
                                                                                {/*<UncontrolledTooltip*/}
                                                                                {/*    delay={0}*/}
                                                                                {/*    target={ `tootltip${vault.vault_address}` }*/}
                                                                                {/*>*/}
                                                                                {/*    Locked by Admin*/}
                                                                                {/*</UncontrolledTooltip>*/}
                                                                            </FormGroup>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="additional-permission">
                                                                <h5>Additional Vault Permissions</h5>
                                                                {/*<FormGroup check>*/}
                                                                {/*    <Label check>*/}
                                                                {/*        {*/}
                                                                {/*            (*/}
                                                                {/*                (id !== "new" &&  originalPermissionList && originalPermissionList.length !== 0)*/}
                                                                {/*                ||*/}
                                                                {/*                (id === "new")*/}
                                                                {/*            ) &&*/}
                                                                {/*            <Input*/}
                                                                {/*                defaultChecked={ originalPermissionList.indexOf("1512") > -1 }*/}
                                                                {/*                type="checkbox"*/}
                                                                {/*                id={"1512"}*/}
                                                                {/*                onChange={ handleVaultPermissions }*/}
                                                                {/*            />*/}
                                                                {/*        }*/}
                                                                {/*        <span className="form-check-sign" />*/}
                                                                {/*        Deposit*/}
                                                                {/*    </Label>*/}
                                                                {/*</FormGroup>*/}
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        {
                                                                            (
                                                                                (id !== "new" &&  originalPermissionList && originalPermissionList.length !== 0)
                                                                                ||
                                                                                (id === "new")
                                                                            ) &&
                                                                            <Input
                                                                                type="checkbox"
                                                                                id={"1513"}
                                                                                onChange={handleVaultPermissions}
                                                                                defaultChecked={originalPermissionList.indexOf("1513") > -1}
                                                                            />
                                                                        }
                                                                        <span className="form-check-sign" />
                                                                        Cross Vault Transfers
                                                                    </Label>
                                                                </FormGroup>
                                                                {/*<FormGroup check>*/}
                                                                {/*    <Label check>*/}
                                                                {/*        {*/}
                                                                {/*            (*/}
                                                                {/*                (id !== "new" &&  originalPermissionList && originalPermissionList.length !== 0)*/}
                                                                {/*                ||*/}
                                                                {/*                (id === "new")*/}
                                                                {/*            ) &&*/}
                                                                {/*            <Input*/}
                                                                {/*                type="checkbox"*/}
                                                                {/*                id={ "1035" }*/}
                                                                {/*                onChange={ handleVaultPermissions }*/}
                                                                {/*                defaultChecked={ originalPermissionList.indexOf("1035") > -1}*/}
                                                                {/*            />*/}
                                                                {/*        }*/}
                                                                {/*        <span className="form-check-sign" />*/}
                                                                {/*        Outgoing Transfers*/}
                                                                {/*    </Label>*/}
                                                                {/*</FormGroup>*/}
                                                            </div>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            <Row className="permission-section">
                                                <Label sm="2">Transaction Rules</Label>
                                                <Col className="checkbox-radios" sm="10">
                                                    <FormGroup check>
                                                        <Label check>
                                                            {
                                                                (
                                                                    (id !== "new" &&  originalPermissionList && originalPermissionList.length !== 0)
                                                                    ||
                                                                    (id === "new")
                                                                ) &&
                                                                <Input
                                                                    defaultChecked={originalPermissionList.includes("1520")}
                                                                    type="checkbox"
                                                                    id="1520,1521,1522"
                                                                    onChange={txPermissions}
                                                                />
                                                            }
                                                            <span className="form-check-sign" />
                                                            Admin Access
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="permission-section">
                                                <Label sm="2">Lending Pool</Label>
                                                <Col className="checkbox-radios" sm="10">
                                                    <FormGroup check>
                                                        <Label check>
                                                            {
                                                                (
                                                                    (id !== "new" &&  originalPermissionList && originalPermissionList.length !== 0)
                                                                    ||
                                                                    (id === "new")
                                                                ) &&
                                                                <Input
                                                                    defaultChecked={originalPermissionList.includes("1514")}
                                                                    type="checkbox"
                                                                    id="1514,1515,1516,1517,1518"
                                                                    onChange={lendingPermissions}
                                                                />
                                                            }
                                                            <span className="form-check-sign" />
                                                            Admin Access
                                                        </Label>
                                                    </FormGroup>
                                                    {
                                                        // lendingState === "admin" &&
                                                        <div className="additional-permission">
                                                            <h5>Additional Lending Pool Permissions</h5>
                                                            {/*<FormGroup check>*/}
                                                            {/*    <Label check>*/}
                                                            {/*        <Input type="checkbox" />*/}
                                                            {/*        <span className="form-check-sign" />*/}
                                                            {/*        Deposit*/}
                                                            {/*    </Label>*/}
                                                            {/*</FormGroup>*/}
                                                            <FormGroup check>
                                                                <Label check>
                                                                    {
                                                                        (
                                                                            (id !== "new" &&  originalPermissionList && originalPermissionList.length !== 0)
                                                                            ||
                                                                            (id === "new")
                                                                        ) &&
                                                                        <Input
                                                                            type="checkbox"
                                                                            id={"1528"}
                                                                            onChange={handleLendingTransferPermissions}
                                                                            defaultChecked={originalPermissionList.indexOf("1528") > -1}
                                                                        />
                                                                    }
                                                                    <span className="form-check-sign" />
                                                                    Allow User to Transfer/Deposit from Vaults
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    {
                                                                        (
                                                                            (id !== "new" &&  originalPermissionList && originalPermissionList.length !== 0)
                                                                            ||
                                                                            (id === "new")
                                                                        ) &&
                                                                        <Input
                                                                            type="checkbox"
                                                                            id={"1529"}
                                                                            value={lendingWithdraw}
                                                                            onChange={handleLendingWithdrawPermissions}
                                                                            defaultChecked={originalPermissionList.indexOf("1528") > -1}
                                                                        />
                                                                    }
                                                                    <span className="form-check-sign" />
                                                                    Withdraw from Pool to Vaults
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>

                                        {
                                            id === "new" &&
                                            <Button color="primary" onClick={createMember}>
                                                Invite New Member
                                            </Button>
                                        }
                                        {
                                            id !== "new" &&
                                            <Button color="primary" onClick={ updateMemberProfile }>
                                                Update Member
                                            </Button>
                                        }
                                        {
                                            redirect &&
                                            <Redirect to="/admin/console" />
                                        }

                                    </CardBody>
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default MemberPermissions;
