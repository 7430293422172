import { Base64 } from "../../base64";

export class DeleteRuleData {
  ruleHash: string;
 
  constructor(rulehash: string) {
    this.ruleHash = rulehash;

  }

  public static fromJson(json: any): DeleteRuleData {
    return new DeleteRuleData(
      json["rule_hash"],
      );
  }

  public toJson(): string {
    return JSON.stringify({
      "rule_hash": this.ruleHash,
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
