/* eslint-disable no-unused-vars*/
import React, { useState, useEffect, useRef } from "react";
import {
    Base64,
    CreatePBMProgramPoolTransaction,
    CreatePBMProgramTransaction,
    MintPBM, PBMRedeemTransaction,
    PBMTransferTransaction, Quantity,
    CreatePBMProgramPoolConditionTransaction
} from "kapow-sdk";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, FormGroup, Modal, ModalBody, Form, Input, CardFooter,
} from "reactstrap";

import MealProgramModal from "./Modals/MealProgramModal";
import {Link, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {config} from "../config";
import { getNonce } from "../reducers/currentNonce";
import "./mealvoucherprogram.scss";
import Swal from "sweetalert2";
import Select from "react-select";
import { MCC_LIST, ER, NER } from "../constants";
import LongHash from "components/LongHash/LongHash";
import NotificationAlert from "react-notification-alert";
import {setCurrentTokenPool} from "actionCreators/setCurrentTokenPool";
import {setCurrentOrg} from "../actionCreators/setCurrentOrg";


const MealVoucherProgram = () => {

    const [programList, setProgramList] = useState([]);
    const [currentProgram, setCurrentProgram] = useState({});

    const [currentPool, setCurrentPool] = useState({});

    const [programPools, setProgramPools] = useState([]);
    const [modalClassic, setModalClassic] = useState(false);
    const [alert, setAlert] = useState(null);
    const [currentView, setCurrentView] = useState("init");
    const [poolCreateState, setPoolCreateState] = useState(false);
    const [invalidMCCAddress, setInvalidMCCAddress] = useState([]);

    const [gasETHData, setETHGasData] = useState(null);
    const [modalClassicToken, setModalClassicToken] = useState(false);

    const [vault_list, setCurrentVaults] = useState(null);
    const [vault, setVault] = useState("");

    const [tokenQuantity, setTokenQuantity] = useState("0.00");

    const [assetList, setAssetList] = useState(null);

    const nonce = useSelector(state => state.currentNonce);
    const dispatch = useDispatch();
    const notificationAlertRef = useRef(null);

    useEffect(() => {
        (async () => {
            await getAssetList();
            await getPBMPrograms();
            await getETHGasData();
            await getCurrentVaults();
            dispatch(getNonce());
        })();

        return () => {

        };
    }, []);

    const toggleModalClassicToken = () => {
        setModalClassicToken(!modalClassicToken);
    };

    const openPop = (msg = 'Purpose Bound Vault Created') => {
        Swal.fire(
            'Success!',
            msg,
            'success'
        )
    }

    const notify = (place, msg) => {
        let color = Math.floor(Math.random() * 5 + 1);
        let type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        let options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    async function getAssetList () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`
        );

        let assetList = await response.json();
        console.log("asset list shubham hew added data", assetList);
        setAssetList(assetList);
    }

    async function getCurrentVaults (vault_address) {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${config.endpoints.vaults}?address=${localStorage.getItem("ws_address")}&organisationHash=${localStorage.getItem("org_hash")}&limit=100`
        );

        let vault_list = await response.json();
        console.log("vault Data ", vault_list.data);

        let useAbleList = [];
        useAbleList.unshift({
            value: "",
            label: "Choose a Source Vault",
            name: "Choose a Source Vault",
            isDisabled: true,
        });

        vault_list.data.map((vault, innerIndex) => {
            useAbleList.push({
                value: "",
                label: vault.vault_name,
                name: vault.vault_name,
                address: vault.vault_address
            })
        });
        console.log("Vault List", useAbleList);
        setCurrentVaults(useAbleList);
        if(vault_address) {
            console.log("current Vault =>", useAbleList.find((item) => {
                return (item.address === vault_address);
            }), vault_address);
            setVault(useAbleList.find(item => item.address === vault_address));
        }
    }

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    async function getPBMPrograms () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.organisation}/${localStorage.getItem("org_hash")}/pbm-programs`
        );

        let pbmPrograms = await response.json();
        console.log("PBM Program Group Response ", pbmPrograms.data);
        setProgramList(pbmPrograms.data);
        setCurrentProgram(pbmPrograms.data[0]);
        getProgramPools(pbmPrograms.data[0].pbm_program_hash);
    }

    async function getProgramPools (hash) {

        const response = await fetch(
            `${config.api_url}/${config.v}/pbm/programs/${hash}/program-pool`
        );

        let programPools = await response.json();
        setProgramPools(programPools.data);
        setCurrentPool(programPools.data[0]);
        // dispatch(setCurrentTokenPool(programPools.data[0]));
        localStorage.setItem("current_token_pool", JSON.stringify(programPools.data[0]));
    }

    async function getETHGasData () {
        const response = await fetch(
            `${config.eth_gas_api}`
        );

        const gas_data = await response.json();
        console.log("ETH gas_data", gas_data.data);
        setETHGasData({
            price: gas_data.data.price,
            limit: gas_data.data.limit
        });
    }

    const mintMoney = () => {

        const minPBMTx = new MintPBM(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            currentPool.vault.vault_address,
            "",
            currentPool.address,
            ER,
            tokenQuantity,
            "0x00",
            parseInt(gasETHData.limit),
        );

        minPBMTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = minPBMTx.toBase64();
        sendTx(signedTx, "Meal Tokens Minted!").then((res) => {
            console.log("response", res);
        });
    };

    const disbursalTransaction = () => {

        const disbursalTx = new PBMTransferTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            "1",
            NER,
            "YHhjKFRM8hvHjmGEt9ynKAAtrkhrdxMFpEtrNVYhJoCSkdmo",
            "fzehpq15iYXPeLG6twBdF7A5GvPsaYKdSSBGow1tEEWRdYvX",
            "0x00",
            parseInt(gasETHData.limit),
            "testing 123, 321!"
        );

        disbursalTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = disbursalTx.toBase64();
        sendTx(signedTx, "Tokens Disbursed!").then((res) => {
            console.log("response", res);
        });
    };

    const redeemTransaction = () => {

        const redeemTx = new PBMRedeemTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            "0.5",
            NER,
            "cTuaZoVwUg6KRwd32CRyETCYYR1xqwkTYbGxEB4xLUJFnXbk",
            "ZHuJXiLspnJdjnSRDCfatUZ18HXNkSimzLTcw9oAnNHhxs8s",
            "0x00",
            parseInt(gasETHData.limit),
            "testing 123, 321!"
        );

        redeemTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = redeemTx.toBase64();
        sendTx(signedTx, "Meal Tokens Disbursed!").then((res) => {
            console.log("response", res);
        });
    };

    const createPBMProgramPoolConditionTx = () => {
        const createPBMProgramPoolTx = new CreatePBMProgramPoolConditionTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            vault.address,
            "",
            "",
            currentProgram.pbm_program_hash,
            2, // Asset ID fixed as its CBDC - Retail
            50,
            10,
            config.oracle_address,
            "inr"
            );

        createPBMProgramPoolTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = createPBMProgramPoolTx.toBase64();
        sendTx(signedTx, "Meal Token Pool Created!").then((res) => {
            console.log("response", res);
        });
    }

    const createPBMPool = () => {

        console.log("name", currentProgram.pbm_program_name);
        console.log("hash", currentProgram.pbm_program_hash);
        console.log("asset id", currentProgram.pbm_asset_id);
        console.log("mcc", invalidMCCAddress);

        let mccList = [];
        invalidMCCAddress.map((mcc_obj, index) => mccList.push(mcc_obj.mcc));
        console.log("mccList", mccList.join(","));
        console.log("vault_address", vault.address);

        const createPBMTx = new CreatePBMProgramPoolTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            vault.address,
            "",
            currentProgram.pbm_program_name,
            currentProgram.pbm_program_hash,
            1, // Asset ID fixed as its CBDC - Retail
            currentProgram.pbm_asset_id,
            mccList.join(","),
            config.oracle_address,
            "inr"
        );

        createPBMTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = createPBMTx.toBase64();
        sendTx(signedTx, "Meal Token Pool Created!").then((res) => {
            console.log("response", res);
        });
    };

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx, successMsg) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        const json = await response.json();

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!", json);
            notify("tr", json.message);
            return;
        } else {
            // getAccountRules();
            toggleModalClassicToken();
            openPop(successMsg);
            // notify("successful transaction");
            dispatch(getNonce());
            // dispatch(setCurrentMemberView("member-list"));
            getProgramPools(currentProgram.pbm_program_hash);
        }

        console.log("Transaction Successful!");
    }

    // const handleTokenQuantity = (event) => {
    //     setTokenQuantity(event.target.value);
    // }

    let { path } = useRouteMatch();
    let { id } = useParams();

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <div className="content meal-voucher">

                {/* Vault Modal - 2 for Minting Tokens */}
                <Modal isOpen={modalClassicToken} toggle={toggleModalClassicToken} className="">
                    <div className="modal-header justify-content-left">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassicToken}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        {/*<h6 className="title title-up" style={{color: "white"}}>Create Vault</h6>*/}
                    </div>
                    <ModalBody className="justify-content-left connect-exchange-modal">
                        <div>
                            <Col className="mb-4">
                                {/*<CardTitle tag="h4">Customisable Select</CardTitle>*/}
                                <Row>
                                    <Col lg="12" md="6" sm="3" style={{padding: "0", maxWidth: "100%"}}>
                                        <Card style={{marginTop: "20px", border: "0.5px solid grey", background: "transparent", boxShadow: "none"}}>
                                            <CardHeader>
                                                <CardTitle tag="h4">
                                                    <div className="vault-logo">
                                                        <div>
                                                            <img src={require("assets/img/vault-transparent.png").default} alt="" />
                                                        </div>
                                                    </div>
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <Form>
                                                    <label>Token Quantity</label>
                                                    <FormGroup>
                                                        <Input type="text" placeholder="Enter the Amount of Meal Token you want to Mint"
                                                               onChange={(e) => setTokenQuantity(e.target.value)}
                                                        />
                                                    </FormGroup>
                                                    {/*<label>Choose Assets</label>*/}
                                                </Form>
                                            </CardBody>
                                            <CardFooter style={{textAlign: "center"}}>
                                                <Button className="btn-fill" color="primary"  onClick={ mintMoney }>
                                                    Mint Meal Voucher Tokens
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            {/*<input type="text" placeholder="Search Exchange Name" style={{width: "calc(100% - 35px)", marginBottom: "10px"}} />*/}

                        </div>
                    </ModalBody>

                </Modal>
                {/* End Vault Modal - 2 for Minting Tokens */}
                <Switch>
                    <Route exact path={path}>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            Corporate Meal Program
                                            {/*<button onClick={createPBMProgramPoolConditionTx}>TEST Condition</button>*/}
                                            <Link color="info" to={`meal-voucher-program/new`}>
                                                <Button
                                                    color="primary"
                                                    className="new-vault btn-simple"
                                                    style={{marginRight: "20px"}}
                                                >
                                                    + Create New Meal Program
                                                </Button>
                                            </Link>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        {
                                            programList && programList.length === 0 &&
                                            <div className="first-view">
                                                <div className="step-1">
                                                    <div className="team-image">
                                                        <div>
                                                            <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1672745122/web_application/s2_agzbke.png" alt="" style={{width: "200px"}} />
                                                        </div>
                                                    </div>
                                                    <div className="step-1__text">
                                                        Create your Corporate Meal Program

                                                    </div>
                                                    <Link color="info" to={`meal-voucher-program/new`}>
                                                        <div className="step-1__action">
                                                            <Button className="btn-primary" toggleModalClassic={ toggleModalClassic }>+ Create Program</Button>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                        <div className="program_plus_pool">

                                            {
                                                programList && programList.length > 0 &&
                                                <div className="meal-program-list">
                                                    {
                                                        programList.filter((item, index) => index === 0).map((program, index) => (
                                                            <div className="meal-program">
                                                                <h6 className="program-title">Meal Program</h6>
                                                                <div className="meal-program-body">
                                                                    <div className="programCtx">
                                                                        <div>
                                                                            <label>Program Name</label>
                                                                            <div>{ program.pbm_program_name }</div>
                                                                        </div>
                                                                        <div>
                                                                            <label>Token Name</label>
                                                                            <div>{ program.pbm_coin_name }</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="programCtx">
                                                                        <div>
                                                                            <label>Token Symbol</label>
                                                                            <div>{ program.pbm_coin_symbol }</div>
                                                                        </div>
                                                                        <div>
                                                                            <label>Token Logo</label>
                                                                            <img src={ program.pbm_coin_logo } alt="" width="40" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="programCtx">
                                                                        <div>
                                                                            <label>Description</label>
                                                                            <div>{ program.pbm_coin_description }</div>
                                                                        </div>
                                                                        <div>
                                                                            <label>Status</label>
                                                                            <div>
                                                                                <span className="status-tag">Active</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }

                                            {
                                                programPools && programPools.length ===  0 &&
                                                programList && programList.length > 0 &&
                                                poolCreateState === false &&
                                                <div className="pbm_pool" onClick={ () => {
                                                    setPoolCreateState(true);
                                                }}>
                                                    <i>+</i>
                                                    <b>Create Meal Voucher Pool</b>
                                                </div>
                                            }

                                            {
                                                programPools && programPools.length ===  0 &&
                                                programList && programList.length > 0 &&
                                                poolCreateState === true &&
                                                <div className="pbm_pool__create">
                                                    <h6 className="program-title">Create Asset Pool for Meal Voucher Program</h6>
                                                    <div className="programCtx">
                                                        <div>
                                                            <label>Collateral Asset Name</label>
                                                            <div>e-Rupee Retail</div>
                                                        </div>
                                                        <div>
                                                            <label>Collateral Asset Logo</label>
                                                            <img src="https://res.cloudinary.com/kapow-tech/image/upload/v1676914208/erupee_vii3is.png" alt="" width="30"/>
                                                        </div>
                                                    </div>

                                                    <FormGroup className="calc-width">
                                                        <label style={{fontSize: "12px", textTransform: "uppercase", fontWeight: "bold"}}>Choose Source Vault<sup className="required-label">*</sup></label>
                                                        {
                                                            vault_list && vault_list.length > 0 &&
                                                            <Col lg="12" md="12" sm="3">
                                                                <Select
                                                                    style={{color: "#fff"}}
                                                                    className="react-select info"
                                                                    classNamePrefix="react-select"
                                                                    name="vault"
                                                                    value={vault}
                                                                    onChange={(value) => {
                                                                        console.log("value", value);
                                                                        setVault(value);
                                                                    }}
                                                                    getOptionLabel={value => (
                                                                        <div>
                                                                            <img
                                                                                className="asset-logo-icon"
                                                                                src={require("assets/img/vault-transparent.png").default}
                                                                                alt=""
                                                                            />
                                                                            <span>{value.name}</span>
                                                                        </div>
                                                                    )}
                                                                    options={vault_list}
                                                                    placeholder="Choose Source Vault"
                                                                />
                                                                {/*<span className="error_m">*required</span>*/}
                                                            </Col>
                                                        }
                                                    </FormGroup>

                                                    <div className="rules-form-elements calc-width mcc_selector">
                                                        <label style={{fontSize: "12px", textTransform: "uppercase", fontWeight: "bold"}}>Invalid MCCs<sup className="required-label">*</sup></label>
                                                        <Col lg="12" md="12" sm="3">
                                                            <Select
                                                                className="react-select info"
                                                                classNamePrefix="react-select"
                                                                placeholder="Select Merchant Code"
                                                                name="merchantCodesList"
                                                                closeMenuOnSelect={false}
                                                                isMulti
                                                                value={ invalidMCCAddress }
                                                                onChange={(value) => {
                                                                    setInvalidMCCAddress(value);
                                                                }}
                                                                options={MCC_LIST}
                                                            />
                                                            {/*<span className="error_m">*required</span>*/}
                                                        </Col>
                                                    </div>
                                                    <Button
                                                        color="primary"
                                                        className="new-vault btn-simple create_meal_pool"
                                                        onClick={ createPBMPool }
                                                    >
                                                        + Create Meal Pool
                                                    </Button>
                                                </div>
                                            }

                                            {
                                                programPools && programPools.length > 0 &&
                                                <div className="multi-top">
                                                    {
                                                        programPools.map((pool, index) => (
                                                                <div className="top-border">
                                                                    <div className="meal-program">
                                                                        <h6 className="program-title">Pool Details</h6>
                                                                        <div className="meal-program-body">
                                                                            <div className="programCtx">
                                                                                <div>
                                                                                    <label>Pool Name</label>
                                                                                    <div>{ pool.pbm_program_pool_name }</div>
                                                                                </div>
                                                                                <div>
                                                                                    <label>Pool Address</label>
                                                                                    <div>
                                                                                        <LongHash hash={pool.address} mode="dark" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="programCtx">
                                                                                <div>
                                                                                    <label>Collateral Asset</label>
                                                                                    <div>
                                                                                        <img src={ pool.collateral_asset.logo } alt="" width="40" />
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <label>Purpose Bound Token</label>
                                                                                    <div>
                                                                                        <img src={ pool.pbm_asset.logo } alt="" width="40" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="programCtx">
                                                                                <div>
                                                                                    <label>Pool Collateral Balance</label>
                                                                                    <div>{
                                                                                        Number(
                                                                                            Quantity.hexToDecimal(
                                                                                                pool.collateral_asset,
                                                                                                pool.portfolio[0].confirmed_quantity
                                                                                            )
                                                                                        )
                                                                                    }</div>
                                                                                </div>
                                                                                <div>
                                                                                    <label>Pool Token Balance</label>
                                                                                    <div>
                                                                                        {
                                                                                            Number(
                                                                                                Quantity.hexToDecimal(
                                                                                                    pool.pbm_asset,
                                                                                                    pool.portfolio[pool.portfolio.findIndex(obj => obj.id === pool.pbm_asset.id)].confirmed_quantity
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    {/*<span>{pool.portfolio.findIndex(obj => obj.id === pool.pbm_asset.id)}</span>*/}
                                                                                </div>
                                                                            </div>
                                                                            <div className="programCtx">
                                                                                <div>
                                                                                    <label>Collateral Source Vault</label>
                                                                                    <div>{ pool.vault.vault_name}</div>
                                                                                </div>
                                                                                <div>
                                                                                    <label>Blocked Merchant Codes</label>
                                                                                    <div>
                                                                                        {
                                                                                            MCC_LIST.filter((Mcc, dIndex) => (pool.invalid_mcc.split(",").findIndex(str => str !== Mcc.mcc))).map((newObj) => <div>{newObj.mcc + " - " + newObj.name}</div>)
                                                                                        }
                                                                                    </div>
                                                                                    {/*<span>{pool.portfolio.findIndex(obj => obj.id === pool.pbm_asset.id)}</span>*/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                    <Button
                                                        color="primary"
                                                        className="new-vault btn-simple create_meal_pool"
                                                        onClick={ toggleModalClassicToken }
                                                    >
                                                        Mint Tokens
                                                    </Button>

                                                    {/*<Button*/}
                                                    {/*    color="primary"*/}
                                                    {/*    className="new-vault btn-simple create_meal_pool"*/}
                                                    {/*    onClick={ disbursalTransaction }*/}
                                                    {/*>*/}
                                                    {/*    Disburse Tokens*/}
                                                    {/*</Button>*/}

                                                    {/*<Button*/}
                                                    {/*    color="primary"*/}
                                                    {/*    className="new-vault btn-simple create_meal_pool"*/}
                                                    {/*    onClick={ redeemTransaction }*/}
                                                    {/*>*/}
                                                    {/*    Redemption Transaction*/}
                                                    {/*</Button>*/}

                                                </div>
                                            }

                                        </div>



                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Route>

                    <Route path={`${path}/:id`}>
                        <MealProgramModal
                            modalClassic={ modalClassic }
                            toggleModalClassic={ toggleModalClassic }
                            getPBMPrograms = { getPBMPrograms }
                        />
                    </Route>
                </Switch>
            </div>
        </>
    );
};

export default MealVoucherProgram;