import React, { useEffect, useState, useRef } from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    CreatePBMProgramTransaction
} from 'kapow-sdk';
import { useSelector, useDispatch } from "react-redux";
import {config} from "../../config";

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    ModalBody,
    Row,
} from "reactstrap";

import Swal from "sweetalert2";
import NotificationAlert from "react-notification-alert";

import { getNonce } from "../../reducers/currentNonce";
import "./loyaltyprogrammodal.scss";

const LoyaltylProgramModal = (props) => {

    const { getPBMPrograms } = props;

    const [programName, setProgramName] = useState("");
    const [tokenName, setTokenName] = useState("");
    const [tokenSymbol, setTokenSymbol] = useState("");
    const [tokenLogo, setTokenLogo] = useState("");
    const [tokenDescription, setTokenDescription] = useState("");

    const [mintState, setMintState] = useState(false);
    const [burnState, setBurnState] = useState(false);
    const [txFeeState, setTxFeeState] = useState(false);

    let history = useHistory();
    let { id } = useParams();
    const nonce = useSelector(state => state.currentNonce);
    const dispatch = useDispatch();
    const notificationAlertRef = useRef(null);

    const isFormReadyToSubmit = () => {

        if (programName === "") {
            notify("tr", "Please enter Program Name");
            return false;
        } else if (tokenName === "") {
            notify("tr", "Please enter Token Name");
            return false;
        } else if (tokenSymbol === "") {
            notify("tr", "Please enter Token Symbol");
            return false;
        }  else if (tokenLogo === "") {
            notify("tr", "Please enter Token Logo");
            return false;
        } else if (tokenDescription === "") {
            notify("tr", "Please enter Token Description");
            return false;
        } else {
            return true;
        }
    }

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Loyalty Program Created!',
            'success'
        )
    }

    useEffect(() => {
        (async () => {
            dispatch(getNonce());
        })();

        return () => {
            // Do cleanup on Unmount,

        }

    }, []);

    const notify = (place, msg) => {
        let color = Math.floor(Math.random() * 5 + 1);
        let type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        let options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const createNewPBMProgram = () => {
        console.log("Inside Create New PDM Program", nonce);

        if (isFormReadyToSubmit() === false) {
            return false;
        }

        const createPBMTx = new CreatePBMProgramTransaction(
            nonce,
            localStorage.getItem("ws_address"),
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            programName,
            tokenName,
            tokenSymbol,
            tokenLogo,
            tokenDescription
        );

        createPBMTx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = createPBMTx.toBase64();
        sendTx(signedTx, "Loyalty Program Created!").then((res) => {
            console.log("response", res);
        });
    };

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        const json = await response.json();

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            notify("tr", json.message);
            return;
        } else {
            getPBMPrograms();
            openPop();
            dispatch(getNonce());
            // notify("successful transaction");
            // dispatch(setCurrentMemberView("member-list"));
            history.push("/admin/loyalty");
        }

        console.log("Loyalty Program Created Successfully", json);
    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <div className="rules-modal meals-modal">
                <div className="modal-header justify-content-left">
                    <h6 className="title title-up">Loyalty Program Details</h6>
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    <div>

                        <Row>
                            <Col md="12">
                                <Form id="RegisterValidation">
                                    <Card className="rule-set">
                                        <CardBody>
                                            <div className="input-parallel">
                                                <FormGroup>
                                                    <label>Program Name<sup className="required-label">*</sup></label>
                                                    <Input
                                                        name="rule"
                                                        id="rule_name"
                                                        type="text"
                                                        value={programName}
                                                        placeholder="Enter the Name of the Loyalty Program"
                                                        onChange={(e) => setProgramName(e.target.value)}
                                                    />
                                                    {/*<span className="error_m">*required</span>*/}
                                                </FormGroup>
                                                <FormGroup>
                                                    <label>Token Name<sup className="required-label">*</sup></label>
                                                    <Input
                                                        name="rule"
                                                        id="rule_name"
                                                        type="text"
                                                        value={tokenName}
                                                        placeholder="Enter the Name of the Token you want to Mint"
                                                        onChange={(e) => setTokenName(e.target.value)}
                                                    />
                                                    {/*<span className="error_m">*required</span>*/}
                                                </FormGroup>
                                            </div>
                                            <div className="input-parallel">
                                                <FormGroup>
                                                    <label>Token Symbol<sup className="required-label">*</sup></label>
                                                    <Input
                                                        name="rule"
                                                        id="rule_name"
                                                        type="text"
                                                        value={tokenSymbol}
                                                        placeholder="Enter the Symbol for the Token that you'll be Minting"
                                                        onChange={(e) => setTokenSymbol(e.target.value)}
                                                    />
                                                    {/*<span className="error_m">*required</span>*/}
                                                </FormGroup>
                                                <FormGroup>
                                                    <label>Token Logo<sup className="required-label">*</sup></label>
                                                    <Input
                                                        name="rule"
                                                        id="rule_name"
                                                        type="text"
                                                        value={tokenLogo}
                                                        placeholder="Enter the Url or Upload the Token Logo"
                                                        onChange={(e) => setTokenLogo(e.target.value)}
                                                    />
                                                    {/*<span className="error_m">*required</span>*/}
                                                </FormGroup>
                                            </div>
                                            <div className="input-parallel">
                                                <FormGroup>
                                                    <label>Token Description<sup className="required-label">*</sup></label>
                                                    <Input
                                                        name="description"
                                                        type="text"
                                                        value={tokenDescription}
                                                        placeholder="Enter the Token Description"
                                                        onChange={(e) => setTokenDescription(e.target.value)}
                                                    />
                                                    {/*<span className="error_m">*required</span>*/}
                                                </FormGroup>
                                                {/*<i className="tim-icons icon-square-pin" />*/}
                                            </div>

                                            <br/>
                                            <div>
                                                <h5 className="title title-up">Select Features for Your Token</h5>
                                                <p style={{marginTop: "-10px", color: "#5b5b5b"}}>Choose the Additional Functionalities you want added to you Smart Contracts</p>
                                            </div>
                                            <br/>
                                            <div className="meal-modal-flex-box">
                                                <Col lg="4" md="6">
                                                    <Card className={"card-stats " + (mintState ? "active" : "")}
                                                          onClick={ () => {
                                                              setMintState(!mintState);
                                                          }}
                                                    >
                                                        <CardBody>
                                                            <Row>
                                                                <Col xs="5">
                                                                    <div className="info-icon text-center">
                                                                        <i className="tim-icons icon-money-coins" />
                                                                    </div>
                                                                </Col>
                                                                <Col xs="7">
                                                                    <div className="numbers">
                                                                        <h3 className="card-category">Mint Function</h3>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                        <CardFooter>
                                                            <hr />
                                                            <div className="stats">
                                                                <i className="tim-icons icon-trophy" /> Add the Ability to Mint Additional Tokens
                                                            </div>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                                <Col lg="4" md="6">
                                                    <Card className={"card-stats " + (burnState ? "active" : "")}
                                                          onClick={ () => {
                                                              setBurnState(!burnState);
                                                          }}
                                                    >
                                                        <CardBody>
                                                            <Row>
                                                                <Col xs="5">
                                                                    <div className="info-icon text-center icon-success">
                                                                        <i className="tim-icons icon-money-coins" />
                                                                    </div>
                                                                </Col>
                                                                <Col xs="7">
                                                                    <div className="numbers">
                                                                        <h3 className="card-category">Burn Function</h3>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                        <CardFooter>
                                                            <hr />
                                                            <div className="stats">
                                                                <i className="tim-icons icon-trophy" /> Add the Ability to Burn Tokens
                                                            </div>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                                <Col lg="4" md="6">
                                                    <Card className={"card-stats " + (txFeeState ? "active" : "")}
                                                          onClick={ () => {
                                                              setTxFeeState(!txFeeState);
                                                          }}
                                                    >
                                                        <CardBody>
                                                            <Row>
                                                                <Col xs="5">
                                                                    <div className="info-icon text-center">
                                                                        <i className="tim-icons icon-money-coins" />
                                                                    </div>
                                                                </Col>
                                                                <Col xs="7">
                                                                    <div className="numbers">
                                                                        <h3 className="card-category">Transaction Fee</h3>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                        <CardFooter>
                                                            <hr />
                                                            <div className="stats">
                                                                <i className="tim-icons icon-trophy" /> Charge a fee for each Tx that takes place.
                                                            </div>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            </div>
                                        </CardBody>



                                        <CardFooter className="text-right">
                                            <FormGroup check className="pull-left">
                                                <Label check>
                                                    &nbsp;
                                                </Label>
                                            </FormGroup>
                                            {
                                                id && id === "new" &&
                                                <Button color="primary" onClick={ createNewPBMProgram }>
                                                    Create Loyalty Program
                                                </Button>
                                            }
                                            {
                                                id && id !== "new" &&
                                                <div>
                                                    <Button color="primary">
                                                        Update Loyalty Program
                                                    </Button>
                                                    <Button color="primary" >
                                                        Archive Loyalty Program
                                                    </Button>
                                                </div>

                                            }
                                        </CardFooter>
                                    </Card>

                                </Form>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </div>
        </>
    );
};

export default LoyaltylProgramModal;