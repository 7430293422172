// import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { config } from "../config";

export function currentNonce(state= 0, action) {
    switch(action.type){
        case 'SET_NONCE':
            return action.payload;
        default:
            return state;
    }
}

//asynchronous action
export const getNonce = () => async dispatch => {
    let res = {data:[]};
    try {
        res = await axios.get(`${config.api_url}/${config.v}/${config.endpoints.accounts}/${localStorage.getItem("ws_address")}/sequence`);
        await dispatch({
            type: 'SET_NONCE',
            payload: res.data + 1
        })
    } catch (error) {
        //res.data = error;
        console.log(error);
    }
}