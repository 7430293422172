export function strip0x(address: string) {
  if (address.startsWith("0x") || address.startsWith("0X")) {
    return address.substring(2);
  }
  return address;
}

export function isValidFormat(address: string): boolean {
  let regexp = new RegExp("^[0-9a-f]{40}$");
  return regexp.test(strip0x(address));
}


