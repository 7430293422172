import { Base64 } from "../../base64";

export class CreatePBMProgramPoolData {
  name: string;
  pbmProgramHash: string;
  collateralAssetID: number;
  pbmAssetID: number;
  invalidMCC: string;
  assetPriceOracle: string;
  priceUnit: string;
  
  constructor(name: string, pbmProgramHash: string, collateralAssetID: number, pbmAssetID: number, invalidMCC: string, assetPriceOracle: string, priceUnit: string) {
    this.name = name;
    this.pbmProgramHash = pbmProgramHash;
    this.collateralAssetID = collateralAssetID;
    this.pbmAssetID = pbmAssetID;
    this.invalidMCC = invalidMCC;
    this.assetPriceOracle = assetPriceOracle;
    this.priceUnit = priceUnit;

  }

  public static fromJson(json: any): CreatePBMProgramPoolData {
    return new CreatePBMProgramPoolData(
      json["name"],
      json["pbm_program_hash"],
      json["collateral_asset_id"],
      json["pbm_asset_id"],
      json["invalid_mcc"],
      json["asset_price_oracle"],
      json["price_unit"]
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "name": this.name,
      "pbm_program_hash": this.pbmProgramHash,
      "collateral_asset_id": this.collateralAssetID,
      "pbm_asset_id": this.pbmAssetID,
      "invalid_mcc": this.invalidMCC,
      "asset_price_oracle": this.assetPriceOracle,
      "price_unit": this.priceUnit

    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
