import { Base64 } from "../../base64";

export class ActionTransactionData {
  txHash: string;

  constructor(txHash: string) {
    this.txHash = txHash;
  }

  public static fromJson(json: any): ActionTransactionData {
    return new ActionTransactionData(
      json["tx_hash"]
    );
  }

  public toJson(): string {
    return JSON.stringify({
      "tx_hash": this.txHash,
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
