import React, {useEffect, useState} from "react";
import {config} from "../../config";
import "./receivemodal.scss";
import QRCode from "./../components/QRCode";

import {
    Button,
    Col, FormGroup, Input, Modal, ModalBody, Row, UncontrolledTooltip,
} from "reactstrap";
import {KeyPair, TransferTransaction} from "kapow-sdk";
import {ImCopy} from "react-icons/im";
import {CopyToClipboard} from "react-copy-to-clipboard";

const ReceiveModal = (props) => {

    const { modalClassic1, setModalClassic1, assetAddress } = props;
    const [txCopied, setTxCopyStatus] = useState(false);

    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };

    useEffect(() => {

    }, []);

    return (
        <>
            <Modal isOpen={modalClassic1} toggle={toggleModalClassic1} className="qr-model">
                <div className="modal-header justify-content-left">
                    <button
                        aria-hidden={true}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalClassic1}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up">Scan QR Code Address</h6>
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    <div className="qr-styler">
                        <QRCode address={assetAddress}/>
                        {/*<br />*/}
                        <div className="copy_address_link">
                            <sub>{assetAddress} </sub>
                            <CopyToClipboard
                                className="copy-pointer"
                                text={assetAddress}
                                id="tooltip7174699999"
                                onCopy={() => {
                                    setTxCopyStatus({copied: true});
                                }}>
                                <ImCopy />
                            </CopyToClipboard>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip7174699999"
                            >
                                Copy Address
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ReceiveModal;
