import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { DeleteRuleData } from "./data/data_rule_delete";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class DeleteRuleTransferTransaction extends Transaction {

  // type: number = TransactionTypeEnum.TX_TYPE_MUA_CREATE_RULE;
  type: number = 1522
  txType: number = 1513 //TX_TYPE_MUA_TRANSFER

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, ruleHash: string) {
    super();
    // this.nonce = nonce;
    // this.address = address;
    // this.publicKey = publicKey;
    console.log("insie YS", nonce, sender, publicKey, ruleHash)
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    // var ruleData = new CreateRuleTransferData(assetID, min, max, valueType).toBase64();
    var data = new DeleteRuleData(ruleHash).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();
  }
}
