
export class Base64 {
  public static encode(data: Buffer): string {
    return data.toString('base64');
  }

  public static decode(data: string): Buffer {
    return Buffer.from(data, 'base64');
  }
  public static encodeString(data: string): string {
    return Buffer.from(data, 'utf8').toString('base64');
  }

  public static decodeString(data: string): string {
    return Buffer.from(data, 'base64').toString('utf8');
  }
}
