import { Conversion } from '../conversion';
import { Hash } from '../hash';
import { Asset } from '../models/asset.model';
import { Utils } from '../utils';
import { Quantity } from '../utils/quantity';
import { TransferData } from './data/data_transfer';
import { Payload } from './payload';
import { Transaction } from './transaction';

export class VaultTransferTransaction extends Transaction {
  // type: number = TransactionTypeEnum.TX_TYPE_VAULT_TRANSFER;
  type: number= 1513;

// data variables
  quantity : string; //quantity in decimal
  asset : Asset;
  to : string;
  from: string;
  gasPrice: string;
  gasLimit: number;
  note: string;

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, quantity: string, asset: Asset, to: string, from: string, gasPrice: string, gasLimit: number, note: string = "") {
    super();
    // this.nonce = nonce;
    // this.address = address;
    // this.publicKey = publicKey;
    this.quantity = quantity;
    this.asset = asset;
    this.to = to;
    this.from = from;
    this.gasPrice = gasPrice;
    this.gasLimit = gasLimit;
    this.note = note;

    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new TransferData(from, to, asset.id, Quantity.decimalToHex(this.asset, this.quantity), this.gasPrice, this.gasLimit).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data, note);
    this.payload = payload.toBase64();
  }
}
