import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import classnames from "classnames";
import "./registerorganization.scss";
import { CreateOrganisationTransaction } from "kapow-sdk";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

import { getNonce } from "../../reducers/currentNonce";
import {config} from "../../config";

const RegisterOrg = () => {
    const [state, setState] = useState({});
    const [orgName, setOrgName] = useState("");
    const [orgSuccess, setOrgSuccess] = useState(false);
    const [orgType, setOrgType] = useState(3);

    const nonce = useSelector(state => state.currentNonce);
    const dispatch = useDispatch();


    useEffect(() => {
        (async () => {
            dispatch(getNonce());
        })();

        document.body.classList.toggle("register-page");
        return () => {
            document.body.classList.toggle("register-page");
        };
    }, [nonce, dispatch]);

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            // notify("tr");
            return;
        } else {
            // notify("successful transaction");
        }
        const json = await response.json();
        console.log("successful transaction", json);
    }

    const CreateOrganization = () => {

        console.log("orgNameAndType", orgName, orgType);

        const org_create_tx = new CreateOrganisationTransaction(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            "",
            "",
            "",
            orgName,
            orgType
        );

        org_create_tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = org_create_tx.toBase64();
        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
            setOrgSuccess(true);
        });
    }

    if (orgSuccess) {
        return <Redirect to='/admin/vaults' />
    }
    return (
        <>
            <div className="content register-organization">
                <Container>
                    <Row>
                        <Col className="ml-auto" md="5">
                            &nbsp;
                        </Col>
                        <Col className="mr-auto" md="7">
                            <Card className="card-register card-white">
                                <CardHeader>
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/card-primary.png").default}
                                    />
                                    <CardTitle tag="h4">Organization</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form organization-name">
                                        <InputGroup
                                            className={classnames({
                                                "input-group-focus": state.nameFocus,
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="tim-icons icon-single-02" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Enter Your Organization name"
                                                type="text"
                                                className="org-name"
                                                onFocus={(e) => setState({ ...state, nameFocus: true })}
                                                onBlur={(e) => setState({ ...state, nameFocus: false })}
                                                onChange={(e) => setOrgName(e.target.value)}
                                            />
                                        </InputGroup>
                                        <FormGroup check className="form-check-radio">
                                            <Label check>
                                                <Input
                                                    defaultChecked
                                                    defaultValue="0"
                                                    id="exampleRadios1"
                                                    name="exampleRadios"
                                                    type="radio"
                                                    onClick={ () => {
                                                        console.log("test", 0);
                                                        setOrgType(0);
                                                    } }
                                                />
                                                <span className="form-check-sign" />
                                                SME
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check className="form-check-radio">
                                            <Label check>
                                                <Input
                                                    defaultValue="1"
                                                    id="exampleRadios2"
                                                    name="exampleRadios"
                                                    type="radio"
                                                    onClick={ () => {
                                                        console.log("test", 1);
                                                        setOrgType(1);
                                                    }  }
                                                />
                                                <span className="form-check-sign" />
                                                BANK
                                            </Label>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        className="btn-round"
                                        color="primary"
                                        href="#pablo"
                                        onClick={CreateOrganization}
                                        size="lg"
                                    >
                                        Create Your Organization
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default RegisterOrg;
