import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reduxWebsocket from '@giantmachines/redux-websocket';
import reducer from './reducers/index';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';


const initialState = {};
const reduxWebsocketMiddleware = reduxWebsocket();
const reduxWebsocketMiddleware_i2 = reduxWebsocket();
const middlewares = [thunk, reduxWebsocketMiddleware, reduxWebsocketMiddleware_i2];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
    // compose(
    //     typeof window === "object" && typeof window.devToolsExtension !== "undefined" ? window.devToolsExtension() : f => f
    // )
);

export default store;