import { Base64 } from "../../base64";

export class UpdateVaultData {
  name: string;
  
  constructor(name: string) {
      this.name = name;     
  }

  public static fromJson(json: any): UpdateVaultData {
    return new UpdateVaultData(
      json["name"],
    );
  }

  public toJson(): string {
    return JSON.stringify({
      "name": this.name,
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
