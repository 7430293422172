import { Base64 } from "../../base64";

export class CreateVaultData {
  name: string;
  assetPriceOracle: string;
  priceUnit: string;
  
  constructor(name: string, assetPriceOracle: string,
    priceUnit: string) {
      this.name = name;
      this.assetPriceOracle = assetPriceOracle;
      this.priceUnit = priceUnit;         
  }

  public static fromJson(json: any): CreateVaultData {
    return new CreateVaultData(
      json["name"],
      json["asset_price_oracle"],
      json["price_unit"]

    );
  }

  public toJson(): string {
    return JSON.stringify({
      "name": this.name,
      "asset_price_oracle": this.assetPriceOracle,
      "price_unit": this.priceUnit
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
