import axios from "axios";
import {config} from "../config";

export function setAssetList (asset_list) {
    return { type:"SET_ASSET_LIST", payload: asset_list }
}

// asynchronous action
export const getAssetList = () => async dispatch => {
    console.log("Inside Get Asset List Reducer");
    let res = {data:[]};
    try {
        res = await axios.get(`${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`);
        console.log("res ASSET List", res.data);
        await dispatch({
            type: 'SET_ASSET_LIST',
            payload: res.data
        })
    } catch (error) {
        //res.data = error;
        console.log(error);
    }
}

export const setCurrentAssetId = (asset_id) => {
    return { type:"SET_CURRENT_ASSET_ID", payload: asset_id }
}