import React, {useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import "./profile.scss";
import {config} from "../../config";
import {getNonce} from "../../reducers/currentNonce";
import axios from "axios";
import Swal from "sweetalert2";
import NotificationAlert from "react-notification-alert";

const Profile = () => {
    const [designation, setDesignation] = useState("");
    const [user_name, setUserName] = useState("");
    const [branch, setBranch] = useState("");
    const [img_src, setImgSrc] = useState(null);
    const currentUser = useSelector(state => state.currentUser);



    let { id } = useParams();
    let dispatch = useDispatch();
    const notificationAlertRef = useRef(null);

    useEffect(() => {
        (async () => {
            await getCurrentProfile();
            dispatch(getNonce());
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [id]);

    const openPop = (message) => {
        Swal.fire(
            'Success!',
            message,
            'success'
        )
    }

    const notify = (place, msg) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    //asynchronous action
    async function getCurrentProfile () {
        const response = await fetch(
            `${config.api_url}/${config.v}/organisation-members/member?orgHash=${localStorage.getItem("org_hash")}&memAddress=${id}`
        );

        const memberDetails = await response.json();
        setImgSrc(memberDetails.profile_image ? `https://api.devnet.kapow.tech/v1/files/images/${memberDetails.profile_image}` : "");
        setUserName(memberDetails.member_name);
        setDesignation(memberDetails.designation);
        setBranch(memberDetails.branch);
    }

    const handleProfileImageChange = (e) => {

        // Preparing Payload
        let formData = new FormData();

        formData.append("file", "");
        formData.append("org_hash", localStorage.getItem("org_hash"));
        formData.append("address", id);
        formData.append("name", user_name);
        formData.append("designation", designation);
        formData.append("branch", branch);

        axios.post(`${config.api_url}/${config.v}/organisation-members/profile`, formData, {
            // Add parameters here
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                console.log("Upload Success", response.data);
                openPop("Member Profile Updated!");
                // Handle dats
            })
            .catch((error) => {
                if(error.response) {
                    console.log("error", error.response.data.error);
                    notify("tr", error.response.data.error);
                }
            })
    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <div className="content user_profile">
                <Row>
                    <Col md="8">
                        <Card>
                            <CardHeader>
                                <h5 className="title">My Profile</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col className="pr-md-1" md="4">
                                            <FormGroup>
                                                <label>Company (view only)</label>
                                                <Input
                                                    defaultValue={ JSON.parse(localStorage.getItem("org_list"))[0].organisation_name }
                                                    disabled
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="px-md-1" md="4">
                                            <FormGroup>
                                                <label>Member Address (view only)</label>
                                                <Input value={ currentUser.member_address } placeholder="dddd" type="text" disabled />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="4">
                                            <FormGroup>
                                                <label>Member Email</label>
                                                <Input value={ currentUser.member_email } placeholder="dddd" type="text" disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-md-1" md="6">
                                            <FormGroup>
                                                <label>Name</label>
                                                <Input
                                                    placeholder="Please Enter Your Name"
                                                    type="text"
                                                    value={ user_name }
                                                    onChange={ (event) => setUserName(event.target.value) }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-md-1" md="6">
                                            <FormGroup>
                                                <label>Designation</label>
                                                <Input
                                                    placeholder="Please Enter Your Designation"
                                                    type="text"
                                                    value={ designation }
                                                    onChange={ (event) => setDesignation(event.target.value) }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Branch</label>
                                                <Input
                                                    type="text"
                                                    value={ branch }
                                                    onChange={ (event) => setBranch(event.target.value) }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button className="btn-fill" color="primary" onClick={handleProfileImageChange}>
                                    Save
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-user">
                            <CardBody>
                                <CardText />
                                <div className="author">
                                    <div className="block block-one" />
                                    <div className="block block-two" />
                                    <div className="block block-three" />
                                    <div className="block block-four" />

                                    {
                                        (img_src !== null || img_src === "") &&
                                        <ImageUpload
                                            addBtnColor="default"
                                            changeBtnColor="default"
                                            addBtnClasses="primary"
                                            org_hash={ localStorage.getItem("org_hash") }
                                            upload_type="profile_pic"
                                            member_address={ currentUser.member_address }
                                            image_src={ img_src }
                                            className="avatar"
                                            user_name={ user_name }
                                            designation={ designation }
                                            branch={ branch }
                                        />
                                    }

                                        {/*<img*/}
                                        {/*    alt="..."*/}
                                        {/*    className="avatar"*/}
                                        {/*    src={require("assets/img/emilyz.jpg").default}*/}
                                        {/*/>*/}
                                        <h5 className="title" >{ user_name }&nbsp;</h5>

                                    <p className="description">{ designation }&nbsp;</p>
                                </div>
                                {/*<div className="card-description">*/}
                                {/*    Do not be scared of the truth because we need to restart the*/}
                                {/*    human foundation in truth And I love you like Kanye loves*/}
                                {/*    Kanye I love Rick Owens’ bed design but the back is...*/}
                                {/*</div>*/}
                            </CardBody>
                            {/*<CardFooter>*/}
                            {/*    <div className="button-container">*/}
                            {/*        <Button className="btn-icon btn-round" color="facebook">*/}
                            {/*            <i className="fab fa-facebook" />*/}
                            {/*        </Button>*/}
                            {/*        <Button className="btn-icon btn-round" color="twitter">*/}
                            {/*            <i className="fab fa-twitter" />*/}
                            {/*        </Button>*/}
                            {/*        <Button className="btn-icon btn-round" color="google">*/}
                            {/*            <i className="fab fa-google-plus" />*/}
                            {/*        </Button>*/}
                            {/*    </div>*/}
                            {/*</CardFooter>*/}
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Profile;
