import React from 'react';
import { useQRCode } from 'react-qrcodes';
import { config } from "./../../config";

function QRCode(address) {
    console.log("address", address.address);
    const [qrRef] = useQRCode({
        text: `${address.address}`,
        options: {
            level: 'H',
            margin: 10,
            scale: 8,
            width: 256,
            color: {
                dark: '#000',
                light: 'red',
            },
        },
    });

    return <canvas ref={qrRef} />;
};

export default QRCode;
