import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CreateRuleTransferTransaction, UpdateRuleTransferTransaction, DeleteRuleTransferTransaction } from 'kapow-sdk';
import { useSelector, useDispatch } from "react-redux";
import {config} from "../../config";

import {
    Button,
    Card, CardBody, CardFooter,
    Col, Form, FormGroup, Input, Label, ModalBody, Row, UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import Slider from "nouislider";
import Swal from "sweetalert2";
import NotificationAlert from "react-notification-alert";

import { getNonce } from "../../reducers/currentNonce";
import "./rulesmodal.scss";

const RulesModal = (props) => {

    const { getAccountRules } = props;
    const [ruleName, setRuleName] = useState("");
    const [vault, setVault] = useState("");
    const [txLimit, setTxLimit] = useState(null);
    const [memberList, setMemberList] = useState([]);
    const [approverCount, setApproverCount] = useState([]);
    const [rejectorCount, setRejectorCount] = useState([]);
    const [approverAddress, setApproverAddress] = useState([]);
    const [rejectorAddress, setRejectorAddress] = useState([]);
    const [vault_list, setCurrentVaults] = useState(null);
    const [rule_tx_type, setRuleTxType] = useState("1513");
    const [calledOnce, setCalledOnce] = useState(false);

    const slider1Ref = useRef(null);
    const slider2Ref = useRef(null);

    let history = useHistory();
    let { id } = useParams();
    const nonce = useSelector(state => state.currentNonce);
    const dispatch = useDispatch();
    const notificationAlertRef = useRef(null);



    const isFormReadyToSubmit = () => {
        console.log("getting called once", approverCount, rejectorCount);
        if (ruleName === "") {
            notify("tr", "Please enter Rule Name");
            return false;
        } else if (vault === "") {
            notify("tr", "Please Choose a Vault on which you want to apply the Rule");
            return false;
        } else if (txLimit === null) {
            notify("tr", "Please Choose a Tx Limits for which to apply the Rule");
            return false;
        } else if (!approverAddress || (approverAddress && Array.isArray(approverAddress) && approverAddress.length === 0)) {
            notify("tr", "Please add at least one Team Member as an Approver");
            return false;
        } else if (!rejectorAddress || (rejectorAddress && Array.isArray(rejectorAddress) && rejectorAddress.length === 0)) {
            notify("tr", "Please add at least one Team Member as a Rejector");
            return false;
        } else if (!approverCount || (approverCount && Array.isArray(approverCount) && parseInt(approverCount[0]) === 0)) {
            notify("tr", "Please Choose the minimum # of Approvals");
            return false;
        } else if (!rejectorCount || (rejectorCount && Array.isArray(rejectorCount) && parseInt(rejectorCount[0]) === 0)) {
            notify("tr", "Please Choose the minimum # of Rejections");
            return false;
        } else if ((approverAddress && Array.isArray(approverAddress) && approverAddress.length !== 0) && (approverCount > approverAddress.length)) {
            notify("tr", "Either Increase the # of Approvers or Decrease the minimum # of Approvals Required.");
            return false;
        } else if ((rejectorAddress && Array.isArray(rejectorAddress) && rejectorAddress.length !== 0) && (rejectorCount > rejectorAddress.length)) {
            notify("tr", "Either Increase the # of Rejectors or Decrease the minimum # of Rejections Required.");
            return false;
        } else {
            return true;
        }
    }

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Rule Created',
            'success'
        )
    }

    useEffect(() => {
        (async () => {
            dispatch(getNonce());
            await getOrganizationMember();
            if(id !== "new" && calledOnce === false) {
                await getCurrentRule();
            }
            await getCurrentVaults();
        })();

        const timeout = setTimeout(() => {
            let slider1 = slider1Ref.current;
            let slider2 = slider2Ref.current;
            if (slider1.className === "slider") {
                Slider.create(slider1, {
                    start: [approverCount],
                    connect: [true, false],
                    step: 1,
                    range: { min: 0, max: 5 },
                });

                slider1.noUiSlider.on('update', (value) => {
                    document.getElementsByClassName("noUi-touch-area")[0].setAttribute("data-content", Math.round(slider1.noUiSlider.get()));
                    setApproverCount(value);
                });
            }

            if (slider2.className === "slider") {
                Slider.create(slider2, {
                    start: [rejectorCount],
                    connect: [true, false],
                    step: 1,
                    range: { min: 0, max: 5 },
                });

                slider2.noUiSlider.on('update', (value) => {
                    document.querySelectorAll(".slider-2 .noUi-touch-area")[0].setAttribute("data-content", Math.round(slider2.noUiSlider.get()));
                    setRejectorCount(value);
                });
            }
        }, 5000);

        return () => {
            // Timeout cleanup on Unmount,
            window.clearTimeout(timeout);
        }

    }, [approverCount, rejectorCount]);

    async function getCurrentRule () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.rules}/${id}`
        );

        let rule = await response.json();
        console.log("Rule Data", rule.data);
        setRuleName(rule.name);
        // setVault(rule.vault_address);
        getCurrentVaults(rule.vault.vault_address);
        setApproverCount(rule.threshold_approvals);
        setRejectorCount(rule.threshold_rejections);
        const approverMembers = rule.approvers.map(elem => (
            {
                ...elem,
                label: elem.member_name,
                value: elem.member_address,
                name: elem.member_name
            }
        ));
        
        const rejectorMembers = rule.rejectors.map(elem => (
            {
                ...elem,
                label: elem.member_name,
                value: elem.member_address,
                name: elem.member_name
            }
        ));
        setApproverAddress(approverMembers);
        setRejectorAddress(rejectorMembers);
        setTxLimit({value: rule.max, label: `From ${rule.min} ${config.price_unit} to ${rule.max} ${config.price_unit}`, limit: [rule.min, rule.max]});
        setCalledOnce(true);
    }

    async function getOrganizationMember () {
        const response = await fetch(
            `${config.api_url}/${config.v}/organisations/${localStorage.getItem("org_hash")}/${config.endpoints.members}`
        );

        let org_member_list = await response.json();
        org_member_list.data.unshift({
            value: "",
            label: "Choose Team Members",
            name: "Choose Team Members",
            isDisabled: true
        });

        org_member_list = org_member_list.data.map(elem => (
            {
                ...elem,
                label: elem.member_name === "" ? "Root Admin" : elem.member_name,
                value: elem.member_address,
                name: elem.member_name === "" ? "Root Admin" : elem.member_name
            }
        ))
        console.log("Org member List ", org_member_list);
        setMemberList(org_member_list);
    }

    async function getCurrentVaults (vault_address) {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${config.endpoints.vaults}?address=${localStorage.getItem("ws_address")}&organisationHash=${localStorage.getItem("org_hash")}&limit=100`
        );

        let vault_list = await response.json();
        console.log("vault Data ", vault_list.data);

        let useAbleList = [];
        useAbleList.unshift({
            value: "",
            label: "Choose a Source Vault",
            name: "Choose a Source Vault",
            isDisabled: true,
        });

        vault_list.data.map((vault, innerIndex) => {
            useAbleList.push({
                value: "",
                label: vault.vault_name,
                name: vault.vault_name,
                address: vault.vault_address
            })
        });
        console.log("Vault List", useAbleList);
        setCurrentVaults(useAbleList);
        if(vault_address) {
            console.log("current Vault =>", useAbleList.find((item) => {
                return (item.address === vault_address);
            }), vault_address);
            setVault(useAbleList.find(item => item.address === vault_address));
        }
    }

    const notify = (place, msg) => {
        var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b> - Error
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-alert-circle-exc",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        const json = await response.json();

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            notify("tr", json.message);
            return;
        } else {
            getAccountRules();
            openPop();
            // notify("successful transaction");
            // dispatch(setCurrentMemberView("member-list"));
            history.push("/admin/governance");
        }
        
        console.log("Rule Created Successfully", json);
    }

    const createRuleData = () => {

        if (isFormReadyToSubmit() === false) {
            return false;
        }

        console.log("passing form submit how");
        const approvers = [];
        const rejectors = [];
        approverAddress.map((elem, index) => {
            approvers.push(elem.member_address);
        });
        rejectorAddress.map((elem, index) => {
            rejectors.push(elem.member_address);
        });
        console.log("Rule Data Form",
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            vault.address,
            ruleName,
            approvers,
            rejectors,
            parseInt(approverCount),
            parseInt(rejectorCount),
            0,
            parseInt(txLimit.limit[0]),
            parseInt(txLimit.limit[1]),
            "usd")

        const tx = new CreateRuleTransferTransaction(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            vault.address,
            "",
            ruleName,
            approvers,
            rejectors,
            parseInt(approverCount[0]),
            parseInt(rejectorCount[0]),
            parseInt(txLimit.limit[0]),
            parseInt(txLimit.limit[1]),
            "usd",
            parseInt(rule_tx_type)
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    }

    const updateRuleData = () => {
        if (isFormReadyToSubmit() === false) {
            return false;
        }
        const approvers = [];
        const rejectors = [];
        approverAddress.map((elem, index) => {
            approvers.push(elem.member_address);
        });
        rejectorAddress.map((elem, index) => {
            rejectors.push(elem.member_address);
        });
        console.log("Rule Data Form",
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            vault.address,
            ruleName,
            approvers,
            rejectors,
            parseInt(approverCount),
            parseInt(rejectorCount),
            0,
            parseInt(txLimit.limit[0]),
            parseInt(txLimit.limit[1]),
            "usd")

        const tx = new UpdateRuleTransferTransaction(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            vault.address,
            "",
            id,
            ruleName,
            approvers,
            rejectors,
            parseInt(approverCount),
            parseInt(rejectorCount),
            parseInt(txLimit.limit[0]),
            parseInt(txLimit.limit[1]),
            "usd"
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    }

    const deleteRuleData = () => {
        const tx = new DeleteRuleTransferTransaction(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            vault.address,
            "",
            id
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
        });
    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <div className="rules-modal">
                <div className="modal-header justify-content-left">
                    <h6 className="title title-up">Rule Details</h6>
                </div>
                <ModalBody className="justify-content-left connect-exchange-modal">
                    <div>

                        <Row>
                            <Col md="12">
                                <Form id="RegisterValidation">
                                    <Card className="rule-set">
                                        <CardBody>
                                            <FormGroup>
                                                <label>Rule Name<sup className="required-label">*</sup></label>
                                                <Input
                                                    name="rule"
                                                    id="rule_name"
                                                    type="text"
                                                    value={ruleName}
                                                    placeholder="Enter the New Rule Name"
                                                    onChange={(e) => setRuleName(e.target.value)}
                                                />
                                                {/*<span className="error_m">*required</span>*/}
                                            </FormGroup>
                                            <FormGroup className="calc-width">
                                                <label>Vault Address<sup className="required-label">*</sup></label>
                                                {/*<Input*/}
                                                {/*    name="master_wallet"*/}
                                                {/*    id="master_wallet"*/}
                                                {/*    type="text"*/}
                                                {/*    value={vaultAddress}*/}
                                                {/*    placeholder="Enter Vault Address"*/}
                                                {/*    onChange={(e) => setVaultAddress(e.target.value)}*/}
                                                {/*/>*/}
                                                {
                                                    vault_list && vault_list.length > 0 &&
                                                    <Col lg="12" md="12" sm="3">
                                                        <Select
                                                            style={{color: "#fff"}}
                                                            className="react-select info"
                                                            classNamePrefix="react-select"
                                                            name="vault"
                                                            value={vault}
                                                            onChange={(value) => {
                                                                console.log("value", value);
                                                                setVault(value);
                                                            }}
                                                            getOptionLabel={value => (
                                                                <div>
                                                                    <img
                                                                        className="asset-logo-icon"
                                                                        src={require("assets/img/vault-transparent.png").default}
                                                                        alt=""
                                                                    />
                                                                    <span>{value.name}</span>
                                                                </div>
                                                            )}
                                                            options={vault_list}
                                                            placeholder="Choose Vault on which you want to apply Rules"
                                                        />
                                                        {/*<span className="error_m">*required</span>*/}
                                                    </Col>
                                                }
                                            </FormGroup>



                                            <div className="rules-form-elements calc-width">
                                                <label>Amount Limit<sup className="required-label">*</sup></label>
                                                <Col lg="12" md="12" sm="3">
                                                    <Select
                                                        className="react-select info"
                                                        classNamePrefix="react-select"
                                                        name="txLimit"
                                                        value={txLimit}
                                                        onChange={(value) => {
                                                            console.log("single min max", value);
                                                            setTxLimit(value);
                                                        }}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Choose Transaction Limits",
                                                                isDisabled: true,
                                                            },
                                                            { value: "0", label: `From 0 ${config.price_unit} to 10,000 ${config.price_unit}`, limit: ["0", "10000"] },
                                                            { value: "1000", label: `From 10000 ${config.price_unit} to 25000 ${config.price_unit}`, limit: ["10000", "25000"] },
                                                            { value: "25000", label: `From 25000 ${config.price_unit} to 50000 ${config.price_unit}`, limit: ["25000", "50000"] },
                                                            { value: "50000", label: `From 50,000 ${config.price_unit} to 100,000 ${config.price_unit}`, limit: ["50000", "100000"] },
                                                            { value: "100000", label: `From 100,000 ${config.price_unit} to 250,000 ${config.price_unit}`, limit: ["100000", "250000"] },
                                                            { value: "250000", label: `From 250,000 ${config.price_unit} to 500,000 ${config.price_unit}`, limit: ["250000", "500000"] },
                                                            { value: "500000", label: `From 500,000 ${config.price_unit} to 1,000,000 ${config.price_unit}`, limit: ["500000", "1000000"] },
                                                            { value: "1000000", label: `From 1,000,000 ${config.price_unit} to 5,000,000 ${config.price_unit}`, limit: ["1000000", "5000000"] },
                                                            { value: "5000000", label: `From 5,000,000 ${config.price_unit} to 10,000,000 ${config.price_unit}`, limit: ["5000000", "10000000"] },
                                                            { value: "max", label: `More than 10,000,000 ${config.price_unit}` },
                                                        ]}
                                                        placeholder="Choose Transaction Limits"
                                                    />
                                                    {/*<span className="error_m">*required</span>*/}
                                                </Col>
                                            </div>

                                            <div className="rules-form-elements calc-width">
                                                <label>Approvers<sup className="required-label">*</sup></label>
                                                <Col lg="12" md="12" sm="3">
                                                    <Select
                                                        className="react-select info"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Approvers "
                                                        name="approverAddress"
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        value={approverAddress}
                                                        onChange={(value) => {
                                                            console.log("value", value)
                                                            setApproverAddress(value)
                                                        }
                                                    }
                                                        options={memberList}
                                                    />
                                                    {/*<span className="error_m">*required</span>*/}
                                                </Col>
                                            </div>

                                            <div className="rules-form-elements slider-elem">
                                                <label>Min. Approvals<sup className="required-label">*</sup></label>
                                                <Col md="6" className="slider-1">
                                                    <div className="slider" ref={ slider1Ref } />
                                                </Col>
                                                {/*<span className="error_m">*required</span>*/}
                                            </div>

                                            <div className="rules-form-elements calc-width">
                                                <label>Rejectors<sup className="required-label">*</sup></label>
                                                <Col lg="12" md="12" sm="3">
                                                    <Select
                                                        className="react-select info"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Rejectors"
                                                        name="rejectorAddress"
                                                        closeMenuOnSelect={false}
                                                        isMulti
                                                        value={ rejectorAddress }
                                                        onChange={(value) => setRejectorAddress(value)}
                                                        options={memberList}
                                                    />
                                                    {/*<span className="error_m">*required</span>*/}
                                                </Col>
                                            </div>

                                            <div className="rules-form-elements slider-elem">
                                                <label>Min. Rejections<sup className="required-label">*</sup></label>
                                                <Col md="6"  className="slider-2">
                                                    <div className="slider" ref={slider2Ref} />
                                                </Col>
                                                {/*<span className="error_m">*required</span>*/}
                                            </div>

                                            <div className="rules-form-elements">
                                                <label>Rules Applied On<sup className="required-label">*</sup></label>
                                                <div className="applied-rules">
                                                    <FormGroup check className="form-check-radio form-check-inline">
                                                        <Label check>
                                                            <Input
                                                                defaultChecked
                                                                defaultValue="1513"
                                                                id="exampleRadios1"
                                                                name="exampleRadios"
                                                                type="radio"
                                                                value={ rule_tx_type }
                                                                onChange={() => {
                                                                    console.log("test, 1513");
                                                                    setRuleTxType("1513");
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                            VAULTS
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check className="form-check-radio form-check-inline" disabled id="tooltipgetearlyaccess1233">
                                                        <Label check>
                                                            <Input
                                                                defaultValue="1533"
                                                                id="exampleRadios2"
                                                                name="exampleRadios"
                                                                type="radio"
                                                                disabled
                                                                value={rule_tx_type}
                                                                onChange={() => {
                                                                    console.log("test, 1533");
                                                                    setRuleTxType("1533");
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                            INVOICE DISCOUNTING
                                                        </Label>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target="tooltipgetearlyaccess1233"
                                                        >
                                                            Contact to Get Early Access
                                                        </UncontrolledTooltip>
                                                    </FormGroup>
                                                </div>
                                            </div>

                                        </CardBody>

                                        <CardFooter className="text-right">
                                            <FormGroup check className="pull-left">
                                                <Label check>
                                                    &nbsp;
                                                </Label>
                                            </FormGroup>
                                            {
                                                id && id === "new" &&
                                                <Button color="primary" onClick={createRuleData}>
                                                    Create Rule
                                                </Button>
                                            }
                                            {
                                                id && id !== "new" &&
                                                <div>
                                                    <Button color="primary" onClick={updateRuleData}>
                                                        Update Rule
                                                    </Button>
                                                    <Button color="primary" onClick={deleteRuleData}>
                                                        Archive Rule
                                                    </Button>
                                                </div>

                                            }
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </div>
        </>
    );
};

export default RulesModal;
