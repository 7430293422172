import * as bip39 from "bip39";
import { Conversion } from './conversion';

export class Mnemonic {
  // generate 12 word random mnemonic
  public static generateMnemonic(): string {
    return bip39.generateMnemonic();
  }
  // generate 512-bit seed from mnemonic
  // @param mnemonic - mnemonic to generate seed from
  public static generateSeedFromMnemonic(mnemonic: string): Buffer {
    return bip39.mnemonicToSeedSync(mnemonic);
  }

  public static mnemonicToSeedHex(mnemonic: string): string {
    var seed = Mnemonic.generateSeedFromMnemonic(mnemonic);
    return Conversion.bytesToHex(seed);
  }

  public static isValidMnemonic(mnemonic: string): boolean {
    return bip39.validateMnemonic(mnemonic);
  }
}
