import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { CreateLendingPoolData } from "./data/data_lending_pool_create";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class CreateLendingPoolTransaction extends Transaction {

  // type: number = TransactionTypeEnum.TX_TYPE_LENDING_POOL_CREATE;
  type: number = 1514

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, name: string, collateralAssetID: number, borrowAssetID: number, lendingRate: number, collateralRatio: number, liquidationThresold: number, allowExternal: boolean, maxLoanPeriod: number, assetPriceOracle: string, priceUnit: string) {
    super();
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new CreateLendingPoolData(name, collateralAssetID, borrowAssetID, lendingRate, collateralRatio, liquidationThresold, allowExternal, maxLoanPeriod, assetPriceOracle, priceUnit).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    console.log("payload", payload, payload.sender);
    this.payload = payload.toBase64();
  }
}
