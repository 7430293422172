import { Base64 } from "../../base64";

export class EditOrganisationMemberPermissionData {
  address: string;
  txTypes: Array<Number>;
  vaults: Array<string>;
  
  constructor(address: string, txTypes: Array<Number>, vaults: Array<string>) {
    this.address = address;
    this.txTypes = txTypes;
    this.vaults = vaults;
  }

  public static fromJson(json: any): EditOrganisationMemberPermissionData {
    return new EditOrganisationMemberPermissionData(
      json["address"],
      json["tx_types"],
      json["vaults"],
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "address": this.address,
      "tx_types": this.txTypes,
      "vaults": this.vaults,
    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
