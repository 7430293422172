export const config = {
    "api_url": "https://api.devnet.kapow.tech",
    // "api_url": "https://85e1-2409-4072-99e-981-6fca-e35f-d2d2-fdca.ngrok-free.app",
    "socket_url": "wss://d2d1-103-87-59-240.ngrok.io/ws",
    // "api_url": "https://c2f0-2401-4900-1f39-6b87-2d78-3f68-7729-ee47.in.ngrok.io",
    "price_api_url": "https://pricing.devnet.kapow.tech",
    // "chart_api": "https://167.99.67.97:8080",
    "chart_api": "https://portfolio.devnet.kapow.tech",
    "eth_gas_api": "https://gas.devnet.kapow.tech/v1/ethereum/goerli",
    "btc_gas_api": "https://gas.devnet.kapow.tech/v1/bitcoin/testnet",
    "kapow_gas_api": "https://gas.devnet.kapow.tech/v1/kapow/testnet",
    "v": "v1",
    "wallet": "ZgcFRXoQycKZSxYNH2zyFVxMz9DSCcgWpmLLdSP1ZsuWh3CY",
    "multi_account_address": "UANsB6wefEoqFvRhFawmwi7nfSovcU9hqWfvDATbE4KG2wDu",
    // "multi_account_address": "Um3zAVWr8r5T5oRDgBrKVnrYymRgSoUihVN74QMDZcRWvTLo",
    "oracle_address": "TvLWKuYnQVEDg3VsbNhdZ6zrj53S5kSPd4DoEqegebcWf9oR",
    "price_unit": "INR",
    "price_symbol": "₹",
    "endpoints": {
        "blocks": "blocks",
        "summary": "summary",
        "transactions": "transactions",
        "addresses": "addresses",
        "portfolio": "portfolio",
        "accounts": "accounts",
        "assets": "assets",
        "lending_pools": "lending_pools",
        "vaults": "vaults",
        "vault": "vault",
        "organisation": "organisation",
        "members": "members",
        "members_invite": "membersinvite",
        // "members_invite": "members",
        "rules": "rules"
    },
    "price_endpoints": {
        "coins": "coins"
    },
    "hack": {
            "mnemonic": "exile valley device replace juice anxiety balance direct order wine silver kiwi",
            "address": "ZgcFRXoQycKZSxYNH2zyFVxMz9DSCcgWpmLLdSP1ZsuWh3CY"
        }
}
