// The Keccak hash function
import bs58 from 'bs58'

export class Base58 {
  // Keccak-256 hash
  // @param data - The data to hash
  // @returns The hash of the data
  public static encode(data: Buffer | Uint8Array): string {
    return bs58.encode(data);
  }

  public static decode(data: string): Buffer {
    return bs58.decode(data);
  }
}
