import React, {useEffect, useState, useRef} from "react";
import Identicon from 'react-identicons';

import {
    UncontrolledTooltip,
} from "reactstrap";

const ActionTakers = (props) => {
    return (
        <>
            <div>
                <span style={{textTransform: "capitalize"}}>{props.actionTaker}</span>
                <div className="identicon-list">
                    {
                        props.rules.map((rule, index) => (
                            rule[props.actionTaker].map((actionTaker, newIndex) => (<div id={ props.id }>
                                <Identicon
                                    string={ actionTaker.member_name }
                                />
                                <UncontrolledTooltip
                                    delay={0}
                                    target={ props.id }
                                >
                                    { actionTaker.member_name }
                                </UncontrolledTooltip>
                            </div>))
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default ActionTakers;
