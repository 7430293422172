import React, { useEffect, useState } from "react";
import { Route, Switch, Link, useParams, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// import {KeyPair, createAddress } from "kapow-sdk";
// New Kapow Code - Shubham Sharma
import { config } from "../config";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    FormGroup,
    Input,
    Progress,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    ModalBody,
    Breadcrumb,
    BreadcrumbItem,
    Alert, Form
} from "reactstrap";

// Functional View
import AssetList from "./Assets/AssetList";
import NameClip from "../components/NameClip/NameClip";
import {totalVaultAmount, totalXchangeAmount} from "../reducers/totalAmount";
import  { clip_names, getSimpleDate } from "../variables/utils";
import {Bar, Line, Pie } from "react-chartjs-2";
import  {chartExample10, chartExample2, chartExample3 } from "../variables/charts";
import { formatAmount } from "./../variables/cutils";
import "./portfolio.scss";

const Portfolio = () => {

    // const key_pair = KeyPair.generateKeyPairFromMnemonic(config.hack.mnemonic);
    // const x = createAddress(3, key_pair.publicKey);
    // console.log("bitcoin address", x);

    const totalVaultAmount = useSelector(state => state.totalVaultAmount)
    const totalXchangeAmount = useSelector(state => state.totalXchangeAmount)

    const [modalClassic, setModalClassic] = useState(false);
    const [modalClassic1, setModalClassic1] = useState(false);

    const [currentView, setCurrentView] = useState("vault")
    const [portfolioData, setPortfolioData] = useState([]);

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };

    let { path } = useRouteMatch();
    let { id } = useParams();

    useEffect(() => {
        (async () => {
                await getPortfolioData();
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    async function getPortfolioData () {
        const response = await fetch(
            `${config.chart_api}/${config.v}/${config.endpoints.portfolio}?address=${localStorage.getItem("org_hash")}&query=7d`
        );

        let portfolio_data = await response.json();
        // console.log("filtered portfolio data", portfolio_data.map((item) => item['created_at'].toString()));
        // console.log("filtered portfolio data", portfolio_data.map((item) => item['portfolio_value']));

        chartExample2.setLabel(portfolio_data.map((item) => getSimpleDate( new Date(item['created_at'] * 1000 ))));
        chartExample2.setPData(portfolio_data.map((item) => item['portfolio_value']));
        console.log("portfolio_data -> ", portfolio_data);
        setPortfolioData(portfolio_data);
    }

    return (
        <>
            <div className="content accounts-view">
                <Row>
                    <Col lg="8">
                        <Card className="card-chart">
                            <CardHeader>
                                <h5 className="card-category">Holdings</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-bell-55 text-primary" />
                                    {
                                        totalVaultAmount &&
                                        <span className="total-amount">
                                            { formatAmount(totalVaultAmount) }
                                        </span>
                                    }
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Line
                                        id="line-chart-portfolio"
                                        data={ chartExample2.data }
                                        options={chartExample2.options}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="mr-auto" lg="4" md="5">
                        <Card className="card-chart card-chart-pie">
                            <CardHeader>
                                <h5 className="card-category">Asset Allocation</h5>
                            </CardHeader>
                            <CardBody style={{paddingBottom: "27px"}}>
                                <Row>
                                    <Col>
                                        <div className="chart-area">
                                            <Pie
                                                data={chartExample10.data}
                                                options={chartExample10.options}
                                            />
                                            <ul className="holding-legends" style={{position: "absolute", bottom: 0, left: "-10px", listStyleType: "none"}}>
                                                <li style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                    <span style={{display: "inline-block", width: "15px", height: "10px", backgroundColor: "orange"}}>&nbsp;</span>
                                                    <span style={{fontSize: "11px", marginLeft: "7px"}}>BTC</span>
                                                </li>
                                                <li style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                    <span style={{display: "inline-block", width: "15px", height: "10px", backgroundColor: "grey"}}>&nbsp;</span>
                                                    <span style={{fontSize: "11px", marginLeft: "7px"}}>ETH</span>
                                                </li>
                                                <li style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                    <span style={{display: "inline-block", width: "15px", height: "10px", backgroundColor: "lightblue"}}>&nbsp;</span>
                                                    <span style={{fontSize: "11px", marginLeft: "7px"}}>Xaults</span>
                                                </li>
                                                <li style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                    <span style={{display: "inline-block", width: "15px", height: "10px", backgroundColor: "#1f8ef1"}}>&nbsp;</span>
                                                    <span style={{fontSize: "11px", marginLeft: "7px"}}>LINK</span>
                                                </li>
                                            </ul>

                                        </div>
                                    </Col>
                                    {/*<Col xs="6">*/}
                                    {/*    <CardTitle tag="h4">*/}
                                    {/*        <i className="tim-icons icon-tag text-warning" /> 130,000*/}
                                    {/*    </CardTitle>*/}
                                    {/*    <p className="category">Feedback from 20.000 users</p>*/}
                                    {/*</Col>*/}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Portfolio;
