import { Base64 } from "../base64";
import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { CreateInvoiceDiscountingProposalData } from "./data/data_invoice_discounting_proposal_create";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class CreateInvoiceTransactionProposal extends Transaction {
  
  // type: number = TransactionTypeEnum.TX_TYPE_ORGANISATION_CREATE;
  type: number = 1530;

  // data variables
  name: string;

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, maxDiscountingProposalPrecentage: number, feesPercent: number) {
    super();   
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new CreateInvoiceDiscountingProposalData(maxDiscountingProposalPrecentage, feesPercent).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();
  }
}
