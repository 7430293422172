export function totalXchangeAmount(state= 0, action) {
    /*console.log("Inside reducer", action.type, action.payload)*/
    switch(action.type){
        case 'SET_TOTAL_XCHANGE_AMOUNT':
            return action.payload;
        default:
            return state;
    }
}

export function totalVaultAmount(state= 0, action) {
    switch(action.type){
        case 'SET_TOTAL_VAULT_AMOUNT':
            return action.payload;
        default:
            return state;
    }
}