import { Base64 } from "../base64";
import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { CreatePBMProgramPoolData } from "./data/data_pbm_program_pool_create";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class CreatePBMProgramPoolTransaction extends Transaction {
  
  // type: number = TransactionTypeEnum.TX_TYPE_ORGANISATION_CREATE;
  type: number = 1538;


  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, name: string, pbmProgramHash: string, collateralAssetID: number, pbmAssetID: number, invalidMCC: string, assetPriceOracle: string, priceUnit: string) {
    super();
    
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    
    var data = new CreatePBMProgramPoolData(name, pbmProgramHash, collateralAssetID, pbmAssetID, invalidMCC, assetPriceOracle, priceUnit).toBase64();
    
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();    
  }
}
