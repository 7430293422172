import { Base64 } from "../../base64";

export class InvoiceDiscountingTransferData {
  invoiceHash: string;
  invoiceDiscountingProposalHash: string;
  from: string;
  to: string;
  assetID: number;
  quantity: string; //hex encoded string
  gasPrice: string; // gas price - hex value
  gasLimit: number; // gas limit

  constructor(invoiceHash: string, invoiceDiscountingProposalHash: string, from: string, to: string, assetID: number, quantity: string, gasPrice: string, gasLimit: number) {
    this.invoiceHash = invoiceHash;
    this.invoiceDiscountingProposalHash = invoiceDiscountingProposalHash;
    this.from = from;
    this.to = to;
    this.assetID = assetID;
    this.quantity = quantity;
    this.gasPrice = gasPrice;
    this.gasLimit = gasLimit;
  }

  public static fromJson(json: any): InvoiceDiscountingTransferData {
    return new InvoiceDiscountingTransferData(
      json["invoice_hash"],
      json["invoice_discounting_proposal_hash"],
      json["from"],
      json["to"],
      json["asset_id"],
      json["quantity"],
      json["gas_price"],
      json["gas_limit"]
    );
  }

  public toJson(): string {
    return JSON.stringify({
      "invoice_hash": this.invoiceHash,
      "invoice_discounting_proposal_hash": this.invoiceDiscountingProposalHash,
      "from": this.from,
      "to": this.to,
      "asset_id": this.assetID,
      "quantity": this.quantity,
      "gas_price": this.gasPrice,
      "gas_limit": this.gasLimit
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
