import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {config} from "../config";

import {
    Button,
    CardBody,
    Progress,
    Table,
    UncontrolledTooltip,
} from "reactstrap";

const Insights = (props) => {

    useEffect(() => {

    }, []);


    return (
        <>
            <div>
                Magical Insights.
            </div>
        </>
    );
};

export default Insights;
