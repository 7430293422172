import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux"
import store from "./store"

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";

Sentry.init({
    dsn: "https://7e9abac7c3fddd50431038a46e8014c7@o4505635063726080.ingest.sentry.io/4505635084107776",
    integrations: [
        new BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
    return (
        <Provider store={store}>
            <HashRouter>
                <Switch>
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                    <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
                    <Redirect from="/" to="/admin/vaults" />
                </Switch>
            </HashRouter>
        </Provider>
    )
}

ReactDOM.render(<App />, document.getElementById("root"));
