export const tx_status = {
    // approver and rejector are required to approve or reject the transaction
    599: "Pending Action",
    // transaction is confirmed on kapow and no further action is required from our "ANT"
    600: "Confirmed",
    // transaction confirmed on kapow but need to be processed by our "ANT"
    601: "Transaction Created",
    // transaction taken up for processing by our "ANT"
    602: "Tx Signing In Progress",
    // transaction failed to be processed by our "ANT"
    603: "ANT Processing Failed ",
    // transaction processed by our "ANT" but not yet confirmed on other chains
    604: "TX_STATUS_PENDING_LAYER1",
    // transaction is failed on kapow
    605: "Failed",
    606: "Transaction Broadcasting",
    607: "Transaction Broadcasting Failed",
    608: "Transaction Failed Layer 1 Confirmation",
    609: "MPC Signing Complete",
    612: "Rejected",

    // Will created successfully on kapow blockchain
    650: "Will Created Successfully",
    // Will start execution (ANT needs to process the will)
    651:  "Will Execution Started",
    // Will to be  taken up for processing by our "ANT"
    652: "Will Processing(ANT)",
    // Will failed to be processed by our "ANT"
    653: "Will Processing Failed(ANT)",
    // Will processed by our "ANT" but not yet confirmed on other chains
    654: "Will Processed(ANT)",

    700: "Transaction Successful on Layer 1",
    701: "1 Confirmation",
    702: "2 Confirmations",
    703: "3 Confirmations",
    704: "4 Confirmations",
    705: "5 Confirmations",
    706:  "6 Confirmations",
    707: "7 Confirmations",
    708: "8 Confirmations",
    709: "9 Confirmations",
    710: "10 Confirmations",
    711: "11 Confirmations",
    712: "12 Confirmations",

    901: "Blockchain - Active",
    902: "Blockchain - Inactive",

    801: "Asset Status Active",
    802: "Asset Status Inactive"
}

export const tx_types = {
    1001: "Genesis Transaction",
    1002: "Create Account",
    1003: "Update Account",
    1004: "Transfer",
    1005: "Create Asset",
    1006: "Issue Asset",
    1007: "Transfer Asset",
    1008: "Update Asset",
    1009: "Update Contract",
    1010: "Invoke Contract",
    1011: "Created Contract",
    1012: "Transfer Contract",
    1013: "Approve Contract",
    1014: "Create Fungible Token",
    1015: "Create Non Fungible Token",
    1016: "Create Blockchain",
    1017: "Create Address",
    1018: "Assign Address",
    1019: "Update Transaction Status",
    1020: "Create Will",
    1021: "Execute Will",
    1022: "Update Presence",
    1023: "Update Balance",
    1024: "Update Will Status",
    1025: "Create Multi Account",
    1026: "Transaction Approved",
    1027: "Delete Transaction",
    1028: "Transaction Failed",
    1029: "Delete Reject Transaction",
    1030: "Create Ignore Transaction",
    1031: "Delete Ignore Transaction",
    1032: "Add Account Key",
    1035: "External Transfer - Incoming",

    1044: "Oracle Asset Price Data",
    // 1500: "Start Range MUA Tx",
    1599: "End Range MUA Tx",
    1500: "Organization Created[Tx]",
    1501: "Organization Updated[Tx]",
    1502: "Organization Deleted[Tx]",
    1503: "Organization Member Invited[Tx]",
    1504: "Organization Member Confirmed[Tx]",
    // member
    1505: "Organization Remove Member[Tx]",
    1506: "ORGANISATION UPDATE MEMBER STATUS[Tx]",
    1507: "ORGANISATION ADD PERMISSION[Tx]",
    1508: "ORGANISATION REMOVE PERMISSION[Tx]",
    1509: "Create Vault",
    1510: "Update Rule",
    1511: "Vault Add Member",
    1513: "Vault Transfer",
    1520: "Rule Created",
    1521: "Rule Updated",
    1522: "Vault Archived",
    1530: "Created - Invoice Discounting Proposal [Contract]",
    1531: "Submitted - Invoice [Contract]",
    1532: "Accepted - Invoice Discounting Proposal [Tx]",
    1533: "Approved - INVOICE DISCOUNTING TRANSFER [Tx]",
}

export const gasValues = [
    {id: 1, price: "0", limit: 0},
    {id: 2, price: "0xb9bfe2922", limit: 21000},
    {id: 3, price: "0x1", limit: 10000},
]


export const MCC_LIST = [
    {
        "mcc": "9399",
        "description": "Government Services, Not Elsewhere Classified",
        "label": "Government Services, Not Elsewhere Classified",
        "name": "Government Services, Not Elsewhere Classified",
        "value": "9399"
    },
    {
        "mcc": "6540",
        "description": "Debit Card to Wallet Credit",
        "label": "Debit Card to Wallet Credit",
        "name": "Debit Card to Wallet Credit",
        "value": "6540"
    },
    {
        "mcc": "4900",
        "description": "Utilities-Electric/Gas/Heating Oil/Sanitary/Water",
        "label": "Utilities-Electric/Gas/Heating Oil/Sanitary/Water",
        "name": "Utilities-Electric/Gas/Heating Oil/Sanitary/Water",
        "value": "4900"
    },
    {
        "mcc": "5399",
        "description": "Miscellaneous General Merchandise Stores",
        "label": "Miscellaneous General Merchandise Stores",
        "name": "Miscellaneous General Merchandise Stores",
        "value": "5399"
    },
    {
        "mcc": "5816",
        "description": "Digital Goods: Games",
        "label": "Digital Goods: Games",
        "name": "Digital Goods: Games",
        "value": "5816"
    },
    {
        "mcc": "4814",
        "description": "Telecommunication Services",
        "label": "Telecommunication Services",
        "name": "Telecommunication Services",
        "value": "4814"
    },
    {
        "mcc": "7399",
        "description": "Business Services, Not Elsewhere Classified",
        "label": "Business Services, Not Elsewhere Classified",
        "name": "Business Services, Not Elsewhere Classified",
        "value": "7399"
    },
    {
        "mcc": "5814",
        "description": "Fast Food Restaurants",
        "label": "Fast Food Restaurants",
        "name": "Fast Food Restaurants",
        "value": "5814"
    },
    {
        "mcc": "8220",
        "description": "Colleges, Universities, Professional Schools and Jr.",
        "label": "Colleges, Universities, Professional Schools and Jr.",
        "name": "Colleges, Universities, Professional Schools and Jr.",
        "value": "8220"
    },
    {
        "mcc": "4112",
        "description": "Passenger Railways",
        "label": "Passenger Railways",
        "name": "Passenger Railways",
        "value": "4112"
    },
    {
        "mcc": "5411",
        "description": "Grocery Stores, Supermarkets",
        "label": "Grocery Stores, Supermarkets",
        "name": "Grocery Stores, Supermarkets",
        "value": "5411"
    },
    {
        "mcc": "6300",
        "description": "Insurance - Sales and Underwriting",
        "label": "Insurance - Sales and Underwriting",
        "name": "Insurance - Sales and Underwriting",
        "value": "6300"
    },
    {
        "mcc": "6513",
        "description": "Real Estate Agents and Managers - Rentals",
        "label": "Real Estate Agents and Managers - Rentals",
        "name": "Real Estate Agents and Managers - Rentals",
        "value": "6513"
    },
    {
        "mcc": "5699",
        "description": "Miscellaneous Apparel and Accessory Stores",
        "label": "Miscellaneous Apparel and Accessory Stores",
        "name": "Miscellaneous Apparel and Accessory Stores",
        "value": "5699"
    },
    {
        "mcc": "6012",
        "description": "Financial Institutions - Merchandise and Services",
        "label": "Financial Institutions - Merchandise and Services",
        "name": "Financial Institutions - Merchandise and Services",
        "value": "6012"
    },
    {
        "mcc": "4131",
        "description": "Bus Lines",
        "label": "Bus Lines",
        "name": "Bus Lines",
        "value": "4131"
    },
    {
        "mcc": "4111",
        "description": "Local/Suburban Commuter Passenger Transport/Ferries",
        "label": "Local/Suburban Commuter Passenger Transport/Ferries",
        "name": "Local/Suburban Commuter Passenger Transport/Ferries",
        "value": "4111"
    }
];


export const ER = {
    "id": 2,
    "blockchain_id": 2,
    "name": "eRupee-R",
    "symbol": "e₹-R",
    "description": "Tokenised Asset",
    "contract": "",
    "creator": "cnCEFpjcUwQDNx7NAadcGRjKxh6bnjdAJBmgLr1HKc6iML44",
    "decimals": 2,
    "logo": "https://res.cloudinary.com/kapow-tech/image/upload/v1676914208/erupee_vii3is.png",
    "source_code": "",
    "status_code": 801,
    "sub_type": 0,
    "supply": "",
    "type": 7001,
    "website": "",
    "white_paper": ""
}

export const NER = {
    "blockchain_id": 1,
    "contract": "",
    "creator": "b74EybaF5iThBvB68sBqdYDNRiAaxfA5cjNGpUpE9NSBVTH5",
    "decimals": 2,
    "description": "Maruti suzki token",
    "id": 4,
    "logo": "https://res.cloudinary.com/djaxesfqj/image/upload/v1704881733/maruti_kbfe9b.png",
    "name": "Maruti",
    "source_code": "",
    "status_code": 801,
    "sub_type": 0,
    "supply": "0x00",
    "symbol": "MT",
    "type": 7002,
    "website": "",
    "white_paper": ""
}