export function assetList(state= [], action) {
    switch(action.type){
        case 'SET_ASSET_LIST':
            return action.payload;
        default:
            return state;
    }
}

export function currentAssetId(state= 0, action) {
    switch(action.type){
        case 'SET_CURRENT_ASSET_ID':
            return action.payload;
        default:
            return state;
    }
}