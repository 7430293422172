import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { Asset } from '../models/asset.model';
import { Quantity } from '../utils/quantity';
import { MintPBMData } from "./data/data_mint_pbm";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class MintPBM extends Transaction {

  // type: number = TransactionTypeEnum.MINT_PURPOSE_BOUND_MONEY;
  type: number = 1540

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool:string, pbmPool: string, asset:Asset, quantity: string, gasPrice: string, gasLimit: number) {
    super();
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new MintPBMData(pbmPool, Quantity.decimalToHex(asset, quantity),gasPrice, gasLimit).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    console.log("payload", payload, payload.sender);
    this.payload = payload.toBase64();
  }
}
