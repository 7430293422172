import { Base64 } from "../base64";
import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { RemoveOrganisationMemberData } from "./data/data_organisation_member_remove";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class RemoveOrganisationMemberTransaction extends Transaction {
  
  // type: number = TransactionTypeEnum.TX_TYPE_ORGANISATION_REMOVE_MEMBER;
  type: number = 1505;

  // data variables
  memberHash: string;

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, memberHash: string) {
    super();
    // this.nonce = nonce;
    // this.address = address;
    // this.publicKey = publicKey;
    this.memberHash = memberHash;

    var pubKey = Base64.encode(Utils.toBuffer(publicKey));
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new RemoveOrganisationMemberData(this.memberHash).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();
  }
}
