import { Base64 } from "../../base64";

export class CreatePBMProgramData {
  name: string;
  coinName: string;
  coinSymbol: string;
  coinLogo: string;
  description: string;
  
  constructor(name: string, coinName: string, coinSymbol: string, coinLogo: string, description: string) {
    this.name = name;
    this.coinName = coinName; 
    this.coinSymbol = coinSymbol
    this.coinLogo = coinLogo
    this.description = description
  }

  public static fromJson(json: any): CreatePBMProgramData {
    return new CreatePBMProgramData(
      json["name"],
      json["coin_name"],
      json["coin_symbol"],
      json["coin_logo"],
      json["description"]
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "name": this.name,
      "coin_name": this.coinName,
      "coin_symbol": this.coinSymbol,
      "coin_logo": this.coinLogo,
      "description": this.description

    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
