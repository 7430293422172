import { Base64 } from "../base64";
import { Conversion } from "../conversion";
import { Hash } from "../hash";
import { Utils } from "../utils";
import { ActionTransactionData } from "./data/data_action_transaction";
import { Payload } from "./payload";
import { Transaction } from "./transaction";

export class CreateRejectTransaction extends Transaction {
  
  // type: number = TransactionTypeEnum.TX_TYPE_CREATE_REJECT_TX;
  type: number = 1028;

  constructor(nonce: number, sender: string, publicKey: Uint8Array, organisation: string, vault: string, lendingPool: string, txHash: string) {
    super();

    var pubKey = Base64.encode(Utils.toBuffer(publicKey));
    var keyHash = Conversion.bytesToHex(Hash.hash160(Utils.toBuffer(publicKey)));
    var data = new ActionTransactionData(txHash).toBase64();
    var payload = new Payload(nonce, this.type, sender, keyHash, organisation, vault, lendingPool, data);
    this.payload = payload.toBase64();
  }
}
