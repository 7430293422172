import { Base64 } from "../base64";

export class Payload {
  nonce: number; // nonce encoded as a hex string
  type: number;
  sender: string;
  organisation: string; //organisation 
  vault: string;
  lendingPool: string;
  keyHash: string; // 20 bytes hash of the public key encoded as hex
  data: string; // base64 encoded string
  note: string;

  constructor(nonce: number,
    type: number,
    sender: string,
    keyHash: string,
    organisation: string,
    vault: string,
    lendingPool: string,
    data: string,
    note?: string,
    ) {
    this.nonce = nonce;
    this.type = type;
    this.sender = sender;
    this.keyHash = keyHash;
    this.data = data;
    this.organisation = organisation;
    this.vault = vault;
    this.lendingPool = lendingPool;
    this.note = note;

    console.log("this.sender", this.sender);
    console.log("this.paydata", this.data);
  }

  public static fromJson(json: any): Payload {
    return new Payload(
      json["nonce"],
      json["type"],
      json["sender"],
      json["key_hash"],
      json["data"],
      json["organisation"],
      json["vault"],
      json["lending_pool"],
      json["note"],
    );
  }

  public toJson(): string {
    return JSON.stringify({
      "nonce": this.nonce,
      "type": this.type,
      "sender": this.sender,
      "key_hash": this.keyHash,
      "data": this.data,
      "organisation": this.organisation,
      "vault": this.vault,
      "lending_pool": this.lendingPool,
      "note": this.note,
    });
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
