import { Asset } from "../models/asset.model";

export class Quantity {
  static decimalToHex(asset: Asset, quantity: string): string {
    let decimals: number = asset.decimals;
    let mul: number;
    let quant: number;
    let result: number;
    if (asset.decimals < 0) {
      console.log("Asset decimals is less than 0");
      return "0x00";
    }
    //split string
    var arr = quantity.split('.');
    // no decimal places in the amount
    if (arr.length == 1) {
      mul = Number(10)**Number(decimals);
      quant = Number(arr[0]);
      result = quant * mul;
      return "0x" + result.toString(16);
    }
    if (arr.length == 2) {
      let decimalDigits = arr[1].length;
      mul = Number(10)**Number(decimals - decimalDigits);
      quant = Number(arr[0] + arr[1]);
      result = quant * mul;
      return "0x" + result.toString(16);
    }
    console.log("Quantity is invalid");
    return "0x00";
  }

// quantity hex to decimals
  static hexToDecimal(asset: Asset, quantity:string): string{
    let decimals: number = asset.decimals;
    let quant: Number;
    // check if quantity is empty
    if (quantity.trim() == "") {
      return "0.00";
    }
    // check if decimals is null or 0
    if (asset.decimals < 0) {
      console.log("Asset decimals is less than 0");
      return "0x00";
    }

    quant = Number(quantity);
    // bigint to string
    let result = quant.toString(10);
    // prefix zeroes to the string
    result = result.padStart(decimals + result.length, '0');
    // insert decimal point
    result = result.substring(0, result.length - decimals) +
        "." +
        result.substring(result.length - decimals);
    return result;
  }
}
