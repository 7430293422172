/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from "react";
import {Base64, InvoiceDiscountingTransferTransaction, Quantity} from "kapow-sdk";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table,
    Modal,
    ModalBody,
    Alert,
    UncontrolledTooltip,
} from "reactstrap";

import ProposalCreation from "./ProposalCreation/ProposalCreation";
import {Link, Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {config} from "../../../config";
import "./invoices.scss";
import "./invoicecard.scss";
import {calculateDaysBetween, clip_address} from "../../../variables/utils";
import Avatar from "react-avatar";
import ProposalCard from "./ProposalCard";
import Swal from "sweetalert2";
import {tx_status} from "../../../constants";
import ActionTakers from "../../Modals/CurrentTransactionModal/ActionTakers";

const InvoiceCard = (props) => {

    const [showHideProposals, setShowHideProposals] = useState(false);
    const [nonce, setNonce] = useState(0);
    const [linkedInvoices, setLinkedInvoices] = useState(0);
    const [modalClassic, setModalClassic] = useState(false);

    let history = useHistory();
    let { id } = useParams();

    // console.log("proposalList", props.proposalList, props.invoice.proposal, props.proposalList.filter((pro) => (pro.proposal_hash === props.invoice.proposal))[0].proposal_hash);

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Invoice Submitted',
            'success'
        )
    }

    const handleProposal = () => {
        setShowHideProposals(!showHideProposals);
    };

    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    useEffect(() => {
        (async () => {
            await getNonce();
            await getLinkedInvoices();
        })();

        return () => {
        }

    }, []);

    async function getNonce () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${localStorage.getItem("ws_address")}/sequence`
        );

        const nonce = await response.json();
        setNonce(nonce + 1);
    }

    async function getLinkedInvoices () {

        const response = await fetch(
            `${config.api_url}/${config.v}/invoice/${props.invoice.invoice_hash}/linked-invoices`
        );

        let linkedInvoiceList = await response.json();
        console.log("Linked Invoices", linkedInvoiceList.data);
        setLinkedInvoices(linkedInvoiceList.data);
    }

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            // notify("tr");
            return;
        } else {
            openPop();
            // notify("successful transaction");
            // dispatch(setCurrentMemberView("member-list"));
            history.push("/admin/invoices");
        }
        const json = await response.json();
        console.log("Rule Created Successfully", json);
    }

    const initiateTransaction = () => {

        const tx = new InvoiceDiscountingTransferTransaction(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            props.proposalList.filter((pro) => (pro.proposal_hash === props.invoice.proposal))[0].vault_address,
            "",
            props.invoice.invoice_hash,
            props.proposalList.filter((pro) => (pro.proposal_hash === props.invoice.proposal))[0].proposal_hash,
            "100",
            {
                "id": 1,
                "blockchain_id": 1,
                "name": "eRupee-R",
                "symbol": "e₹-R",
                "description": "",
                "logo": "https://res.cloudinary.com/kapow-tech/image/upload/v1676914208/erupee_vii3is.png",
                "decimals": 2,
                "supply": "0x1000000000000000000000",
                "type": 7001,
                "sub_type": 0,
                "contract": "",
                "creator": "cnCEFpjcUwQDNx7NAadcGRjKxh6bnjdAJBmgLr1HKc6iML44",
                "website": "",
                "white_paper": "",
                "source_code": "",
                "status_code": 801
            },
            props.invoice.vault_address, // Invoice Vault COKE VAULT
            props.proposalList.filter((pro) => (pro.proposal_hash === props.invoice.proposal))[0].vault_address,
            "0",
            0
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    };

    return (
        <>
            <div className="invoices">
                <Modal isOpen={modalClassic} toggle={toggleModalClassic} className="action-model" style={{width: "1000px", maxWidth: "1000px", height: "250px"}}>
                    <div className="modal-header justify-content-left">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalClassic}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h4 className="title title-up">Linked Invoices</h4>
                    </div>
                    <ModalBody className="justify-content-left connect-exchange-modal">
                        <div className="linked_list">
                            {
                                linkedInvoices &&
                                linkedInvoices.children && linkedInvoices.children.length > 0 &&
                                linkedInvoices.children.reverse().map((inv, index) => (<div className="linked_invoices">
                                    <h1>&nbsp; Parent</h1>
                                    <div className="text-center">
                                        <div className="display_inline">
                                            <Avatar name={ inv.organisation_name }   size="40" />
                                        </div>
                                        <div className="company_title">{ inv.organisation_name } - {inv.invoice_number}</div>
                                    </div>
                                    <div className="summary-block data-block">
                                        <div>
                                            <h2>Deal Value</h2>
                                            <div>{ inv.total_invoice_amount } USD</div>
                                        </div>
                                        <div>
                                            <h2>Tenure</h2>
                                            <div>{ calculateDaysBetween((inv.created_at)*1000, (inv.due_date)*1000) }</div>
                                        </div>
                                        <div>
                                            <h2>Vendor ID</h2>
                                            <div>{inv.irn}</div>
                                        </div>
                                        <div>
                                            <h2>Buyer GST</h2>
                                            <div>{inv.buyer_gst}</div>
                                        </div>
                                        <div>
                                            <h2>Seller GST</h2>
                                            <div>{inv.seller_gst}</div>
                                        </div>
                                    </div>
                                </div>))
                            }

                            <div className="linked_invoices current_invoice">
                                <h1>Current Invoice</h1>
                                <div className="text-center">
                                    <div className="display_inline">
                                        <Avatar name={ props.invoice.organisation_name }   size="40" />
                                    </div>
                                    <div className="company_title">{ props.invoice.organisation_name } - {props.invoice.invoice_number}</div>
                                </div>
                                <div className="summary-block data-block">
                                    <div>
                                        <h2>Deal Value</h2>
                                        <div>{ props.invoice.total_invoice_amount } USD</div>
                                    </div>
                                    <div>
                                        <h2>Tenure</h2>
                                        <div>{ calculateDaysBetween((props.invoice.created_at)*1000, (props.invoice.due_date)*1000) }</div>
                                    </div>
                                    <div>
                                        <h2>Vendor ID</h2>
                                        <div>{props.invoice.irn}</div>
                                    </div>
                                    <div>
                                        <h2>Buyer GST</h2>
                                        <div>{props.invoice.buyer_gst}</div>
                                    </div>
                                    <div>
                                        <h2>Seller GST</h2>
                                        <div>{props.invoice.seller_gst}</div>
                                    </div>
                                </div>
                            </div>
                            {
                                linkedInvoices &&
                                linkedInvoices.ancestors  && linkedInvoices.ancestors.length > 0 &&
                                linkedInvoices.ancestors.map((inv, index) => (<div className="linked_invoices">
                                    <h1>Children &nbsp;</h1>
                                    <div className="text-center">
                                        <div className="display_inline">
                                            <Avatar name={ inv.organisation_name }   size="40" />
                                        </div>
                                        <div className="company_title">{ inv.organisation_name } - {inv.invoice_number}</div>
                                    </div>
                                    <div className="summary-block data-block">
                                        <div>
                                            <h2>Deal Value</h2>
                                            <div>{ inv.total_invoice_amount } USD</div>
                                        </div>
                                        <div>
                                            <h2>Tenure</h2>
                                            <div>{ calculateDaysBetween((inv.created_at)*1000, (inv.due_date)*1000) }</div>
                                        </div>
                                        <div>
                                            <h2>Vendor ID</h2>
                                            <div>{inv.irn}</div>
                                        </div>
                                        <div>
                                            <h2>Buyer GST</h2>
                                            <div>{inv.buyer_gst}</div>
                                        </div>
                                        <div>
                                            <h2>Seller GST</h2>
                                            <div>{inv.seller_gst}</div>
                                        </div>
                                    </div>
                                </div>))
                            }
                        </div>

                    </ModalBody>
                </Modal>
                <div className="invoices__company">
                    <div>
                        <Avatar name={ props.invoice.organisation_name }   size="40" />
                        {/*<img width="30px" height="30px" src="" alt=""/>*/}
                    </div>
                    <div className="company_title">{ props.invoice.organisation_name } - {props.invoice.invoice_number}</div>
                    <Button
                        className="btn-simple" color="info"
                        onClick={toggleModalClassic}
                        style={{    width: "250px", marginRight: "100px", padding: "7px 4px"}}
                    >
                        See Linked Invoices
                    </Button>
                    <span className="invoice_tag">Invoice</span>
                </div>
                <div className="invoices_body">
                    <div className="invoice-details">
                        <div className="summary-block data-block">
                            <div>
                                <h2>Deal Value</h2>
                                <div>{ props.invoice.total_invoice_amount } {props.invoice.currency === 0 ? "USD" : "INR" }</div>
                            </div>
                            <div>
                                <h2>Tenure</h2>
                                <div>{ calculateDaysBetween((props.invoice.created_at)*1000, (props.invoice.due_date)*1000) }</div>
                            </div>
                            <div>
                                <h2>Vendor ID</h2>
                                <div>{props.invoice.irn}</div>
                            </div>
                        </div>
                        <div className="summary-block data-block">
                            <div>
                                <h2>Seller GST</h2>
                                <div>{props.invoice.seller_gst}</div>
                            </div>
                            <div>
                                <h2>Buyer GST</h2>
                                <div>{props.invoice.buyer_gst}</div>
                            </div>
                            <div>
                                <h2>Transaction Fee</h2>
                                <div>Yes | No</div>
                            </div>
                            {
                                props.acceptedFilter &&
                                <Button
                                    className="show_proposals btn-simple"
                                    color="info"
                                    size="sm"
                                    onClick={ initiateTransaction }
                                >
                                    Approve Invoice
                                </Button>
                            }
                        </div>
                    </div>
                    {
                        JSON.parse(localStorage.getItem("org_list"))[0].organisation_type === 0 &&
                        <Button
                            className="show_proposals btn-simple"
                            color="info"
                            size="sm"
                            onClick={ handleProposal }>
                            {!showHideProposals ? '+ Show Proposals' : '- Hide Proposals'}
                        </Button>
                    }
                </div>

                <div>
                    {
                        !showHideProposals && props.acceptedFilter &&
                        props.proposalList && props.proposalList.length > 0 &&
                        props.proposalList.filter((pro) => {
                            console.log("Test 1", pro.proposal_hash);
                            console.log("Test 2", props.invoice.proposal);
                            return (pro.proposal_hash === props.invoice.proposal);
                        }).map((proposal, index) => (
                            <ProposalCard proposal={proposal} invoice={props.invoice} accepted={true}/>
                        ))
                    }
                    {
                        showHideProposals &&
                        props.acceptedFilter === false &&
                        props.proposalList && props.proposalList.length > 0 &&
                        props.proposalList.map((proposal, index) => (
                            <ProposalCard proposal={proposal} invoice={props.invoice}/>
                        ))
                    }
                </div>
                {
                    props.acceptedFilter &&
                    props.invoice.state_changes &&
                    <div className="state-bar">
                        <div className={props.invoice.state_changes["INVOICE_CREATED"] ? "green" : "red"}>Created</div>
                        <div className={props.invoice.state_changes["PROPOSAL_ACCEPTED"] ? "green" : "red"}>Accepted</div>
                        <div className={props.invoice.state_changes["FINANCER_APPROVED"] ? "green" : "red"}>Approved</div>
                        <div className={props.invoice.state_changes["AMOUNT_DISBURSED"] ? "green" : "red"}>Discounted | Transferred</div>
                        <div className={props.invoice.state_changes["REPAID"] ? "green" : "red"}>Repaid</div>
                    </div>
                }
            </div>
        </>
    );
};

export default InvoiceCard;