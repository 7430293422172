/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from "react";
import {AcceptInvoiceTransaction, Base64} from "kapow-sdk";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table,
    Modal,
    ModalBody,
    Alert,
    UncontrolledTooltip,
} from "reactstrap";

import ProposalCreation from "./ProposalCreation/ProposalCreation";
import {Link, Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {config} from "../../../config";
import "./invoices.scss";
import { clip_address } from "../../../variables/utils";
import Avatar from "react-avatar";
import Swal from "sweetalert2";

const ProposalCard = (props) => {

    const [nonce, setNonce] = useState(0);

    let history = useHistory();
    let { id } = useParams();

    const openPop = () => {
        Swal.fire(
            'Success!',
            'Invoice Submitted',
            'success'
        )
    }

    useEffect(() => {
        (async () => {
            await getNonce();

        })();

        return () => {
            // Timeout cleanup on Unmount,
            // window.clearTimeout(timeout);
        }

    }, []);

    async function getNonce () {
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.accounts}/${localStorage.getItem("ws_address")}/sequence`
        );

        const nonce = await response.json();
        setNonce(nonce + 1);
    }

    // XHR Request to send transaction to Blockchain
    async function  sendTx (signedTx) {
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                "tx": signedTx
            })
        }
        const response = await fetch(
            `${config.api_url}/${config.v}/${config.endpoints.transactions}`, settings
        );

        if (!response.ok) {
            console.log("YO YO MA NOT OKAY!");
            // notify("tr");
            return;
        } else {
            openPop();
            // notify("successful transaction");
            // dispatch(setCurrentMemberView("member-list"));
            history.push("/admin/invoices");
        }
        const json = await response.json();
        console.log("Rule Created Successfully", json);
    }

    const acceptProposal = () => {
        const tx = new AcceptInvoiceTransaction(
            nonce,
            localStorage.getItem("ws_address"), // address from WS
            Buffer.from(localStorage.getItem("session_pub_key"), 'hex'), // Login Pub Key UIn8
            localStorage.getItem("org_hash"),
            "",
            "",
            props.invoice.invoice_hash,
            props.proposal.proposal_hash,
            true
        );

        tx.signTx(Buffer.from(localStorage.getItem("session_secret_key"), 'hex')); // Login Key Pair Secret
        const signedTx = tx.toBase64();

        sendTx(signedTx).then((res) => {
            console.log("responseOrg", res);
            // setOrgSuccess(true);
        });
    }

    return (
        <>
            <div className="invoices proposal">
                <div className="invoices__company">
                    <div>
                        <Avatar name={ props.proposal.organisation_name }   size="40" />
                        {/*<img width="30px" height="30px" src="" alt=""/>*/}
                    </div>
                    <div className="company_title">{ props.proposal.organisation_name }</div>
                    <span className="proposal_tag">Proposal</span>
                </div>
                <div className="invoices_body">
                    <div className="invoice-details">
                        <div className="summary-block data-block">
                            <div>
                                <h2>Proposal Hash Value</h2>
                                <div>
                                    <div className="long-hash">
                                        {clip_address(props.proposal.proposal_hash)}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2>Vault Address</h2>
                                <div>
                                    <div className="long-hash">
                                        {clip_address(props.proposal.vault_address)}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2>Discounting Percentage</h2>
                                <div>{props.proposal.max_discounting_percent}%</div>
                            </div>
                            <div>
                                <h2>Fee Percentage</h2>
                                <div>{props.proposal.fees_percent}</div>
                            </div>
                        </div>

                        {
                            props.accepted &&
                            <div className="accepted_tag">PROPOSAL ACCEPTED</div>
                        }
                        {
                            !props.accepted &&
                            <Button color="primary" className="accept_proposal" onClick={acceptProposal}>
                                Accept Proposal
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProposalCard;