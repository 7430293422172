import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import  { clip_address } from "../variables/utils";
import { tx_types, tx_status } from "../constants";
import TransactionTable from "./TransactionTable";

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Progress,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Breadcrumb,
    BreadcrumbItem,
    Alert, Form
} from "reactstrap";

import "./transactions.scss";

const Transactions = (props) => {

    useEffect(() => {
        (async () => {
            // await getTransactions();
            // await getAssetList();
        })();

    }, []);

    // async function getTransactions () {
    //     const response = await fetch(
    //         `${config.api_url}/${config.v}/${config.endpoints.transactions}?limit=100&page=0&order=desc&txTypes=1513`
    //     );
    //
    //     let transactionList = await response.json();
    //     console.log("Transaction response ", transactionList.data, transactionList.data.length);
    //     setTransaction(transactionList.data)
    // }

    // async function getAssetList () {
    //     const response = await fetch(
    //         `${config.api_url}/${config.v}/${config.endpoints.assets}?pagination=false`
    //     );
    //
    //     let assetList = await response.json();
    //     console.log("asset list shubham ew added data", assetList);
    //     setAssetList(assetList);
    // }

    return (
        <>
            <div className="content accounts-view transactions">
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <div className="tools float-right">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            caret
                                            className="btn-icon"
                                            color="link"
                                            data-toggle="dropdown"
                                            type="button"
                                        >
                                            <i className="tim-icons icon-settings-gear-63" />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                Action
                                            </DropdownItem>
                                            <DropdownItem
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                Another action
                                            </DropdownItem>
                                            <DropdownItem
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                Something else
                                            </DropdownItem>
                                            <DropdownItem
                                                className="text-danger"
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                Remove Data
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                                <CardTitle tag="h4">Transaction History</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/*<Table responsive>*/}
                                {/*    <thead className="text-primary" style={{"height": "0px"}}>*/}
                                {/*    <tr style={{"height": "0px"}}>*/}
                                {/*        {*/}
                                {/*            tx_keys.map((tx_header, index) => <th key={index}>{tx_header}</th>)*/}
                                {/*        }*/}
                                {/*    </tr>*/}
                                {/*    </thead>*/}
                                {/*    {*/}
                                {/*        transactions && transactions.length > 0 &&*/}
                                {/*        <tbody>*/}
                                {/*        {*/}

                                {/*            transactions.map((tx, index) => (*/}
                                {/*                <tr key={tx.tx_hash}>*/}
                                {/*                    <td>*/}
                                {/*                        <span className="long-hash">*/}
                                {/*                            {clip_address(tx.tx_hash)}*/}
                                {/*                        </span>*/}
                                {/*                    </td>*/}
                                {/*                    <td>*/}
                                {/*                        {*/}
                                {/*                            assetList && assetList.length > 0 &&*/}
                                {/*                            <span>*/}
                                {/*                                { assetList.find(item => item.id === tx.Data.asset_id).name }*/}
                                {/*                            </span>*/}
                                {/*                        }*/}
                                {/*                        /!*{tx.blockchain} <sup>[{tx.network}]</sup>*!/*/}
                                {/*                    </td>*/}
                                {/*                    <td>{tx_status[tx.status]}</td>*/}
                                {/*                    <td>*/}
                                {/*                        /!*{(tx.Data && tx.Data.quantity) ? tx.Data.quantity : ""}*!/*/}
                                {/*                        {*/}
                                {/*                            assetList && assetList.length > 0 &&*/}
                                {/*                            <div>*/}
                                {/*                                <span>*/}
                                {/*                                    {*/}
                                {/*                                        Number(Quantity.hexToDecimal(assetList.find(item => item.id === tx.Data.asset_id), (tx.Data && tx.Data.quantity) ? tx.Data.quantity : 0))*/}
                                {/*                                    }*/}
                                {/*                                </span> <span>*/}
                                {/*                                    {*/}
                                {/*                                        assetList.find(item => item.id === tx.Data.asset_id).symbol*/}
                                {/*                                    }*/}
                                {/*                                </span>*/}
                                {/*                            </div>*/}

                                {/*                        }*/}
                                {/*                    </td>*/}
                                {/*                    <td>*/}
                                {/*                        <span className="date-time-class">{new Date(tx.time_stamp*1000).toString().toString()}</span>*/}
                                {/*                    </td>*/}
                                {/*                    <td>*/}
                                {/*                        <span className="long-hash">*/}
                                {/*                            {clip_address(tx.Data.from)}*/}
                                {/*                        </span>*/}
                                {/*                    </td>*/}
                                {/*                    <td>*/}
                                {/*                        {*/}
                                {/*                            tx.Data.to &&*/}
                                {/*                            <span className="long-hash">*/}
                                {/*                                {clip_address(tx.Data.to)}*/}
                                {/*                            </span>*/}
                                {/*                        }*/}
                                {/*                    </td>*/}
                                {/*                    <td>*/}
                                {/*                        /!*<div>{tx.action}</div>*!/*/}
                                {/*                        /!*<div>*!/*/}
                                {/*                        /!*    {*!/*/}
                                {/*                        /!*        tx.actors.map((actor) => (*!/*/}
                                {/*                        /!*                <sub key={actor.user_id}>{actor.username}</sub>*!/*/}
                                {/*                        /!*            )*!/*/}
                                {/*                        /!*        )*!/*/}
                                {/*                        /!*    }*!/*/}
                                {/*                        /!*</div>*!/*/}
                                {/*                    </td>*/}
                                {/*                </tr>*/}
                                {/*            ))*/}
                                {/*        }*/}
                                {/*        </tbody>*/}
                                {/*    }*/}
                                {/*</Table>*/}
                                <TransactionTable
                                    tx_type="txTypes=1513,1035,1533"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Transactions;
