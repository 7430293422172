import { Base64 } from "../../base64";

export class AddOrganisationMemberData {
  inviteHash: string;
  memberName: string; 
  memberEmail: string;
  memberType: number;
  permissions: string;
  vaults: string;
  lendingPools: string;
  
  constructor(memberName: string, memberEmail: string, memberType: number, inviteHash: string, permissions: string, vaults: string, lendingPools: string) {
    this.inviteHash = inviteHash;
    this.memberName = memberName;
    this.memberEmail = memberEmail;
    this.memberType = memberType;
    this.permissions = permissions;
    this.vaults = vaults;
    this.lendingPools = lendingPools;
  }

  public static fromJson(json: any): AddOrganisationMemberData {
    return new AddOrganisationMemberData(
      json["invite_hash"],
      json["member_name"],
      json["member_email"],
      json["member_type"],
      json["permissions"],
      json["vaults"],
      json["lending_pools"]
    );

  }
  public toJson(): string {
    return JSON.stringify({
      "invite_hash": this.inviteHash,
      "member_name": this.memberName,
      "member_email": this.memberEmail,
      "member_type": this.memberType,
      "permissions": this.permissions,
      "vaults": this.vaults,
      "lending_pools": this.lendingPools
    }); 
  }

  public toBase64(): string {
    return Base64.encodeString(this.toJson());
  }
}
